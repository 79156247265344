import React,{ useEffect, useState } from "react";
import styled from "styled-components";
import { Body,Center, Space} from "../Reuse_ui/Css";
import {Oval} from 'react-loader-spinner'
import Cheader from "./Cheader";
import ScanTable from '../Admins/ScansTable'
import UserFamTable from '../Admins/UserFamTable'
import NotifyTable from '../Admins/NotifyTable'
import {request, requestTermianl, ScansBeep} from '../actions_api';
import { Add_session, Get_session, Message, getCookie } from "../Reuse_ui/Logic";
import Terminaltable from "../Admins/Terminaltable";
import Footer from "../Components/Footer";
import {useNavigate, useParams} from 'react-router-dom';

const Cpanel = () => {

    const [tab, setTab] = useState(1);
    const [list, setList] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [account_type, setAccount_type] = useState("");
    const navigate = useNavigate();
    var {c} = useParams();




    const  api = async (m) => {
            let res = await request(sessionStorage);
              Add_session(process.env.REACT_APP_USERNODEKEY,sessionStorage,[res.data.message.nodes.type]);
                setAccounts(res.data.message.nodes.list);
                setAccount_type(res.data.message.nodes.type.User.service)
                if(c === "3"){
                  setList([]);
                
                    try{
                        let res = await requestTermianl(process.env.REACT_APP_TERMINALS,sessionStorage);
                          setList(res.data.data);
                          setAccounts(res.data.list);
                      }catch(err){
                          Message("Terminal Request",err.response.data.error,"info");
                      }
                } 
                if(c === "0"){
                    if(res.data.message.nodes.type.User.service === process.env.REACT_APP_REWARD_NODES.toUpperCase() || res.data.message.nodes.type.User.service === process.env.REACT_APP_CARPARK_NODES.toUpperCase()){         
                              try{
                                setList([]);
                                  let respons = await ScansBeep(sessionStorage);
                                    if(!respons.message.includes("No Transciations Yet !") || !respons.message.includes("Acccount Doesn't exist"))
                                        setList(respons.message); 
                                    else
                                        Message('Info', respons.message,'info')
                                    
                                }catch(err){
                                    Message("Scans Request",err,"error");
                                }
                      }else
                          setList(res.data.message.nodes.list);   
                }
          setTab(m); 
          
  }



  const CheckIf = (ls) => {
   return !ls.includes("No Transciations Yet !") ? ls.some(value => { return typeof value == "object" }) : false;
  }



  useEffect(() => { 
        if(Get_session("em",sessionStorage))
                api(c);
            else
               Message("Authentication needed","Pls sign in","warning");
   },[c]);

    return(
        <>
            <Body w={'100%'} h={'auto'}  mh={'auto'} style={{background:"#f5f5f5"}}>
             <Cheader/>
                {list && list !== undefined && list !== null ?
                <>
                    {tab === "0" && list.length > 0  ?
                    <UserFamTable list={CheckIf(list) ? list : []} account_type={account_type}/>
                    :
                    tab == "1" && !list.includes("No Transciations Yet !") ?
                    <ScanTable typ={account_type} acs={accounts} list={list} />
                    :
                    tab == "2" ?
                    <NotifyTable list={list} />
                    : 
                    tab == "3" && list.length > 0 ?
                    <Terminaltable acs={accounts} list={list} />
                    : 
                    <Center style={{display:"flex",flexDirection:"column"}}>
                      <Oval color={"#2D3476"}  height={40} width={40}/>
                      {list.includes("No Transciations Yet !") ? "No Transciations Yet !" : ""}
                    </Center> 
                  }
                </>
               : 
                <Center>
                 <Oval color={"#2D3476"}  height={40} width={40}/>
                </Center> 
               }
             </Body>
              <Space/>
             <Footer/>
         </>
    )
}




export  default Cpanel;