import React,{useEffect} from 'react'
import styled from 'styled-components'
import { Body,Space} from '../Reuse_ui/Css'
import Footer from './Footer'
import {Writes} from '../Reuse_ui/Widget'
import { RiBankFill, RiBook3Fill, RiBookFill, RiCarFill, RiLock2Line, RiRecycleFill, RiRoadMapLine, RiSailboatFill} from 'react-icons/ri'
import AOS from 'aos';
import "aos/dist/aos.css";
import ServiceBanner from './ServiceBanner'
import BackGroundFlow from './BackGroundFlow'

const Business = () =>{




  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <Body w={'100%'} h={'102vh'} style={{background:"#f5f5f5"}}>
        <Space/>
          <Container>
              <ServiceBanner/>     
          </Container>
            <BackGroundFlow/>
            <Footer/>
    </Body>
  )
}



const Container = styled.div`
width:90%;
height:70vh;
margin-left:auto;
margin-right:auto;
overflow:hidden;
justify-content:center;
font-family: "Poppins", sans-serif;
@media(max-width:900px){
width:95%;
height:150vh;
}
`;









export default Business