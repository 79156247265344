import React from 'react'
import { RiBookFill, RiCarFill, RiLock2Line, RiRecycleFill, RiSailboatFill } from 'react-icons/ri';
import styled from 'styled-components'

const  BackGroundFlow = () => {
  return (
    <Container> 
        
        <div id='top'>
          Services
        </div>

        

            <Back h={"auto"} u={"./assets/svg.png"}>
              <CenterDiv mt={"220px"}>

                <ChildDiv>
                  <FirstDiv>
                    <CircleDiv>
                      <RiCarFill size={30} color='#f5f5f5'/> 
                    </CircleDiv>
                    <br/>
                    <h1>Beeppoint Park </h1>
                  </FirstDiv>
                  
                  <hr style={{marginLeft:"auto",marginRight:"auto",marginTop:"10px"}}/>

                  <ul>
                    <li>
                      Provide maximum security system.
                    </li>
                    <li>
                      Automated entry and exit point
                     </li>
                     <li>
                      Community census database solution.
                     </li>
                     <li>
                      All in one payment and security system for your convenience.
                     </li>
                  </ul>
                  
                </ChildDiv>



                <ChildDiv>
                  <FirstDiv>
                    <CircleDiv>
                      <RiLock2Line size={30} color='#f5f5f5'/> 
                    </CircleDiv>
                    <br/>
                    <h1>Beeppoint Access </h1>
                  </FirstDiv>
                  
                  <hr style={{marginLeft:"auto",marginRight:"auto",marginTop:"10px"}}/>
                  
                  <ul>
                    <li>
                     Unlimited access to car packs across the states
                    </li>
                    <li>
                      All in one payment and security system.
                     </li>
                     <li>
                      Tap and go payment solution to save time
                     </li>
                     <li>
                      Realtime tracking of vechicles within car park vicinity
                     </li>
                  </ul>
                  
                </ChildDiv>


                <ChildDiv>
                  <FirstDiv>
                    <CircleDiv>
                      <RiRecycleFill size={30} color='#f5f5f5'/> 
                    </CircleDiv>
                    <br/>
                    <h1>Beeppoint Rewards (Points)</h1>
                  </FirstDiv>
                  
                  <hr style={{marginLeft:"auto",marginRight:"auto",marginTop:"10px"}}/>
                  
                  <ul>
                    <li>
                      Efficient and roburst payment system
                    </li>
                    <li>
                    Automated account balancing system
                     </li>
                     <li>
                     Sales and customer record keeping.
                     </li>
                     <li>
                     Reward as you purchase
                     </li>
                     <li>
                     Creating mutual bond between you and your favourite stores
                     </li>
                  </ul>
                  
                </ChildDiv>
            

                </CenterDiv>
            </Back>


            <Back h={"auto"} u={"./assets/upside.png"}>
              <CenterDiv mt={"20px"}>
                      
                <ChildDiv>
                    <FirstDiv>
                      <CircleDiv>
                        <RiBookFill size={30} color='#f5f5f5'/> 
                      </CircleDiv>
                      <br/>
                      <h1>Beeppoint Education</h1>
                    </FirstDiv>
                    
                    <hr style={{marginLeft:"auto",marginRight:"auto",marginTop:"10px"}}/>
                    
                    <ul>
                      <li>
                        Provide maximum security system
                      </li>
                      <li>
                        Automated entry and exit point.
                      </li>
                      <li>
                       School census database.
                      </li>
                      <li>
                       Auto clock-in system for staff and students.
                      </li>
                      <li>
                      Creating mutual bond between you and your favourite stores
                      </li>
                    </ul>   
                </ChildDiv>



                <ChildDiv>
                    <FirstDiv>
                      <CircleDiv>
                        <RiSailboatFill size={30} color='#f5f5f5'/> 
                      </CircleDiv>
                      <br/>
                      <h1>Beeppoint Trains & Ferris &nbsp;</h1>
                    </FirstDiv>
                    
                    <hr style={{marginLeft:"auto",marginRight:"auto",marginTop:"10px"}}/>
                    
                    <ul>
                      <li>
                        E-ticketing solution
                      </li>
                      <li>
                       Fast and reliable payment solution
                      </li>
                      <li>
                      Instant e-booking solution
                      </li>
                      <li>
                      Fleet management, Cashless retailing system
                      </li>
                    </ul>   
                </ChildDiv>

              </CenterDiv>
          </Back>       
    
    </Container>
    
  )
}


const Container = styled.div`
display:flex; 
flex-flow:column;

#top{
margin: 0 auto;
font-size:30px;
font-weight:700;
}

@media(max-width:900px){
#top{
margin-top:100px;
font-size:20px;
font-weight:700;
}
}
`;



export const Back = styled.div`
width:100%; 
height:100vh;
background-image: url(${(props) => props.u});  
background-repeat: no-repeat;
background-size: 100% 100%;

h5{
margin-left:80px;
font-weight:900;
font-size:25px;
}
@media(max-width:900px){
padding-bottom:50px;
background-size: 300% 100%;
height:${(props) => props.h};
}
`;




export const CenterDiv = styled.div`
width:90%; 
height:auto; 
margin-left:auto;
margin-right:auto;
margin-top:${(props) => props.mt};
display:flex;
flex-flow:row;
align-items:center;
justify-content:center;
hr{
width:80%;
}
@media(max-width:900px){
flex-flow:column;
}
`;






const ChildDiv = styled.div`
width:25%;
height:350px;
background:#f5f5f5;
border-radius:10px;
margin:15px;
display:flex; 
flex-flow:column;
padding:10px;


h1{
font-size:25px;
font-weight:900;
}



li {
font-weight:500;
color:#302EA7;
font-size:12px;  
}



@media(max-width:900px){
width:90%;
box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
`;




const FirstDiv = styled.div`
margin-left:20px;
`;





const CircleDiv = styled.div`
background:#302EA7;
border-radius:50%;
width:60px;
height:60px;
display:flex; 
margin-top:10px;
align-items:center;
justify-content:center;


`;



export default BackGroundFlow