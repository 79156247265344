import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {Oval} from 'react-loader-spinner'
import { Center, WriteUps} from '../Reuse_ui/Css';
import {isNumber, Submit, } from '../actions_api'
import { Message } from '../Reuse_ui/Logic';
import Button from 'react-bootstrap/Button'; 
import { RiFundsFill, RiLock2Fill } from 'react-icons/ri';



const ScanMode = ({list,fun}) => {


    const [type ,setType] = useState("");
    const [discount ,setDiscount] = useState("");
    const [no ,setNo] = useState(true);
    const [isLoad ,setIsLoad] = useState(false);
    const [n ,setN] = useState("");
  
        
    const Change = async (id) => {
         setNo(id);
    } 


    
    
   const route = async () => {
     if(isNumber(discount) &&  discount.trim().length > 0){
       if(n.trim().length <= 0 && !no)
           Message('Info','Pls Add N value','info')
        else{
          setIsLoad(true);
           await Submit(list[0],list[1],discount,list[3],list[2],{Ky:no,Flag:n});
          }
    }else
         Message('Info','Invalid value','info')
 }



    useEffect(() => {
      
    },[])
  

    return(<Container>
                 <Content>
                    <Header>
                      <h2>Choose model</h2>
                       <Button  onClick={(e) => fun(e,1)}>X</Button>
                     </Header>

                     <Box>
                       <Members>
                          <Node onClick={(e) => Change(false)} style={!no ?  {backgroundColor:"#ffffff",border:"1px solid #3c9bfa"} : null}>
                             <RiFundsFill size={60}/>
                             <span>Points x n save</span>
                          </Node>

                          <Node onClick={(e) => Change(true)} style={no  ?  {backgroundColor:"#ffffff",border:"1px solid #3c9bfa"} : null}>
                             <RiLock2Fill size={60}/>
                             <span>Instant point</span>
                          </Node>
                       </Members>

                       <Down>
                         <Left> 
                            {!no ? <WriteUps placeholder='N value' value={n}   onChange={(e) => setN(e.target.value)} /> : null} 
                            <WriteUps placeholder='Discount points' value={discount}   onChange={(e) => setDiscount(e.target.value)} /> 
                          </Left>
                        <Left>
                           {isLoad ? <Oval height={30} width={30}/> : <Button onClick={(e) => route()}>Submit</Button>}
                        </Left>
                       </Down>
                  
                   </Box>      
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:1009;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
margin: 0 auto;
padding-bottom:20px;
top:32px;
h2{
font-size:12px;
}


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const Box = styled.div`
width:80%;
height:auto; 
margin:0 auto;
@media(max-width:900px){
width:100%;
}
`;


const Members = styled.div`
display:flex;
flex-flow:row;
width:100%;
height:auto; 
padding:10px;
`;


const Node = styled.div`
width:50%; 
height:150px;
border-radius:10px;
background: #a4a7ab;
display:flex; 
flex-flow:column;
justify-content:center;
align-items:center;
margin: 15px;
:hover{
border: 1px solid #3c9bfa;
background: #ffffff;
}
`;


const Down = styled.div`
display:flex;
flex-flow:row;
`;


const Left = styled.div`
display:flex;
flex-flow:column;
justify-content:space-evenly;
align-items:center;
`;



const  mapStateToProps = (state)  => {
    return {
    };
};

const mapDistpachToProps = (dispatch) => ({
});

export default  connect(mapStateToProps,mapDistpachToProps)(ScanMode);




    