import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../Reuse_ui/Widget';
import {connect} from 'react-redux';
import { LoginAuth, Tr ,RouteNewMarchant} from '../actions_api';
import {Acounts} from '../Reuse_ui/Css'
import { useNavigate } from 'react-router-dom';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import {Btn,Container,Boxed,Input,Banner} from '../Reuse_ui/Css';
import Location from '../Auths/Location';
import {Oval} from 'react-loader-spinner'
import { Get_session, Message } from '../Reuse_ui/Logic';
import {Type,TopLayer,H5} from '../Beepdmin/SignUp'




const Reset = (props) => {

  const [tabs, setTabs] = useState([process.env.REACT_APP_CARPARK_NODES,process.env.REACT_APP_ESTATE_NODES,process.env.REACT_APP_REWARD_NODES,process.env.REACT_APP_SCHOOL_NODES]);
  const [serviceChoose, setServiceChoose] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [company_mail, setcompanymail] = useState("");
  const active = {border:"1px solid #f5f5f5",background:"#13B6FF"}
  const [otp, setOtp] = useState("");
  const [btn, setBtn] = useState(9);
  const navigate = useNavigate();
  const inactive = {}



  const NavigateToSignIn = () => {
    navigate("/login");
  }


  const fireLogin =  async (e) => {
     if(serviceChoose.trim().length > 0)
        setshowloader(true);
     else
        Message("Info","Pls select service","info");
       
  }


  function close() {
    setshowloader(false);
  }
  

  return (
      <Container>
       <Boxed>
          <Input>
             <Type><div>SELECT SERVICE</div></Type>
               <TopLayer>
                {tabs.map((v,i) => <H5  key={i} style={btn == i ? active : inactive}  onClick={(e) =>{setServiceChoose(v); setBtn(i)}}>{v}</H5> )}
               </TopLayer>
            <USERDATA>
              <input onChange={(e) => setcompanymail(e.target.value)} value={company_mail} placeholder='Company Email'/>
              <input onChange={(e) => setOtp(e.target.value)}  value={otp} placeholder='Otp' type="text"/>
                 <br/>
                <div onClick={(e) =>{ Tr(company_mail)  && Tr(otp) ? fireLogin() : Message("Form validation","Pls fill out all fields !","warning")}}>
                  {showloader ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Activate'}  icon={null}  size={"100%"}/>}
                </div>
                {showloader && company_mail && otp ? <Location  payload={[company_mail,otp,serviceChoose]} fun={close} /> : null}
              </USERDATA>
              <Acounts>
                   <MiniLogin>
                      Sign in facebook &nbsp; <RiFacebookCircleFill color='#2D3476'/>   
                  </MiniLogin>

                  <MiniLogin>
                      Sign in with Google &nbsp; <RiGoogleFill color='#2D3476'/>
                  </MiniLogin>
                </Acounts>
                <Acounts>
                 <div onClick={(e)=> NavigateToSignIn()}>Already reset account password? LOGIN</div>
               </Acounts>
            </Input>
          <Banner>
             <div>
              <img src={
                btn === 0 ?
                "./assets/carpark.gif" 
                :
                btn === 1 ?
                "./assets/estate.gif"
                :
                btn === 2 ?
                "./assets/shop.gif"
                :
                btn === 3 ?
                "./assets/Education.gif"
                :
                "./assets/estate.gif"
                }
              />
             </div> 
          </Banner>
       </Boxed>
     </Container>
  )
}



const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:10%;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
`;



const MiniLogin = styled.button`
display: flex;
justify-content: center;
background-color: #fff;
align-items: center;
height: 50px;
width: auto
z-index: 0;
margin-top:30px;
margin-left:10px;
color:#2D3476;
cursor:pointer;
font-size: 12px;
padding-left:20px;
padding-right:20px;
border-radius: 28px;
vertical-align:middle;
transition-duration: 167ms;
border: 1px solid #f5f5f5;
&:hover{
background-color: rgba(207,207, 207, 0.25);
color: rgba(0, 0, 0, 0.75);
}
@media(max-width:900px){
font-size:8pt;
padding-left:10px;
padding-right:10px;
}
`;


const mapStateToProps = (state) => {
  return {user:state.userState.user,};
};


const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(Reset)