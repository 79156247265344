import { initializeApp} from "firebase/app";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider,signInWithPopup,signInWithEmailAndPassword} from 'firebase/auth'




const app = initializeApp({
    apiKey:process.env.REACT_APP_API_KEY,
    authDomain:process.env.REACT_APP_DOMAIN,
    projectId:process.env.REACT_APP_PROJECT_ID,
    storageBucket:process.env.REACT_APP_STORAGE,
    messagingSenderId:process.env.REACT_APP_MEASURE,
    appId:process.env.REACT_APP_FIREBASE_ID,
    measurementId:process.env.REACT_APP_MEASUREMENT
});

const auth =  getAuth(app);
const Gprovider = new GoogleAuthProvider();
const Fprovider = new FacebookAuthProvider();
export {auth,Gprovider,Fprovider,signInWithPopup,signInWithEmailAndPassword}