import react,{useState} from 'react';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import {Btn,Container,Boxed,Input,Banner} from '../Reuse_ui/Css';
import { fireImportRegistration,Tr } from '../actions_api'; 
import { Type,USERDATA} from './Login'; 
import {Oval} from 'react-loader-spinner'
import { Get_session, Message } from '../Reuse_ui/Logic';
import { ButtonIcon } from '../Reuse_ui/Widget';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';


const  ImporterRegister = () => {

  const history = useNavigate();
  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [pass, setpass] = useState("");



  const send = (url) => {
      history(url);
  }
 
        return(
            <Container>
                  <Boxed>
                    <Input>
                      <Type>Signup</Type>
                        <USERDATA>
                        <input onChange={(e) => setMail(e.target.value)} value={mail} placeholder='Company Email'/>
                        <input onChange={(e) => setName(e.target.value)} value={name} placeholder='Company Name'/>
                        <input onChange={(e) => setpass(e.target.value)}  value={pass} placeholder='Password' type="password"/>
                            <br/>
                          <div onClick={(e) =>{ Tr(mail) && Tr(pass) ? fireImportRegistration([mail,pass,name],send) : Message("Form validation","Pls fill out all fields !","warning")}}>
                            {showloader ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Login'}  icon={null}  size={"100%"}/>}
                          </div>
                        </USERDATA>
                      </Input>
                      <Banner>
                      <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true}>
                        <div>
                          <img src="./assets/carpark.gif"/>
                        </div>
                        <div>
                          <img src="./assets/Education.gif"/>
                        </div>
                        <div>
                          <img src="./assets/estate.gif"/>
                        </div>
                        <div>
                          <img src="./assets/shop.gif"/>
                        </div>
                      </Carousel>
                      </Banner>
                  </Boxed>
          </Container>
        )
}




const TopLayer = styled.div`
width:100%;
height:auto;
display:flex;
background:#4B4AB3;
justify-content:space-between;
`;


const H5 = styled.div`
padding:10px;
color:#ffffff;
background:"#4B4AB3";
border-radius:5px;
margin:10px;
:hover {
background-color: #FF9900;
}
@media(max-width:768px){
margin:5px;
font-size:10px;
padding:5px;
}
`;

export default ImporterRegister