import React from 'react'
import {PanelCards,EditButton} from '../Reuse_ui/Css'
import {RiDeleteBack2Line, RiDeleteBin2Fill, RiDeleteBin2Line} from 'react-icons/ri'
import { getCookie } from '../Reuse_ui/Logic'

const InitPays = ({v,i,fun}) => {
  return (
    
        v === null || v === undefined ? null  :
          <PanelCards key={i}>
                  <table>
        

                  <tr>
                      <td>
                       N{new Intl.NumberFormat().format(v.body.amount)}
                      </td>
                      </tr>


                      <tr>
                      <td>
                        {v.body.acct_number}
                      </td>
                      </tr>


                      <tr>
                      <td>
                        {v.body.bank}
                      </td>
                      </tr>


                      <tr>
                      <td>
                        {v.body.name}
                      </td>
                      </tr>

                      <tr>
                      <td>
                        {v.body.date}
                      </td>
                      </tr>



                      <tr>
                      <td>
                        {v.body.refCode}
                      </td>
                      </tr>



                      <tr>
                      <td>
                        {v.id}
                      </td>
                      </tr>


                    </table>

              <EditButton>
    
                  <div onClick={(e) => fun(v)}>
                    <RiDeleteBin2Line size={20}/>
                  </div>

              </EditButton>
          </PanelCards>
  )
}

export default InitPays