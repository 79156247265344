import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {PanelCards} from '../Reuse_ui/Css'
import  Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import {Replace,SendValidation} from '../actions_api'
import { Message } from '../Reuse_ui/Logic';
import { Oval } from 'react-loader-spinner';


const ValidateScans = ({v,i,k}) => {

  const [clicked, setClicked] = useState(false);
  
  const Openalert = () => {
    Swal.fire({
      title: 'Reciept',
      html:`<img  style="object-fit:contain" width="100%" src="${process.env.REACT_APP_RECEIPT_URL + v.date}/${Replace(v.company_email,"@","%2540")}/${v.date}/${v.pid}.jpg">`
     })
    
  }


  const SendValidate = async () => {
    setClicked(true);
     let res = await SendValidation(v.company_email,v.pid,v.cid);
      if(res){
        setClicked(false);
         Message("Info",res.data.message,"info");
      }
  }


  return (
       <PanelCards key={k}>
                <table>
                      <tr>
                        <td>
                          <b>Points Validation</b> 
                        </td>
                     </tr>

                    <tr>
                     <td>
                        Date:  
                        <br/>
                        {v.date}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Time:  
                        <br/>
                        {v.time}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Points:  
                        <br/>
                        {v.point}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Total:  
                        <br/>
                        {v.total}
                      </td>
                    </tr>

                    <tr>
                     <td>
                       <Coner>
                        <Button onClick={(e) => Openalert()} style={{fontSize:"9px"}}>View reciept</Button>
                        <Button onClick={(e) => SendValidate()} style={{fontSize:"9px"}}>{clicked ? <Oval color={"#fff"}  height={15} width={15}/> : !v.isVerified ? "Validate" : "Invalidate"}</Button>
                       </Coner>
                      </td>
                    </tr>
                    
                </table>
        </PanelCards>
  )
}


const Coner = styled.div`
width:75%;
display:flex;
flex-flow:row;
margin-top:10px;
align-items:center;
justify-content:space-between;
`;


export default ValidateScans