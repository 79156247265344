import React, {useState,useEffect} from 'react'
import {ContainR,ContainC,Body,Btn,Center} from '../Reuse_ui/Css';
import {ButtonIcon, Loading} from '../Reuse_ui/Widget'
import styled from 'styled-components'
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import {Space,Package} from '../Reuse_ui/Css';
import {Oval} from 'react-loader-spinner'
import { getData, pricing, Register } from '../actions_api';
import {Add_session, Clear_session, Get_session, Message} from '../Reuse_ui/Logic';




const Pricing = ({props}) => {

const [prices, setPrices] = useState([])
const [width, setWidth] = useState(window.innerWidth);
const [showloader, setshowloader] = useState(false);
const [click, setClick] = useState("");
var url = new URL(window.location.href);
var ui = url.searchParams.get("c");

function handleCheck() {
  setWidth(window.innerWidth);
}



useEffect(() => {
  pricingRequest();
    if(!Get_session(process.env.REACT_APP_SESSION_NODES2,sessionStorage) && ui !== "1")
                 window.location.href="/login";
  window.addEventListener('resize', handleCheck);
return () => {window.removeEventListener('resize', handleCheck);}
},[])


async function pricingRequest () {
  let data = await pricing();
  setPrices(data.message);
}



function load(e){
  setClick(e); 
  setshowloader(true);
}




// function alert(){
//   Message("Redirect","Please visit the registration page","info");
// }







return(
        <>
         <Body w={'100%'} h={'80vh'}  style={{background:"#f5f5f5"}}  mh={"180vh"} bck_img={null}  c={null}>
          <Header/> 
            {prices.length > 0 ?
            <>
              <ContainC>
               <WriteUps/>
                {width < 900 
                   ?
                   <ContainC>
                    {prices.map((v,i) => 
                      <Package key={i}>
                        <Type>{v.type}</Type>
                          <img src={'./assets/packageHeads.png'} />
                            <ul>
                              {v.meta.map((v) => 
                                <li>{v}</li>
                                )}
                            </ul> 
                            <Prices><h1>${v.price}</h1>/mo</Prices>
                              {/* <div onClick={(e) => {ui !== "1" ? load(v.type) : alert()}}>
                                {showloader && click === v.type  ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <Btn> Select</Btn>}
                              </div> */}
                        </Package>
                      )}
                      <br/>
                    <br/>
                   <br/>
                   </ContainC>
                     :
                   <ContainR>
                      {prices.map((v,i) => 
                        <Package key={i}>
                          <Type>{v.type}</Type>
                          <img src={'./assets/packageHeads.png'} />
                              <ul>
                               {v.meta.map((v) => 
                                <li>{v}</li>
                                )}
                              </ul> 
                              <Prices><h1>${v.price}</h1>/mo</Prices>
                                {/* <div   onClick={(e) =>{ui !== "1" ? load(v.type) : alert()}}>
                                  {showloader && click === v.title  ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <Btn> Select</Btn>}
                                </div> */}
                        </Package>
                      )}
                  </ContainR>
                  }
            </ContainC> 
            </>
            :
            <Center>
              <Oval color={"#2D3476"}  height={40} width={40}/>
            </Center> 
            }
         </Body>
          <Space/>
          <br/><br/><br/><br/>
          <br/><br/><br/><br/>
          <Footer/>
          
       </>
    )
}




const WriteUps = styled.div`
width:76%;
height:10%;
margin-top:100px;
margin-left:auto;
margin-right:auto;
`;



const Type = styled.div`
color:#fff;
font-size:20px;
font-weight:bold;
position:absolute;
background:#2D3476;
padding:10px;
border-radius:0px 0px 10px 10px;
font-family: "Poppins", sans-serif;
`;

const Prices = styled.div`
width:80%;
height:auto;
margin:auto;
color:#2D3476;
display:flex;
justify-content:center;
align-items:center;
font-size:12px;
font-family: "Poppins", sans-serif;
h1{
font-size:40px;
font-weight:900;
margin-bottom:15px;
}
`;



export default Pricing;