import React, {useState, useEffect} from 'react'
import { RiAddCircleLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { Body, AlignRight, BeepAdmminAlignContainer, ContainR} from '../Reuse_ui/Css'
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { getCookie } from '../Reuse_ui/Logic';
import  Button  from "react-bootstrap/Button";

const OnbardImport = () => {


  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);




  function handleCheck() {
    setWidth(window.innerWidth);
  }



  useEffect(() => {
      if(getCookie("asession") === undefined)
            window.location.href="/registerImport";
    window.addEventListener('resize', handleCheck);
  return () => {window.removeEventListener('resize', handleCheck);}
  },[]);






  return (
    <Body w={'100%'} h={'10vh'}  mh={"20vh"} style={{background:"#f5f5f5"}}>
         <BeepAdmminAlignContainer>
           <AlignRight  al={width < 768 ? 1 : 0} onClick={(e) => navigate('/registerImport')}>
            <ContainR>
            {getCookie("asession") 
              ?
               <div style={{width:"200px",height:"50px",zIndex:"1008"}}>
                  <Button> Signup Business &nbsp;<RiAddCircleLine color='#ffffff' size={20}/></Button>
               </div>
              : 
             console.log("n/a")
            }
            </ContainR>
           </AlignRight>
         </BeepAdmminAlignContainer>
    </Body>
  )
}

export default OnbardImport