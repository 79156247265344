import { useEffect, useState } from "react";

export default function useOnScreen(ref){
    const [isInter, setIsInter] = useState(false);

    const observer =  new IntersectionObserver(([entry]) => setIsInter(entry.isIntersecting));

    useEffect(() => {
        observer.observe(ref.current);
        return () => { observer.disconnect();}
    },[])

    return isInter;
}