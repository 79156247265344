import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../Reuse_ui/Widget';
import {connect} from 'react-redux';
import { LoginAuth, fireMessage, Tr } from '../actions_api';
import {Acounts} from '../Reuse_ui/Css'
import { useNavigate } from 'react-router-dom';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import {Btn} from '../Reuse_ui/Css';
import {Oval} from 'react-loader-spinner'
import { Get_session, Message, } from '../Reuse_ui/Logic';
import Header from './Header';
import Footer from './Footer';



const Form = (props) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [show, setshow] = useState(false);
  const navigate = useNavigate();


  const NavigateToSignIn = () => {
    navigate("/signIn");
  }

  const Send = async (n) => {
    if(Tr(name) && Tr(email) && Tr(subject) && Tr(message)){
       let res = await fireMessage(n);
         Message("Info",res.message,"success");
     }else  
        Message("Info","Pls fill out all fields","info");
  }

  return (
    <>
    <Header/>
      <Container>
        <Boxed>
            <Input>
              <Type>Contact Form</Type>
                <USERDATA>
                      <input onChange={(e) => setName(e.target.value)} value={name} placeholder='Name' type="text"/>
                      <input onChange={(e) => setEmail(e.target.value)}  value={email} placeholder='Email' type="email"/>
                      <input onChange={(e) => setSubject(e.target.value)}  value={subject} placeholder='Subject' type="text"/>
                      <textarea onChange={(e) => setMessage(e.target.value)}  value={message} placeholder='message' type="text"/>
                      <div onClick={(e) =>{ Send([name,email,subject,message]); setshow(true);}}>
                        {show ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Submit'}  icon={null}  size={"100%"}/>}
                      </div>
                  </USERDATA>
              </Input>
        </Boxed>
      </Container>
      <Footer/>
    </>
  )
}



const Container = styled.div`
width: 100%;
height:100vh;
display:flex;
background:#f5f5f5;
justify-content:center;
@media(max-width:768px){
height:150vh;
}
`;


const Boxed = styled.div`
margin:0 auto;
width:50%;
height:100%;
display:flex;
margin-top:150px;
background:#ffffff;
@media(max-width:1000px){
width:90%;
}
@media(max-width:768px){
height:60%;
margin-top:25%;
padding-bottom:120px;
border-radius:10px;
}
`;

const Input = styled.div`
width:100%;
height:100%;
`;

const Banner = styled.div`
width:50%;
height:100%;
margin-left:auto;
background:blue;
@media(max-width:768px){
display:none;
}
`;



const Type = styled.div`
width:99.5%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:5px;
padding-left:10px;
@media(max-width:768px){
width:100%;
font-size:12px;
padding-bottom:10px;
padding-left:0px;
border-radius:5px 5px 0px 0px;
display:flex;
justify-content:center;
align-items:center;
}

`;

const TopLayer = styled.div`
width:100%;
height:auto;
display:flex;
background:#4B4AB3;
justify-content:space-between;
`;


const H5 = styled.div`
padding:10px;
color:#ffffff;
background:"#4B4AB3";
border-radius:5px;
margin:10px;
:hover {
background-color: #FF9900;
}
@media(max-width:768px){
margin:5px;
font-size:10px;
padding:5px;
}
`;


const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:5%;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}

textarea{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
height:140px;
}
`;






const mapStateToProps = (state) => {
  return {user:state.userState.user,};
};


const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps,mapDispatchToProps)(Form)