import styled from 'styled-components'
import React, { useEffect,useState} from 'react'
import { Ri24HoursFill, RiBook2Fill, RiBook3Fill, RiGpsFill, RiPhoneFill, RiRestaurant2Fill } from 'react-icons/ri'
import { Body, Space } from '../Reuse_ui/Css'
import {Container,CardInfo,Meta,TopUps} from '../Hover/Css'
import { Button, ButtonIcon,ButtonWidth} from '../Reuse_ui/Widget'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';







const Institution = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleCheck() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
    },[])

  return <>
        <Header/>
          <Body w={'100%'} h={'auto'}  mh={"180vh"}  style={{background:"#f5f5f5"}}>
            <Space/>
                <Container>
                    <CardInfo>
                    <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/tjbsipxphnqpfbfjajal')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                        <Meta w={'90%'}>
                            <div>
                                <h1>BeePPoint multiverse card</h1>
                                Welcome to the limitless world of BeePPoint! BeePPoint card makes your 
                                payment and all forms of transactions hustle-free and instant. Beeping is fun!  
                            </div>
                            <ButtonIcon size={width <=768 ? '90%' : '50%'}  icon={<Ri24HoursFill/>}  text={'How to get beeppoint card?'} />
                        </Meta>
                    </CardInfo>
                    <TopUps>
                     {width > 768 ? "" :   <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/Institue_xa5cp5')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>} 
                     <Meta w={'40%'}>
                            <div>
                                <h1>Beeppoint Institution package</h1>
                                 <br/>
                                 Instant access point and database.
                                 <ul>
                                    <li>Easy  intra campus payment</li>
                                    <li>Easy  inter campus payment</li>
                                 </ul>
                            </div>
                            <br/>
                            <br/>
                            <ButtonIcon size={width <=768 ? '90%' : '50%'}  icon={<RiBook2Fill/>}  text={'View list of Institutions '} />
                        </Meta>  
                        {width <= 768 ? "" : <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/Institue_xa5cp5')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>}
                    </TopUps>
                </Container>
            </Body>
            <Footer/>
        </>
}




export default Institution