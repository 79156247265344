import React, { useEffect,useState} from 'react'
import { Ri24HoursFill, RiArrowDownFill, RiArrowDropDownFill, RiGpsFill, RiPhoneFill, RiRestaurant2Fill } from 'react-icons/ri'
import styled from 'styled-components'
import { Body, Space } from '../Reuse_ui/Css'
import {TabsContain} from '../Hover/Css'
import { Button, ButtonIcon,ButtonWidth} from '../Reuse_ui/Widget'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { QuestionList } from '../actions_api'
import Parser from 'html-react-parser'


const Questions = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const [list,setList] = useState([]);
  

    function handleCheck() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setList(QuestionList());
        window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
    },[])

  return <>
         <Header/>
           <Body w={'100%'} h={'auto'}  mh={"180vh"}  style={{background:"#f5f5f5"}}>
             <Container style={{paddingBottom:"40px"}}>
              <Space/>
                <Meta>Frequently Asked Questions</Meta>
                 <Question>We're always happy to help with questions you might have</Question>
                 <TabsContain>
                  {list.map((v,i) =>
                      <details className='intro_question_faq'>
                          <summary>
                             <h5  className="faq_view">
                                  {v.question}
                              </h5> 
                              <span className="view_drop"><RiArrowDropDownFill  size={35}/> </span>
                          </summary>
                          <span className="faq_view">
                              <br/><br/> 
                              {Parser(v.answear)}
                          </span>
                      </details>
                      )}
                 </TabsContain>
                </Container>
              </Body>
              <Space/>
            <Footer/>
        </>
}


const Container = styled.div`
width:100%;
height:auto;
display:flex;
flex-flow:column;
text-align:center;
align-items:center;
margin:0 auto;
justify-content:center;
font-family: "Poppins", sans-serif;
@media(max-width:900px){
width:100%;
margin-bottom:100px;
}
`;


const Meta =styled.div`
width: 100%;
height: 40px;
font-weight: 950;
font-size:2em;
display: flex;
justify-content: center;
align-items: center;
color: #2D3476;
padding-top: 5px;
padding-bottom: 50px;
@media(max-width:768px){
width:80%;
margin:auto;
font-size:1.3em;
}
`;



const Question = styled.div`
font-size:1.1em;
justify-content: center;
align-items: center;
color: #565671;
margin: 0 auto;
font-weight:900;
@media(max-width:768px){
width:70%;
text-align:center;
font-size:12px;
}
`;





export default Questions

