import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {v4 as uuid4} from 'uuid'
import Swal from 'sweetalert2';
import InScmodel from './InScmodel';
import { Message, ToUpper } from '../Reuse_ui/Logic';
import { ButtonIcon } from '../Reuse_ui/Widget';
import { useNavigate } from 'react-router-dom';
import { RiAddFill, RiCamera2Line, RiCloseLine, RiShareCircleLine, RiAddLine, RiUpload2Fill, RiCloseCircleLine, RiSortAsc} from 'react-icons/ri';
import {AddNewMemebr, addSingleNode, disabeUser, CheckNode, PullScansAndInvite,QueryInvitesScansByDate,Format} from '../actions_api'
import { ContainC, ContainR, WriteUps ,CenterElement} from '../Reuse_ui/Css';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const Model = (props) => {


    const [shareImage, setShareImage] = useState("");
    const [progress, setprogress] = useState("");
    const [phoneEmail ,setPhoneEmail] = useState("");
    const [father ,setFather] = useState("");
    const [sunname ,setSuname] = useState("");
    const [mother, setMother] = useState("");
    const [children, setChildren] = useState("");
    const [singleName,setSingleName] = useState("");
    const [MailEmail, setMailEmail] = useState("");
    const [famId, setFamId] = useState("");
    const [screenSize, setScreenSize] = useState(0);
    const [currrentSelectedated, setCurrrentSelectedated] = useState(new Date().toLocaleDateString());
    const [startDate, setStartDate] = useState(new Date());
    const [shwoDate, setShowDate] = useState(false);
    const [shwoDateIcon, setShowDateIcon] = useState(false);
    const [Imglist, setImgList] = useState([]);
    const [scivList, setScIvList] = useState([]);
    const [lui, setLui] = useState(10);
    const history = useNavigate();


  

    const PostData = (e) => {
        setFamId(props.list[0].email === undefined ?  props.list[0].doc_id : props.list[0].email);
        props.ui(null,1);
    }



    
    const QueryDate = async (date) => {
        setShowDate(false);
        let dt = Format(date);
         setCurrrentSelectedated(dt);
          let res = await QueryInvitesScansByDate(famId,dt,sessionStorage,lui);
           if(res){
            setShowDateIcon(true);
            setScIvList(res.data.message);
           }
   }  
   


    const Invite = async (value,index) => {
        let res = await PullScansAndInvite(value,index,sessionStorage);
        props.ui(null,2);
        setLui(index);
        setFamId(value);
        setScIvList(res.message);
    }


    const AddNewMember = async (e) => {
        if(props.userType.service !== process.env.REACT_APP_SCHOOL_NODES.toUpperCase() ? validate(0) : validate(1)){
          let res = await  AddNewMemebr(sunname,father,mother,children,phoneEmail,sessionStorage);
            Message("info",res.message,"info"); 
          props.fun(e);
        }else 
            Message('Form validation','Pls fill out all fields','warning')        
    }       




    const AddSingleMember = async (i) => {
        if(validate2()){
            let res = await addSingleNode(singleName,MailEmail,i,sessionStorage);
             Message("Info",res.message,"info");
        }
    }



    function validate(index){
       return index === 0 ? father.trim() && mother.trim() && children.trim() && phoneEmail.trim() : children.trim() && phoneEmail.trim()
    }

    function validate2(){
        return  MailEmail.trim() && MailEmail.trim() 
     }
   

    const disableAccount = async (i,ac) => {
        const { value: gas } = await Swal.fire({
            title: 'Authentication',
            input: 'text',
            inputLabel: 'Pls enter account password',
            icon:  'info',
            inputValidator: (value) => {
              if (!value) {
                return 'Invalid password'
              }else
                 SendRequest(i,ac,value);  
            }
          })
    }


    async function SendRequest(i,ac,val) {
        let res = await disabeUser(i,sessionStorage,ac);
          Message("Info",res.message,"info");
    }




     const ImageCall = async (value) => {
        let find = await CheckNode(value);
        setImgList(find.message)
     }



     async function LoopUrls(list){
        if(list.length > 1000)
           for(let e=0; e<list; e++)
             await ImageCall(list[e].url)
     }




     function handleCheck() {
        setScreenSize(window.innerWidth);
      }
      




    useEffect(() => {
        if(props.list === null){
            if(props.userType.service === ToUpper(process.env.REACT_APP_SCHOOL_NODES))
                ImageCall(props.list[0].EP)
            else
                LoopUrls(props.list)
        }
        window.addEventListener('resize', handleCheck);
      return () => {window.removeEventListener('resize', handleCheck);}
    },[])
   
  

    return(<Container>
                 <Content>
                    <Header>
                    <h2>Edit user</h2>
                     <Button  onClick={(e) => props.fun(e,1)}>X</Button>
                    </Header>
                    {props.list[0] !== null ?
                    <>
                    <SharedContent>
                        <UserInfo>
                            <ImageContainer>
                               {Imglist.map((v) => <img src={v === "n/a" ? "../assets/user.svg" : v} alt={v}/>)}
                            </ImageContainer>
                             &nbsp; <span>{props.list[0].name}</span>
                             &nbsp; <span>{props.list[0].email === undefined ?  props.list[0].doc_id : props.list[0].email}</span>
                        </UserInfo> 
                     </SharedContent>

                    <AGORA_SECTION>
                        <div id="me"/>
                        <div id="remote-container"/>
                    </AGORA_SECTION>

                    <ShareCreation>
                   
                        <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}}  onClick={() => Invite(props.list[0].email === undefined ?  props.list[0].doc_id : props.list[0].email,0)}>
                          Scan
                        </Button>   

                         <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} onClick={() => Invite(props.list[0].email === undefined ?  props.list[0].doc_id : props.list[0].email,1)}>
                          Invite
                        </Button>                     
                    
                        <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} id="join" onClick={() =>  disableAccount(props.list[0].email === undefined ?  props.list[0].doc_id : props.list[0].email, props.list[0])}>
                            {props.list[0].disabled === true ? "Unblock" : "Block"} 
                        </Button>

                                   
                        <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}}  disabled={false}  onClick={(e) => PostData(e)}>
                           {props.userType.service !== process.env.REACT_APP_SCHOOL_NODES.toUpperCase() ? "Add user" : "Update"} 
                        </Button>

                    </ShareCreation>
                    </>
                    : props.index == 0 ?
                    <CenterElement>
                        {props.userType.service !== process.env.REACT_APP_SCHOOL_NODES.toUpperCase()
                         ?
                         <>
                        <WriteUps placeholder='FamilyId or Surname' value={sunname} onChange={(e) => setSuname(e.target.value)}/>
                        <WriteUps placeholder='Father' value={father} onChange={(e) => setFather(e.target.value)}/>
                        <WriteUps placeholder='Mother' value={mother} onChange={(e) => setMother(e.target.value)}/>
                        </>
                        : 
                        null
                         }
                        <WriteUps placeholder={props.userType.service === process.env.REACT_APP_SCHOOL_NODES.toUpperCase() ? 'Student name' :'Children (Seprated by , )'} value={children} onChange={(e) => setChildren(e.target.value)}/>
                        <WriteUps placeholder="Phone or emails (Seprated _ )" value={phoneEmail} onChange={(e) => setPhoneEmail(e.target.value)}/>
                            <div  onClick={(e) => AddNewMember(e)}>
                               <Button><RiAddLine/> Add</Button> 
                            </div>
                     </CenterElement>
                     : props.index === 1 ?
                     <CenterElement>
                        <WriteUps placeholder={'Name'} value={singleName} onChange={(e) => setSingleName(e.target.value)}/>
                        <WriteUps placeholder="Phone or email" value={MailEmail} onChange={(e) => setMailEmail(e.target.value)}/>
                             <div  onClick={(e) => AddSingleMember(famId)}>
                                <Button><RiAddLine/> Add</Button> 
                            </div>
                     </CenterElement>
                     :
                     <CenterElement>
                        <DateHeader><div>{currrentSelectedated} {shwoDateIcon ? <RiSortAsc/> : "" }</div><Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} onClick={(e) => setShowDate(true)} >Select date</Button></DateHeader>
                        {shwoDate ? <DatePicker  selected={startDate} onChange={(date) => QueryDate(date)} /> : ""}
                        <Scroll>
                            {scivList.map((v,i) => <InScmodel v={v} i={i} ui={lui} key={i}/> )}
                        </Scroll>
                     </CenterElement>
                    }
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:999;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
top:32px;
margin: 0 auto;
padding-bottom:20px;


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const SharedContent = styled.div`
display:flex;
flex-direction: column;
flex-grow:1;
overflow-y:auto;
vertical-align: baseline;
background: transparent;
padding: 8px 12px;

::-webkit-scrollbar {
display: none;
}
`;



const UserInfo = styled.div`
display: flex;
align-items: center;
flex-flow:column;
padding: 12px 24px;
svg,img{
width:48px;
height:48px;
background-clip: content-box;
border:2px solid  transparent;
border-radius:50%;
margin:-5px;

}
span{
font-weight:600;
font-size:12px;
}
`;



const ShareCreation = styled.div`
display:flex;
justify-content: space-between;
padding: 10px;
`;






const AGORA_SECTION = styled.div`
#me{
position: relative;
width: 50%;
margin: 0 auto;
}
#me video{
position: relative !important;
}
#remote-container{
display: flex;
}
#remote-container video{
height: 200px;
position: relative !important;
}

`;



const ImageContainer = styled.div`
width:100%;
height:auto;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
`;


export const Scroll = styled.div`
height:60vh; 
width:300px;
overflow-y:scroll;
overflow-x:hidden;
::-webkit-scrollbar {
display: none;
}
`;


export const DateHeader = styled.div`
width:100%;
height:auto; 
margin:10px;
display:flex;
justify-content:space-between;
`;



const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({
    PostData: (payload) => dispatch(AddNewMemebr(payload)),
});

export default  connect(mapStateToProps,mapDistpachToProps)(Model);




    // let remoteContainer = document.getElementById("remote-container");

    // function addVideoStream(elementId){
    //     let streamDiv = document.createElement("div");
    //     streamDiv.id = elementId;
    //     streamDiv.style.transform = "rotateY(180deg)";
    //     remoteContainer.appendChild(streamDiv);
    // };



    // function removeVideoStream(elementId){
    //         let remoteDiv = document.getElementById(elementId);
    //         if(remoteDiv) 
    //             remoteDiv.parentNode.removeChild(remoteDiv);
    // }


    //  let options = {
    //     appId : process.env.REACT_APP_APP_ID_AGORA,
    //     channel: "test",
    //     token: "0064c579a842acd4bacbb3d245b57d27911IAAYqE5uAi93yko9KhGxMdpUEMb1w+nS7kREZAyjm83fyAx+f9gAAAAAEAD5orABlYyTYQEAAQCMjJNh",
      
    // }


    // const Request = () => {

    //         let client = AgoraRTC.createClient({
    //             mode:"rtc",
    //             codec: "vp8",
    //         });

        

    //         client.init(options.appId, function(){
    //             console.log("client success");
    //         }, function(err){
    //             console.log("client init failed ", err);
    //         });



    //         client.join(options.token, options.channel, null, (uid) =>{

    //             let localStream = AgoraRTC.createStream({
    //                 video: true,
    //                 audio: true,
    //             });

    //             localStream.init(() => {
    //                 localStream.play('me');
    //                 client.publish(localStream, handelError);
    //            }, handelError);
    //         }, handelError);



    //         client.on('stream-added', function (evt){
    //             client.subscribe(evt.stream, handelError);
    //         });
            

            
    //         client.on('stream-subscribed', function (evt){
    //             let stream = evt.stream;
    //             let streamId = String(stream.getId());
    //             addVideoStream(streamId);
    //             stream.play(streamId);
    //         });


    //         client.on('stream-removed', function (evt){
    //             let stream = evt.stream;
    //             let streamId = String(stream.getId());
    //             stream.close();
    //             removeVideoStream(streamId);
    //         });
        

    //         client.on('peer-leave', function (evt){
    //             let stream = evt.stream;
    //             let streamId = String(stream.getId());
    //             stream.close();
    //             removeVideoStream(streamId);
    //         });

    //     }; 