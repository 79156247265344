import {Box,Btn,Card,Write,Pics,Contains,Write1,Container01,EditButton} from "./Css"
import AnimateHeight from 'react-animate-height';
import {BrowserView,MobileView}  from 'react-device-detect'
import {Oval} from 'react-loader-spinner'
import {AdvancedImage, lazyload} from '@cloudinary/react';




export const Button = ({img,write,direct,w}) => {
        return (
            <Box  ui_call={"U"} w={w} t={"column"}>
                <AdvancedImage  d={direct} cldImg={img} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                <p>{write}</p>
              <button>{direct}</button>
          </Box>
    )
}




export const Writes = ({src,text,f}) => {
    return (
          <>
            <BrowserView>
               <Contains>
                    {f === 1 ?
                    <div>
                     <div/>
                      <img loading='lazy' src={src} />
                    </div>
                    : "" }
                    <table>
                        {text.map((v,i) =>  
                        <ul>
                            <li>
                                {v}
                            </li>
                        </ul>
                        )}
                    </table>
                    {f === 2 ?
                    <div>
                      <div/>
                       <img loading='lazy' src={src} />
                    </div>
                    : "" }
              </Contains>
            </BrowserView>
            <MobileView>
               <Contains>
                <div>
                 <div/>
                  <img loading='lazy' src={src} />
                </div>
                <table>
                    {text.map((v,i) =>  
                    <tr>
                        <td>
                            {v}
                        </td>
                    </tr>
                    )}
                </table>
                </Contains>
            </MobileView>
         </>
    )
}



export const UserModel = ({img,name,rank}) => {
    return(
        <Card>
            <Pics>
                <img loading='lazy' src={img}/>
            </Pics>
            <Write>
                <Write1>              
                    <table>
                        <tr>
                            <td>{name}</td>
                        </tr>
                        <tr>
                            <td>{rank}</td>
                        </tr>  
                    </table>
                </Write1>
            </Write>
        </Card>
    )
}



export const Dropmodel = ({data,height}) => {
    return (
        <div style={{backgroundColor:"red"}}>
        <AnimateHeight height={height}  duration={1000}>
         <ul className="nav__submenu">
          <li className="nav__submenu-item ">
            <a>Oulur Company</a>
          </li>
          <li className="nav__submenu-item ">
            <a>Our Team</a>
          </li>
          <li className="nav__submenu-item ">
            <a>Our Portfolio</a>
          </li>
        </ul>
        </AnimateHeight>
        </div>
     
      )
} 


export const IMAGE = ({src}) => {
    return (
        <Container01>
            <img loading='lazy' src={src} />
        </Container01>
    )
}





export const ButtonIcon = ({icon,text,size,h,font}) => {
    return (
       <Btn  size={size} h={h} font={font}>{icon} &nbsp; {text} &nbsp; </Btn>
    )
}






