import React, { useState } from 'react'
import {PanelCards,EditButton} from '../Reuse_ui/Css'
import {RiAB, RiAccountCircleFill, RiAccountPinBoxLine, RiAddLine, RiBankCardFill, RiCommandLine, RiCommunityFill, RiCommunityLine, RiGroup2Line, RiGroupLine, RiLock2Line, RiPhoneFill, RiShieldCheckFill} from 'react-icons/ri'

const Familymodel = ({v,i,open,icon}) => {


  const [Iconsize, setIconSize] = useState(15);  

  return (
    
        v.User === null || v.User === undefined ?
         null 
         :
        <PanelCards key={i}>
                <table>
                    <tr> 
                        <td>
                        <RiAccountCircleFill size={Iconsize} /> &nbsp; {v.User.name}
                        </td>
                    </tr>
                  <tr> 
                    <td>
                    {v.User.email !== undefined ? <><RiPhoneFill size={Iconsize} />&nbsp;{v.User.email}</> : <><RiAccountPinBoxLine size={Iconsize} />&nbsp;{v.User.doc_id}</>}
                    </td>
                </tr>
                <tr>
                    <td>
                    {v.User.verified !== undefined ? <><RiShieldCheckFill size={Iconsize} /> &nbsp;  {v.User.verified.toString()} </> : ""}
                    </td>
                </tr>
                <tr>
                    <td>
                    {v.User.hasCard !== undefined ? <><RiBankCardFill size={Iconsize}/> &nbsp; {v.User.hasCard.toString()} </> :  <>  <RiGroupLine size={Iconsize}/> &nbsp; {v.User.familySize} </> }
                    </td>
                </tr>
                <tr>
                    <td>
                    {<><RiLock2Line size={Iconsize} /> &nbsp; {v.User.disabled.toString()} </>}
                    </td>
                </tr>
           </table>
            <EditButton  onClick={(e) => open(e,v.User)}>
                {icon}
            </EditButton>
        </PanelCards>
  )
}

export default Familymodel