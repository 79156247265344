import logo from './logo.svg';
import {BrowserRouter,Routes,Router,Route} from "react-router-dom";
import Header from './Components/Header';
import HomePage from './Components/HomePage';
import Hero_gif from './Components/Hero_gif';
import Scroll from './Components/Scroll';
import Form from './Components/Form';
import AboutUs from './Components/AboutUs';
import Business from './Components/Business';
import Beeppointcard from './Hover/Beeppointcard'
import Wherecard from './Hover/Wherecard'
import Whereuse from './Hover/Whereuse'
import Institution from './Hover/Institution'
import TermsCondition from './Hover/TermsCondition'
import Rewards from './Hover/Rewards'
import ReactPlayer from 'react-player'
import SignUp from '../src/Beepdmin/SignUp';
import Csv from '../src/Beepdmin/Csv'
import Pricing from './Auths/Pricing';
import AdminPricing from '../src/Beepdmin/Pricing';
import Cpanel from './Admins/Cpanel';
import Invites from './Admins/Invites';
import Questions from './Hover/Questions';
import Index from './Beepdmin/Index';
import Nodes from './Beepdmin/Nodes';
import Register from './Beepdmin/Register';
import Siginin from './Beepdmin/Siginin';
import Login from './Auths/Login';
import BusinesMap from './Components/BusinesMap';
import Reset from './Auths/Reset';
import Mreset from './Auths/Mreset';
import CheckInternet from './actions_api/CheckInternet';
import Update from './Admins/Update';
import GiftCard from './Components/GiftCard';
import Footer from './Components/Footer';
import Importer from './Beepdmin/Importer';
import ImporterRegister from './Auths/ImportRegister';
import Staff from './Beepdmin/Staff';
import StaffLogin from './Beepdmin/StaffLogin';
import Convart from './Beepdmin/Convart';





function App() {
  return (
   //  <CheckInternet>
               <div className="App">
                  <BrowserRouter>

                     <Routes>
                     <Route exact path="/"  element={<HomePage/>}/>   
                     </Routes>

                     <Routes>
                        <Route exact path="/business"  element={<Header/>}/>
                     </Routes> 

                     <Routes>
                        <Route exact path="/business"  element={<Business/>}/>
                     </Routes>     
                     
                     <Routes>
                        <Route exact path="/signIn"  element={<SignUp/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/registerImport"  element={<ImporterRegister/>}/>
                     </Routes>

                     

                     <Routes>
                        <Route exact path="/login"  element={<Login/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/reset"  element={<Reset/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/csv" element={ <Csv/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/login1" element={ <Siginin/>}/>
                     </Routes>



                     <Routes>
                        <Route exact path="/stafflogin" element={ <StaffLogin/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/register1" element={ <Register/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/Beeppoint+access" element={ <Nodes/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/Beeppoint+education" element={ <Nodes/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/Beeppoint+reward" element={ <Nodes/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/Beeppoint+park" element={ <Nodes/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/Beeppoint+import" element={ <Importer/>}/>
                     </Routes>


                     <Routes>
                        <Route exact path="/Users" element={ <Index n={1}/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/payouts" element={ <Index n={2}/>}/>
                     </Routes>
                     

                     <Routes>
                        <Route exact path="/cp" element={ <Index n={0}/>}/>
                     </Routes>
                     

                     <Routes>
                        <Route exact path="/beeppoint+card"  element={<Header/>}/>
                     </Routes>

                     <Routes>
                        <Route exact path="/beeppoint+card"  element={<Beeppointcard/>}/>
                     </Routes>

                        <Routes>
                        <Route exact path="/pricing" element={<Pricing/>}/>    
                        </Routes>

                        <Routes>
                        <Route exact path="/price" element={<Pricing/>}/>    
                        </Routes>
                        

                        <Routes>
                        <Route exact path="/adminpricing" element={<AdminPricing/>}/>    
                        </Routes>


                        <Routes>
                           <Route  path="/cpanel/:c" element={<Cpanel/>}/>    
                        </Routes>

                        <Routes>
                           <Route   path="/rs/:e"  element={<Mreset/>}/>
                        </Routes>
                        
                        <Routes>
                           <Route   path="/gc/:e"  element={<GiftCard/>}/>
                        </Routes>


                        <Routes>
                           <Route  path="/help+support" element={<Form/>}/>    
                        </Routes>


                        <Routes>
                           <Route  path="/businesses+on+maps" element={<BusinesMap/>}/>    
                        </Routes>
                        
                        <Routes>
                           <Route  path="/businesses+on+maps" element={<Footer/>}/>    
                        </Routes>

                        <Routes>
                           <Route  path="/invites/:u" element={<Invites/>}/>    
                        </Routes>

                        <Routes>
                        <Route exact path="/Where+to+get+beeppoint+card"  element={<Wherecard/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/Where+can+beeppoint+card+be+used"  element={<Whereuse/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/beeppoint+institution+package"  element={<Institution/>}/>
                        </Routes>
                        
                        <Routes>
                           <Route exact path="/beeppoint+rewards"  element={<Rewards/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/Frequently+ask+questions"  element={<Questions/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/Terms+and+condition"  element={<TermsCondition/>}/>
                        </Routes>


                         <Routes>
                           <Route exact path="/Update"  element={<Update/>}/>
                        </Routes>


                        <Routes>
                           <Route exact path="/staff"  element={<Staff/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/convart/:e"  element={<Convart/>}/>
                        </Routes>

                        <Routes>
                           <Route exact path="/convart/:e"  element={<Convart/>}/>
                        </Routes>
                        

                        {/* <Routes>
                           <Route exact path="/about"  element={<Header/>}/>
                         </Routes>

                        <Routes>
                           <Route exact path="/about"  element={<AboutUs/>}/>
                        </Routes> */}

                  </BrowserRouter>
               </div>
     //</CheckInternet> 
  );
}




export default App;
