import React from 'react'
import {PanelCards,EditButton} from '../Reuse_ui/Css'
import {RiAccountCircleFill, RiAddLine, RiBankCardFill, RiCommunityFill, RiEdit2Fill, RiGpsFill, RiListCheck2, RiLock2Line, RiPhoneFill, RiShieldCheckFill, RiScan2Line} from 'react-icons/ri'
import { getCookie } from '../Reuse_ui/Logic'

const Marchant = ({v,i,open,icon}) => {
  return (
    
        v.User === null || v.User === undefined ? null  :
        <PanelCards key={i}>
                <table>

                    <tr> 
                    <td>
                      <RiAccountCircleFill/> &nbsp; {v.User.company_email}
                    </td>
                    </tr>
                 
                    <tr>
                    <td>
                      {<><RiGpsFill/> &nbsp; {v.User.company_name} </>}
                    </td>
                    </tr>

                    <tr>
                    <td>
                      {v.User.time ? <><RiGpsFill/> &nbsp; {v.User.time} </> : null}
                    </td>
                    </tr>

                    <tr>
                    <td>
                      {v.User.date ? <><RiGpsFill/> &nbsp; {v.User.date} </> : null}
                    </td>
                    </tr>

           </table>
            <EditButton>
  
                <div onClick={(e) => open(v.User,1)}>
                   <RiScan2Line size={20}/>
                </div>

                &nbsp;
                &nbsp;

                <div onClick={(e) => open(v.User,0)}>
                   <RiListCheck2 size={20}/>
                </div>
              

            </EditButton>
        </PanelCards>
  )
}

export default Marchant