import React from 'react'
import { Detector } from 'react-detect-offline'
import { RiWifiOffLine } from 'react-icons/ri';
import styled from 'styled-components'
function CheckInternet(props) {
  return (
    <Detector polling={true}   render={({online}) => (
                   online ? props.children :
                   <Container>
                     <Contain style={{paddingTop:'0px', textAlign:'center'}}>
                          <RiWifiOffLine  id='img'/>
                          &nbsp;
                          &nbsp;
                          <h1 style={{marginBottom:'5px'}}>Connection lost Pls check your internet connection.</h1>     
                     </Contain>
                     {props.children}
                   </Container>
                    
              )}
    />
  )
}


const Container = styled.div`
height:100vh;
width:100%;
position:relative;
z-index:99999;
`;

const Contain = styled.div`
height:7vh;
width:100%;
margin-top:0px;
background:red;
color:#fff;
position:absolute;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
z-index:99999;
font-size:9px;
#img{
width:25px;
height:25px;
margin-top:0px;
}
@media(max-width:768px){
height:5vh;
}
`;

export default CheckInternet