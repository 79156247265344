import React,{useState} from 'react'
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import {Oval} from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { fireMessage, Tr } from '../actions_api';
import {Message} from '../Reuse_ui/Logic';
import { Table } from 'react-bootstrap';

const  DiscoverQuestion = () => {



  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [show, setshow] = useState(false);
  const navigate = useNavigate();


  const NavigateToSignIn = () => {
    navigate("/signIn");
  }

  const Send = async (n) => {
    if(Tr(name) && Tr(email) && Tr(subject) && Tr(message)){
      setshow(true);
        let res = await fireMessage(n);
          Message("Info",res.message,"success");
     }else  
        Message("Info","Pls fill out all fields","info");
  }


  function navigating(){
    window.scrollTo(0,0);
    navigate('/businesses+on+maps');
  }

  return (
    <Container height={"120vh"} mh={"170vh"} style={{marginTop:"10px"}}>
     <LeftSpace>
        <h2>View
            BeePPoint <br/> business
             <br/>  nearby 
         </h2>
        <Button id='btn' onClick={(e) => navigating()}>View location</Button>
      </LeftSpace>
      <RightSpace>
             <Contact>
                <Card>
                  <h5>Contact us</h5>
                </Card>
                <Hold>
                   Get in Touch
                   <br/>
                   <USERDATA>
                      <input onChange={(e) => setName(e.target.value)} value={name} placeholder='Name' type="text"/>
                      <input onChange={(e) => setEmail(e.target.value)}  value={email} placeholder='Email' type="email"/>
                      <input onChange={(e) => setSubject(e.target.value)}  value={subject} placeholder='Subject' type="text"/>
                      <textarea onChange={(e) => setMessage(e.target.value)}  value={message} placeholder='message' type="text"/>
                      <div onClick={(e) =>{ Send([name,email,subject,message]); }}>
                        {show ? <Button><Oval color={"#fff"}  height={20} width={20}/></Button> : <Button> Submit </Button>}
                      </div>
                  </USERDATA>
                </Hold>
             </Contact>
      </RightSpace>
    </Container>
  )
}



export const Container = styled.div`
width:100%; 
display:flex;
height:${(props) => props.height};
justify-content:space-between;
font-family: "Poppins", sans-serif;
flex-flow:row;
@media(max-width:768px){
flex-flow:column;
height:200vh;
}
`;



const LeftSpace = styled.div`
width:50%; 
height:100%;
display:flex; 
justify-content:center;
align-items:center;
flex-flow:column;
h2{
margin-left:60px;
font-size:30pt;
margin-top:150px;
color:#302EA7;
width:90%;
font-weight:900;
}
#btn{
margin-left:-400px;
margin-top:25px;
}

@media(max-width:980px){
width:100%;
display:flex; 
justify-content:center;
align-items:center;
text-align:center;
h2{
margin-left:10px;
font-size:20pt;
width:95%;
}
#btn{
margin-left:0px;
}
}
`;



const RightSpace = styled.div`
width:50%; 
height:100%;
display:flex;
flex-flow:column;
@media(max-width:768px){
width:100%;
justify-content:center;
align-items:center;
}
`;



const Contact = styled.div`
width:85%; 
display:flex; 
margin-top:250px;
padding-bottom:50px;
padding-top:10px;
background:#ffffff;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
@media(max-width:768px){
width:90%;
height:1000px;
margin-top:150px;
flex-flow:column;
padding-bottom:100px;
}
`;



const Hold = styled.div`
width:100%; 
height:500px; 
display:flex; 
margin-top:70px;
justify-content:center;
text-align:center;
align-items:center;
flex-flow:column;
@media(max-width:768px){
margin-top:100px;
height:1000px; 
}

`;



const Card = styled.div`
width:50%; 
height:400px; 
margin-left:-100px;
margin-top:70px;
background:#302EA7;
color:#fff;
display:flex; 
flex-flow:column;
align-items:center;
justify-content:center;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
h5{
margin-top:20px;
}
table{
width:80%;
margin-top:50px;
}

@media(max-width:768px){
 margin-left:-20px;
 height:200px;
 padding:10px;
 text-align:center;
}

`;







const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
justify-content:center;
align-items:center;
margin-top:0%;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
textarea{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
height:140px;
}
@media(max-width:768px){
width:100%;
}
`;



export default DiscoverQuestion