import React from 'react'
import {PanelCards} from '../Reuse_ui/Css'
import styled from 'styled-components'


const InScmodel = ({v,i,ui}) => {
  return (
       <PanelCards key={i}>
            {ui === 0 ?
                <table>
                    <tr>
                        <td>
                        <b>Scans</b> 
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Invitee:  
                        <br/>
                        {v.inviteenumber}
                      </td>
                    </tr>
                    <tr>
                        <td>
                        AccessCode:  
                        <br/>
                        {v.accesscode}
                      </td>
                    </tr>
                    {v.access !== undefined && v.access !== null ?
                    <tr>
                        <td>
                        Access:  
                        <br/>
                        {v.access.toString()}
                      </td>
                    </tr>
                    :
                    ""}
                    <tr>
                        <td>
                        Start Time: 
                        <br/>
                        {v.timestart}
                      </td>
                    </tr>
                    <tr>
                        <td>
                        End Time: 
                        <br/>
                        {v.timeend}
                      </td>
                    </tr>
                    <tr>
                        <td>
                        Date: 
                        <br/>
                        {v.date}
                      </td>
                    </tr>
              </table>
             :ui === 1 ?
              <table>
                      <tr>
                          <td>
                          <b>Invite</b> 
                          </td>
                      </tr>
                      <tr>
                          <td>
                          Time:  
                          <br/>
                          {v.dateTime}
                        </td>
                      </tr>
                      <tr>
                          <td>
                          User:  
                          <br/>
                          {v.email}
                        </td>
                      </tr>
              </table>
              :
              ui === 2 ?
               <table>
                  <tr>
                    <td>                  
                        {v.amount != undefined ?
                        <>
                          Amount
                           <br/>
                          {v.amount}
                        </>
                      :
                      <>
                        Point 
                         <br/>
                        {v.point}
                      </>
                      }
                    </td>
                  </tr>
                  <tr>
                      <td>
                        Date: 
                        <br/>
                        {v.date}
                      </td>
                  </tr>
                  <tr>
                     <td>
                        {v.amount != undefined ?
                        <>
                          Gate Location: 
                           <br/>
                          {v.location}
                        </>
                      :
                      <>
                        Total 
                         <br/>
                        {v.total}
                      </>
                       }
                      </td>
                  </tr>
                  <tr>
                     <td>

                    </td>
                  </tr>
               </table>
              :
              null
           }
        </PanelCards>
  )
}


export default InScmodel