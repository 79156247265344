import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {PanelCards} from '../Reuse_ui/Css'
import  Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import {Replace,SendValidation,EditUsers} from '../actions_api'
import { Message } from '../Reuse_ui/Logic';
import { Oval } from 'react-loader-spinner';


const SubListMembers = ({v,i,k}) => {



  const [clicked, setClicked] = useState(false);
  

  const SendValidate = async () => {
    setClicked(true);
    let wait = await EditUsers(v);
    if(wait){
      setClicked(false);
        Message("Info",wait,"info")
    }

  }


  return (
       <PanelCards key={v.email}>
                <table>

                    <tr>
                     <td>
                        Name:  
                        <br/>
                        {v.name}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Email:  
                        <br/>
                        {v.email}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Card ID:  
                        <br/>
                        {v.cardId}
                      </td>
                    </tr>

                    <tr>
                     <td>
                        Member:  
                        <br/>
                        {v.member}
                      </td>
                     </tr>



                     <tr>
                     <td>
                        IsDisabled:  
                        <br/>
                        {v.isDisabled.toString()}
                      </td>
                     </tr>




                     <tr>
                     <td>
                        IsVerified:  
                        <br/>
                        {v.isVerified.toString()}
                      </td>
                     </tr>



                    <tr>
                     <td>
                       <Coner>
                        <Button onClick={(e) => SendValidate()} style={{fontSize:"9px"}}>{clicked ? <Oval color={"#fff"}  height={15} width={15}/> : !v.isDisabled ? "Disable" : "Enable"}</Button>
                       </Coner>
                      </td>
                    </tr>
                    
                </table>
        </PanelCards>
  )
}


const Coner = styled.div`
width:75%;
display:flex;
flex-flow:row;
margin-top:10px;
align-items:center;
justify-content:space-between;
`;


export default SubListMembers