import React,{useState,useEffect} from 'react'
import styled from "styled-components"
import Header from "./Header";
import Footer from "../Components/Footer";
import { Oval } from 'react-loader-spinner'
import {SendCom, ViewPayout} from '../actions_api'
import Onbardmechant from './Onbard'
import {Body,Center, ContainList, LeftTabs} from '../Reuse_ui/Css'
import InitPays from './InitPays';
import { Message } from '../Reuse_ui/Logic';

const Payouts = () => {

    const [list, setList] = useState([]);  



    const PullData = async ()  => {
        let obj = await ViewPayout([sessionStorage])

          if(!JSON.stringify(obj).includes("not"))
            setList(obj);
        else
           {
            Message("Info",obj,"info");
            setList([])
           }
    }





    const SendComs = async (v)  => {
        let obj = await SendCom(v)
          if(obj)
            Message("info",obj,"info");
    }






    useEffect(() => { 
        PullData();
    },[])

    return ( 
        <>
                <Header/>
                        <Body w={'100%'} h={'auto'}  mh={"auto"} style={{background:"#f5f5f5"}}>
                         <Onbardmechant/>
                                {list.length > 0 ?
                                        <ContainList>
                                            <LeftTabs>
                                              {list.map((v,i) => <InitPays v={v} i={i}  fun={SendComs} />)}
                                             </LeftTabs>
                                        </ContainList>
                                        :
                                        <Center>
                                          <Oval color={"#2D3476"}  height={40} width={40}/>
                                        </Center>
                
                                }
                </Body>
          <Footer/>
      </>

)
}

export default Payouts