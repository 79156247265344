import React,{useEffect} from 'react'
import styled from 'styled-components'
import { Body,Space} from '../Reuse_ui/Css'
import Footer from './Footer'
import {Writes} from '../Reuse_ui/Widget'
import { RiBankFill, RiBook3Fill, RiBookFill, RiCarFill, RiLock2Line, RiRecycleFill, RiRoadMapLine, RiSailboatFill} from 'react-icons/ri'
import AOS from 'aos';
import "aos/dist/aos.css";
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';

const ServiceBanner = () =>{


  return (
    <Body w={'100%'} h={'60vh'} style={{background:"#f5f5f5"}}>
        <Container>
        <Sectors>
          <LeftDiv>
              <h3>
                Services <br/> BeePPoint <br/> Offer's
              </h3>
              <h5>Beeppoint &#64; 360 </h5>
          </LeftDiv>
          <RightDiv>
          
            <Bubble1>
               <div>
                <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/real-estate-149902_1280_z9czib')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>  
               </div>
               <br/>
               <h4>Security</h4>
               <span>Achieve complete home automation and control your home from your mobile phone and smart terminals</span> 
            </Bubble1>

          
            <Bubble1>
                <div>
                  <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/recycling-304974_1280_1_nlqni9')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>  
                </div>
               <br/>
               <h4>Loyalty Points</h4>
               <span>Creating mutual bond between you and your favourite stores, also access products at dealers rate</span> 
            </Bubble1>


            <Bubble1>
              <div>
                <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/bell-6478079_1280_ypefme')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>  
              </div>
               <br/>
               <h4>Notification</h4>
               <span>Real time connectivity from your mobile phone to the smart terminals.</span> 
            </Bubble1>

           </RightDiv>
          </Sectors>
        </Container>

    </Body>
  )
}



const Container = styled.div`
width:100%;
height:auto;
display:flex;
flex-flow:column;
margin-left:auto;
margin-right:auto;
overflow:hidden;
justify-content:center;
font-family: "Poppins", sans-serif;
@media(max-width:900px){
width:100%;

}
`;


const Sectors = styled.div`
width:95%;
height:auto;
margin:0 auto;
display:flex; 
flex-flow:row;
border-radius:20px;
background-color:#34c9eb;
background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%);
@media(max-width:980px){
flex-flow:column;
width:95%;
border-radius:10px;
}
`;


const LeftDiv = styled.div`
width: 20%;
height:auto;
background:#302EA7;
color:white;
display:flex;
flex-flow:column;
justify-content:center;
border-radius: 15px 0px 0px 15px;
h3{
padding-left:20px;
font-weight:900;
font-size:40px;
}
h5{
padding-left:20px;
font-size:13px;
}
@media(max-width:980px){
width: 100%;
padding:15px;
border-radius: 15px 15px 0px 0px;
h3{
font-size:30px;
padding-left:0px;
text-align:center;
}
h5{
padding-left:0px;
text-align:center;
}
}
`;



const RightDiv = styled.div`
width: 80%;
padding:10px;
height:auto;
display:flex;
flex-flow:row;
justify-content:center;
@media(max-width:980px){
flex-flow:column;
width: 100%;
}
`;




const Bubble1 = styled.div`
width: auto;
height:300px;
display:flex;
text-align:center;
flex-direction:column;
justify-content:center;
border-radius:15px;
align-items:center;
border-radius:50% 50% 70% 30% / 40% 37% 63% 60%;
padding:10px;
border-radius:25px;
color:#302EA7;

img{
width:40px;
height:40px;
}


div{
border-radius:50%;
border: solid #fff;
padding:10px;
}

h4{
font-weight:900;
}

span{
font-size:12px;  
}

:hover{
background-color:#f5f5f5; 
border-radius:15px;
margin:10px;
}
`;






const ServiceBubble = styled.div`
width: 323px;
height:323px;
background:#f5f5f5;
display:flex;
text-align:center;
flex-direction:column;
justify-content:center;
border-radius:15px;
align-items:center;
border-radius:50% 50% 70% 30% / 40% 37% 63% 60%;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
padding:10px;
border-radius:25px;
img{
width:100%;
height:50%;
object-fit:contain;
}
`;





export default ServiceBanner;