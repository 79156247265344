import React from 'react'
import {PanelCards,EditButton} from '../Reuse_ui/Css'
import {RiAccountCircleFill, RiAddLine, RiBankCardFill, RiCommunityFill, RiHome3Line, RiLock2Line, RiPhoneFill, RiRegisteredFill, RiShieldCheckFill} from 'react-icons/ri'

const Studentmodel = ({v,i,open,icon}) => {
  return (
    <PanelCards key={i}>
                <table>
                    <tr> 
                        <td>
                        <RiAccountCircleFill/> &nbsp; { v.Student.EP}
                        </td>
                    </tr>
                    <tr> 
                        <td>
                        <RiHome3Line/> &nbsp; { v.Student.SN}
                        </td>
                    </tr>

                    <tr> 
                        <td>
                        <RiRegisteredFill/> &nbsp; { v.Student.dateTime}
                        </td>
                    </tr>
                  
                    <tr>
                        <td>
                        {<><RiLock2Line/> &nbsp; {v.Student.disabled.toString()} </>}
                        </td>
                    </tr>
           </table>
            <EditButton  onClick={(e) => open(e,v.Student)}>
                {icon}
            </EditButton>
        </PanelCards>
  )
}

export default Studentmodel