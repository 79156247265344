import React from 'react'
import {PanelCards,EditButton,ContainC} from '../Reuse_ui/Css'
import {RiAccountCircleFill,RiShieldCheckFill, RiTerminalBoxFill, RiAccountBoxLine,RiUserLocationLine,RiBankCardFill, RiMailFill, RiGpsFill} from 'react-icons/ri'
import styled from 'styled-components'
import { IoT1ClickDevicesService } from 'aws-sdk'


const Terminalmodel = ({v,i,open,live,i1,i2,ui}) => {
  return (
       <PanelCards key={i}>
            <table>
                 <tr>
                    <td>
                     <RiTerminalBoxFill/> &nbsp;{v.Assigned.terminal_id}
                    </td>
                </tr>
                 <tr>
                    <td>
                    Disabled:  &nbsp; {v.Assigned.disabled.toString()}
                   </td>
                </tr>
                <tr>
                    <td>
                     Location: {v.Configure.terminalLocation}
                   </td>
                </tr> 
           </table>
           <Contain>
              <EditButton  onClick={(e) => open(e,1,v.Assigned.terminal_id,v.Assigned.disabled)}>
                  {i1}
              </EditButton>
              <EditButton  onClick={(e) => live(e,2,v.Assigned.terminal_id)}>
                  {i2}
              </EditButton>
           </Contain>
       </PanelCards>
  )
}



const  Contain = styled.div`
width:50px;
height:100px;
margin-left:auto;
display:flex;
flex-flow:column;
`;

export default Terminalmodel