import React,{useState,useEffect} from 'react'
import { Body } from '../Reuse_ui/Css'
import { useParams } from 'react-router-dom';
import { ScansBeep} from '../actions_api';
import Cheader from './Cheader';
import Footer from '../Components/Footer'

const Invites = () => {

 const {m} = useParams();

 useEffect(() => {
    SendOut();
 },[m]);



 const SendOut = async () => {
    let res = await ScansBeep(m);
 }


  return (
    <>
      <Cheader/>
        <Body  w={'100%'} h={'80vh'}  style={{background:"#f5f5f5"}}>

        </Body>
      <Footer/>
    </>
  )
}

export default Invites