import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../Reuse_ui/Widget';
import { Add_session, Get_session, Message } from '../Reuse_ui/Logic';
import  * as XLSX from 'xlsx';

const CSV = () => {

  const [tabs, setTabs] = useState(['School','Car Park','Estate']);
  const [width, setWidth] = useState(window.innerWidth);
  const [csvItems, setCsvItems] = useState([]);
  const [csv, setcsv] = useState("");
  const inactive = {}





  const readSheet = (e) => {
    let list = [];
      e.preventDefault();
      var files = e.target.files, f = files[0];
       if(files[0]['name'].substr(files[0]['name'].length - 4) === "xlsx"){
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, {type: 'binary'});
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];
                const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
                  dataParse.forEach((value,index) => {
                      if(index !== 0 && Get_session(process.env.REACT_APP_SESSION_NODES,sessionStorage)[4] === process.env.REACT_APP_ESTATE_NODES){
                          if(value.length === 5)  
                              csvItems.push({Family:{fN:value[0],F:value[1],M:value[2],C:value[3],EP:value[4]}});  
                                    
                        }else
                            if(index !== 0  && Get_session(process.env.REACT_APP_SESSION_NODES,sessionStorage)[4] === process.env.REACT_APP_SCHOOL_NODES){
                              if(value.length === 2)  
                                csvItems.push({Student:{SN:value[0],EP:value[1].toString()}}); 
                        }  
                     })    
            };
            reader.readAsBinaryString(f);
         }else
            Message('Invalid file','invalid excel file selected', 'warning')

  }



const Check = () => {
  if(csvItems.length > 0 ){
    localStorage.setItem('csv',JSON.stringify(csvItems));
      Add_session(process.env.REACT_APP_SESSION_NODES2,sessionStorage,['open'])
        window.location.href = "/adminpricing"
  }else
        Message('No file selected','pls select excel sheet of members','info');
}


  function handleCheck() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleCheck);
  return () => {window.removeEventListener('resize', handleCheck);}
  },[])


  return (
    <Container>
        <Boxed>
            <Input>
              <Type>&nbsp;&nbsp; Add Excel file of members</Type>
              <USERDATA>
                <Box>
                 <input
                   style={{border:"none", marginLeft:width > 768 ? "50px" : "30px"}}
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={(e) => {
                       readSheet(e);
                    }}
                  />

                </Box>
                <br/>
                <div onClick={(e) => Check()} >
                   <ButtonIcon  text={'Submit'}  icon={null}  size={"90%"}/>
                </div>
              </USERDATA>
            </Input>
            <Banner/>
        </Boxed>
    </Container>
  )
}


const Container = styled.div`
width: 100%;
height:100vh;
background:#f5f5f5;
display:flex;
justify-content:center;
`;


const Boxed = styled.div`
margin:0 auto;
width:80%;
height:70%;
display:flex;
margin-top:10%;
background:#ffffff;
@media(max-width:768px){
height:70%;
margin-top:40%;
border-radius:10px;
}
`;

const Input = styled.div`
width:50%;
height:100%;
@media(max-width:768px){
width:100%;
}
`;

const Banner = styled.div`
width:50%;
height:100%;
margin-left:auto;
background:blue;
@media(max-width:768px){
display:none;
}
`;



const Type = styled.div`
width:100%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:5px;
@media(max-width:768px){
width:100%;
font-size:12px;
padding-bottom:0px;
border-radius:5px 5px 0px 0px;
}
`;


const Box = styled.div`
height: 30vh;
width:80%;
border: 1px solid #f5f5f5;
border-radius:10px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
`;

const H5 = styled.div`
padding:10px;
color:#ffffff;
background:"#4B4AB3";
border-radius:5px;
margin:10px;
:hover {
background-color: #FF9900;
}
@media(max-width:768px){
margin:5px;
font-size:10px;
padding:5px;
}
`;


const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:50px;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}

`;

export default CSV