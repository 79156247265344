import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../Reuse_ui/Widget';
import {connect} from 'react-redux';
import { LoginAuth, Tr } from '../actions_api';
import {Acounts} from '../Reuse_ui/Css'
import { useNavigate } from 'react-router-dom';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import {Btn,Container,Boxed,Input,Banner} from '../Reuse_ui/Css';
import {Oval} from 'react-loader-spinner'
import { Get_session, Message } from '../Reuse_ui/Logic';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Login = (props) => {

  const [companymail, setcompanymail] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [pass, setpass] = useState("");
  const navigate = useNavigate();


  const NavigateToSignIn = () => {
    navigate("/reset");
  }


  const fireLogin = (e) => {
    setshowloader(true);
      LoginAuth(e,navigate);
  }



  return (
      <Container>
       <Boxed>
          <Input>
            <Type>{Get_session("em",sessionStorage) !== null && Get_session("em",sessionStorage) !== undefined ?  "Welcome Back" : "Sign in page"} </Type>
             <USERDATA>
              <input onChange={(e) => setcompanymail(e.target.value)} value={companymail} placeholder='Company Email'/>
              <input onChange={(e) => setpass(e.target.value)}  value={pass} placeholder='Password' type="password"/>
                 <br/>
                <div onClick={(e) =>{ Tr(companymail) && Tr(pass) ? fireLogin([companymail,pass]) : Message("Form validation","Pls fill out all fields !","warning")}}>
                  {showloader ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Login'}  icon={null}  size={"100%"}/>}
                </div>
              </USERDATA>
              <Acounts>
                   <MiniLogin>
                      Sign in facebook &nbsp; <RiFacebookCircleFill color='#2D3476'/>   
                  </MiniLogin>

                  <MiniLogin>
                      Sign in with Google &nbsp; <RiGoogleFill color='#2D3476'/>
                  </MiniLogin>
                </Acounts>
                <Acounts>
                 <div onClick={(e)=> NavigateToSignIn()}>Hav'nt reset account password? RESET</div>
               </Acounts>
            </Input>
            <Banner>
            <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true}>
              <div>
                <img src="./assets/carpark.gif"/>
              </div>
              <div>
                <img src="./assets/Education.gif"/>
              </div>
              <div>
                <img src="./assets/estate.gif"/>
              </div>
              <div>
                <img src="./assets/shop.gif"/>
              </div>
            </Carousel>
            </Banner>
       </Boxed>
     </Container>
  )
}






const TopLayer = styled.div`
width:100%;
height:auto;
display:flex;
background:#4B4AB3;
justify-content:space-between;
`;


const H5 = styled.div`
padding:10px;
color:#ffffff;
background:"#4B4AB3";
border-radius:5px;
margin:10px;
:hover {
background-color: #FF9900;
}
@media(max-width:768px){
margin:5px;
font-size:10px;
padding:5px;
}
`;




const MiniLogin = styled.button`
display: flex;
justify-content: center;
background-color: #fff;
align-items: center;
height: 50px;
width: auto
z-index: 0;
margin-top:30px;
margin-left:10px;
color:#2D3476;
cursor:pointer;
font-size: 12px;
padding-left:20px;
padding-right:20px;
border-radius: 28px;
vertical-align:middle;
transition-duration: 167ms;
border: 1px solid #f5f5f5;
&:hover{
background-color: rgba(207,207, 207, 0.25);
color: rgba(0, 0, 0, 0.75);
}
@media(max-width:900px){
font-size:8pt;
margin-top:10px;
padding-left:5px;
padding-right:5px;
}
`;



export const Type = styled.div`
width:100%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:5px;
padding-left:10px;
@media(max-width:768px){
width:100%;
font-size:12px;
padding-bottom:10px;
padding-left:0px;
border-radius:5px 5px 0px 0px;
display:flex;
justify-content:center;
align-items:center;
}
`;



export const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:20%;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
`;


const mapStateToProps = (state) => {
  return {user:state.userState.user,};
};


const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps,mapDispatchToProps)(Login)