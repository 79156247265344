import React, { useEffect, useState } from 'react'
import {Message,Add_session} from '../Reuse_ui/Logic'
import {ButtonIcon } from '../Reuse_ui/Widget';
import styled from 'styled-components'
import {StaffNewMemberRegistation} from '../actions_api'
import {Btn} from '../Reuse_ui/Css'
import {Oval} from 'react-loader-spinner'
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import { useNavigate } from 'react-router-dom';


const Regsiter = (props) => {

const [phone, setPhone] = useState("");
const [email, setEmail] = useState("");
const [name, setName] = useState("");
const [password, setpassword] = useState("");
const [show, setShow] = useState(false);
const naviagte = useNavigate();


const Check = async () => {
    if(name.trim().length > 0 && email.trim().length > 0 && phone.trim().length > 0 && password.trim().length > 0){
        setShow(true);
          let res = await StaffNewMemberRegistation(name,email,phone,password,sessionStorage);
          if(res){
            setShow(false);
            if(!res.message.toString().includes("exists")){
                  Message("Info",res.message,"success");
                    naviagte("/staff");
            }else
                Message("Info",res.message,"info");
          }
        }else 
             Message("SignUp Error","Pls fill out all fields","warning");
  }
  




  return (
    <Container>
        <Boxed>
            <Input>
             <Type>Signup Users</Type>
              <USERDATA>
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' type="text"/>
                <input value={email} onChange={(e) => setEmail(e.target.value)}  placeholder='Email' type="email"/>
                <input value={phone} onChange={(e) => setPhone(e.target.value)}  placeholder='Phone' type="number"/>
                <input value={password} onChange={(e) => setpassword(e.target.value)}  placeholder='Password' type="password"/>
                 <br/>
                  <div onClick={(e) => Check()}>
                    {show ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon  text={'Register'}  icon={null} h="50px"  size={"65%"} />}  
                  </div>
              </USERDATA>
            </Input>
        </Boxed>
    </Container>
  )
}

const Container = styled.div`
width: 100%;
height:110vh;
background:#f5f5f5;
display:flex;
justify-content:center;
margin-bottom:200px;
@media(max-width:768px){
height:100vh;
}
`;


const Boxed = styled.div`
margin:0 auto;
width:50%;
height:auto;
display:flex;
margin-top:100px;
background:#ffffff;
border-radius: 5px;
padding-bottom:100px;


@media(max-width:1000px){
width:90%;
}
@media(max-width:900px){
height:auto;
margin-top:20%;
border-radius:10px;
}`;

const Input = styled.div`
width:100%;
height:auto;
@media(max-width:900px){
width:100%;
}
`;



const Type = styled.div`
width:98.5%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:5px;
padding-left:10px;
text-align:center;
border-radius:10px 10px 0px 0px;
@media(max-width:768px){
width:100%;
font-size:12px;
padding-bottom:10px;
padding-left:0px;
border-radius:5px 5px 0px 0px;
display:flex;
justify-content:center;
align-items:center;
}
`;






const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:50px;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
`;



export default Regsiter