import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {v4 as uuid4} from 'uuid'
import Swal from 'sweetalert2';
import { Message, Get_session} from '../Reuse_ui/Logic';
import { ButtonIcon } from '../Reuse_ui/Widget';
import { useNavigate } from 'react-router-dom';
import {Oval} from 'react-loader-spinner'
import { RiAddFill, RiCamera2Line, RiCloseLine, RiShareCircleLine, RiAddLine, RiUpload2Fill, RiCloseCircleLine, RiDeleteBin2Fill } from 'react-icons/ri';
import { ContainC, ContainR, WriteUps ,CenterElement, Texts} from '../Reuse_ui/Css';
import {SendRequest,SendCommand} from '../actions_api'


const Tmodel = (props) => {


    const [terminal_count, setTerminal_count] = useState("");
    const [locations, setLocations] = useState("");
    const [showloader, setShowloader] = useState(false);
    const [enginnerName ,setEnginnerName] = useState("");
    const [enginnerEmail ,setEnginnerEmail] = useState("");
    const [adminPass ,setAdmiPass] = useState("");
    


    const history = useNavigate();



    function validate2(){
        return  terminal_count.trim() && locations.trim() && enginnerName.trim() && enginnerEmail.trim()
     }


     function validate3(){
        return adminPass.trim()
     }
   
  
    const validateAccount = async (n) => {
        setShowloader(true);
            if(n === 0) {
                const { value: gas } = await Swal.fire({
                    title:'Authentication',
                    input:'text',
                    inputLabel: 'Pls enter account password',
                    icon:'info',
                    inputValidator: async (value) => {
                    if (!value) {
                        return 'Invalid password'
                    }else{
                        if(Get_session("dm",sessionStorage)){
                            let co = locations.split(",").length;
                            if( co == parseInt(terminal_count)){
                                let res = await SendRequest(terminal_count,locations,enginnerName,enginnerEmail,props.v);
                                    if(res){
                                        setShowloader(false);
                                        Message("Info",res.message,'info');
                                    }
                             }else
                                Message("Info","Size not match number of location",'info');
                            }  
                        else   
                            Message("Login","Recent Sigin is required ",'info');
                        }

                    }
                })
            }
            else  
               if(n === 1){
                if(Get_session("dm",sessionStorage) !== undefined &&  Get_session("dm",sessionStorage) !== null){
                        let wait = await SendCommand(props,adminPass);
                         setShowloader(false);
                            Message("Info",wait.message,"info");
                    }
                    else
                      Message("Info","Pls sign in","info");
               }
    }
  

    return(<Container>
                 <Content>
                    <Header>
                    <h2>{props.i === 3 ? "Add" : "Edit"} Terminal</h2>
                     <button  onClick={(e) => props.fun(e,1)}>X</button>
                    </Header>
                        {props.v !== null ?
                            <CenterElement>
                                {props.i === 3 ?
                                 <>
                                    <WriteUps placeholder={'Terminal count'} value={terminal_count} onChange={(e) => setTerminal_count(e.target.value)}  type={"number"}/>
                                    <WriteUps placeholder="Locations (Seprated _ )" value={locations} onChange={(e) => setLocations(e.target.value)}/>
                                    <WriteUps placeholder="Engineer name" value={enginnerName} onChange={(e) => setEnginnerName(e.target.value)}/>
                                    <WriteUps placeholder="Engineer email" value={enginnerEmail} onChange={(e) => setEnginnerEmail(e.target.value)}/>
                                 </>
                                :
                                props.i === 2 ?
                                    <>
                                        <Texts>{props.v.company_email}</Texts>
                                        <Texts>{props.v.company_name}</Texts>
                                        <WriteUps placeholder="Enter password" value={adminPass} onChange={(e) => setAdmiPass(e.target.value)} type={'password'}/>   
                                    </>
                                :   
                                props.i === 1 ?
                                 <>
                                     <Texts>{props.v.company_email}</Texts>
                                     <Texts>{props.v.company_name}</Texts>
                                     <WriteUps placeholder="Enter password" value={adminPass} onChange={(e) => setAdmiPass(e.target.value)} type={'password'} />  
                                 </>
                                :
                                null
                                }
                                 <div  onClick={(e) => validate2() && props.i === 3 ? validateAccount(0) : validate3() && props.i !== 3 ?  validateAccount(1) : Message("Info","Pls fill out all fields !","info")}>
                                    {showloader ? <ButtonIcon icon={<Oval color={"#fff"}  height={20} width={30}/>} /> : <ButtonIcon icon={props.i === 3 ? <RiAddLine/> : <RiDeleteBin2Fill/>}  size={"50px"}  text={props.i === 3 ?  "Add" : "Send"} h={"30px"}/>}
                                 </div>

                            </CenterElement>
                        : null
                        }
                     </Content>
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:999;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
top:32px;
margin: 0 auto;
padding-bottom:20px;


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const SharedContent = styled.div`
display:flex;
flex-direction: column;
flex-grow:1;
overflow-y:auto;
vertical-align: baseline;
background: transparent;
padding: 8px 12px;

::-webkit-scrollbar {
display: none;
}
`;



const UserInfo = styled.div`
display: flex;
align-items: center;
flex-flow:column;
padding: 12px 24px;
svg,img{
width:48px;
height:48px;
background-clip: content-box;
border:2px solid  transparent;
border-radius:50%;
padding:5px;
}
span{
font-weight:600;
font-size:12px;
}
`;



const ShareCreation = styled.div`
display:flex;
justify-content: space-between;
padding: 10px;
`;






const PostButton = styled.button`
min-width:60px;
border-radius: 20px;
height: 35px;
right:2px;
display:flex;
justify-content:center;
align-items:center;
background: ${(props) => (props.disabled ?  "rgba(0,0,0,0.8)" : "#0a66c2")};
color: ${(props) => (props.disabled ? "rgba(1,1,1,0.2)": "white")};
&:hover{
background: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#004162")};
}
`;


const AGORA_SECTION = styled.div`
#me{
position: relative;
width: 50%;
margin: 0 auto;

}
#me video{
position: relative !important;
}
#remote-container{
display: flex;
}
#remote-container video{
height: 200px;
position: relative !important;
}

`;





const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({});

export default  connect(mapStateToProps,mapDistpachToProps)(Tmodel);



