import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'


export const remove_white_space = (data) => {
    return data.replace(/ /g,'+');
}

export function Message(title,text,icon){
    Swal.fire({title:title,text:text,icon:icon});
}

export function Add_session(node,session,list){
  session.setItem(node,JSON.stringify(list));
}


export function ToUpper(node){
   return node.toUpperCase();
  }

export function Get_session(node,session){
 return  JSON.parse(session.getItem(node));
}


export function Clear_session(session,node){
    session.removeItem(node);
}


export function FormatNumber(number,code){
    let format = number.substring(1,number.lenght);
    return "+"+code+format;
}


export function setCookie(name,value) {
    document.cookie = `${name}=${value}; path=/`;
}


export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


export function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export function checkService(service,banner){
    let url;

     if(service === process.env.REACT_APP_SCHOOL_NODES.toUpperCase())
        url = banner !== "n/a" ? banner : "../assets/schdef.jpeg" 
    else 
      if(service === process.env.REACT_APP_ESTATE_NODES.toUpperCase())
       url = banner !== "n/a" ? banner : "../assets/estatedef.jpeg" 
    else 
       if(service === process.env.REACT_APP_REWARD_NODES.toUpperCase())
         url = banner !== "n/a" ? banner : "../assets/shopdef.jpeg" 
    else 
       if(service === process.env.REACT_APP_CARPARK_NODES.toUpperCase())
          url = banner !== "n/a" ? banner : "../assets/carparkdef.jpeg";  
    else 
          if(service === "all")
             url = banner !== "n/a" ? banner : "https://res.cloudinary.com/otecdealings/image/upload/v1708463988/beeppoint/ASSETS/user_jr7hm9.svg"; 

   return url
}



export function checkServiceMarker(service){
    let url;
     if(service === process.env.REACT_APP_SCHOOL_NODES.toUpperCase())
        url =  "../assets/schoolT.png" 
    else 
      if(service === process.env.REACT_APP_ESTATE_NODES.toUpperCase())
       url = "../assets/etateT.png" 
    else 
       if(service === process.env.REACT_APP_REWARD_NODES.toUpperCase())
         url = "../assets/loyalPointT.png" 
    else 
       if(service === process.env.REACT_APP_CARPARK_NODES.toUpperCase())
          url = "../assets/parkT.png";  
   return url
}