import React,{useState,useEffect} from 'react'
import { Body, Center, ContainList, LeftTabs, RightTab} from '../Reuse_ui/Css'
import {MarchetList, PullScansAndInvite, requestSessionToken} from '../actions_api'
import Onbardmechant from './Onbard'
import EditTab from './EditTab'
import Marchant from './Marchant'
import Footer from '../Components/Footer'
import Header from './Header'
import { Oval } from 'react-loader-spinner'
import {getCookie} from '../Reuse_ui/Logic';
import { useParams } from 'react-router-dom'
import ScanModel from '../Model/ScanModel'






const Nodes = () => {

 
  const [list, setList] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [flow, setFlow] = useState(null);
  const [showModel, setShowModel] = useState("close");
  const param = useParams();


   const OpenDiv = (v,i) => {
      if(i === 0)
          setFlow(v);
      else
          OpenModel(v);

   }

   
 
 const OpenModel = (v) => {
    setNodeList(v);
      switch(showModel){
           case "open":
                 setShowModel("close");
                 break;
           case "close":
                 setShowModel("open");
                 break;
           default:
                setShowModel("close");
                break;
      }
    }

    

    
    async function Session (){
      setList(await requestSessionToken());
    }




    useEffect(() => {
       Session();
    },[])




  return (
    <>
    <Header/>
      <Body w={'100%'} h={'auto'}  mh={"auto"} style={{background:"#f5f5f5"}}>

        <Onbardmechant/>

         {list.length > 0 ?

          <ContainList>

            <LeftTabs>
              {list.map((v,i) => <Marchant v={v} i={i} open={OpenDiv} />)}
            </LeftTabs>

            <RightTab h={"120vh"}  mh={"100vh"} mt={"40%"} mtm={"90%"}>
              {flow ? 
                <EditTab data={flow} /> 
                  :
                <Center>
                  <Oval color={"#2D3476"}  height={40} width={40}/>
                </Center>
              } 
            </RightTab>

            {showModel === "open" ? <ScanModel  list={nodeList} fun={OpenModel} ui={1}/> : null}
            
        </ContainList>
        :
         <Center>
           <Oval color={"#2D3476"}  height={40} width={40}/>
          </Center>
        }
      </Body>
    <Footer/>
    </>
  )
}

export default Nodes