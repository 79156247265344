import React,{useEffect,useState} from 'react'
import { RiCloseCircleFill, RiDeleteBack2Fill, RiSpeaker2Fill, RiAddCircleLine, RiGift2Line, RiListCheck2, RiCoinFill} from 'react-icons/ri';
import {EstateBanner,WriteUps,ContainC, Center, ContainR} from '../Reuse_ui/Css'
import {ButtonIcon} from '../Reuse_ui/Widget';
import {checkService} from '../Reuse_ui/Logic';
import Tmodel from './Tmodel';
import {BusinessGiftCard} from '../actions_api'
import SubListModel from '../Model/SubListModel';



const  UserTab = ({data}) => {

  const [showModel, setShowModel] = useState("close");
  const [index, setIndex] = useState(9);

  const Open = (e,i) => {

  }




  return (
    <div>
        <EstateBanner mt={"100px"}>
            {<img  src={checkService(data.table,data.url)}/>}
            <br/>
         </EstateBanner>
         <div style={{marginLeft:"25px"}}>

            <WriteUps value={"Name: "+data.name} readOnly={true} /> 
            <WriteUps value={"Email: "+data.email} readOnly={true} /> 
            {data.phone ? <WriteUps value={"Phone: "+data.phone} readOnly={true} /> : null}
            <WriteUps value={"Sign up date: "+data.date} readOnly={true}/>
            <WriteUps value={"Card id: "+data.cardId} readOnly={true} />
            <WriteUps value={"Login: "+data.Islogin} readOnly={true} />
            <WriteUps value={"Disable: "+data.isDisabled} readOnly={true} />
            <WriteUps value={"Verified: "+data.isVerified} readOnly={true} />     
         </div>                       
         <ContainR>
            <div style={{margin:"0 auto"}}  onClick={(e) => Open(e,1)}> 
                <ButtonIcon font={"9px"} icon={<RiCloseCircleFill/>}  size={"auto"}  text={"Disable"} h={"30px"} />
            </div>
         
            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(e,2)}>
                <ButtonIcon font={"9px"}  icon={<RiCoinFill/>}  size={"auto"}  text={"Freeze funds"} h={"30px"}/>
            </div>

            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(e,2)}>
                <ButtonIcon font={"9px"}  icon={<RiDeleteBack2Fill/>}  size={"auto"}  text={"Delete"} h={"30px"}/>
            </div>
         </ContainR>

    </div>
  )
}

export default UserTab