import React,{useEffect,useState} from 'react'
import {Body} from '../Reuse_ui/Css'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { ButtonIcon} from '../Reuse_ui/Widget';
import { remove_white_space } from '../Reuse_ui/Logic'
import  Button  from 'react-bootstrap/Button';
import ServiceBanner from './ServiceBanner';
import { Back ,CenterDiv} from './BackGroundFlow';
import { RiAccountCircleFill, RiCheckFill, RiDownload2Fill } from 'react-icons/ri';
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';




const  Readmore = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  function handleCheck() {
      setWidth(window.innerWidth);
  }


  function navigating(){
    window.scrollTo(0,0);
    navigate('/business');
  }


  useEffect(() => {
      window.addEventListener('resize', handleCheck);
  return () => {window.removeEventListener('resize', handleCheck);}
  },[])



  return (
        <OverView>

          <ScreenShoots>
            <ServiceBanner/>
          </ScreenShoots>

      
            <Back h={"150vh"} u={"./assets/svg.png"}>
                   <Box>
                            <Screenshot>
                                <AdvancedImage  id="m3" cldImg={cld.image('beeppoint/ASSETS/Down1_vwv6cg')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                            </Screenshot>

                            <Steps>

                              <Child>
                                <div id='icon'>
                                    <RiDownload2Fill size={30}  />
                                </div>
                                <span>
                                  Download the app from app Store
                                </span>
                              </Child>

                              <Child>
                                <div id='icon'>
                                    <RiAccountCircleFill size={30} />
                                </div>
                                <span>
                                  Create an account on the app
                                </span>
                              </Child>


                              <Child>
                                <div id='icon'>
                                    <RiCheckFill size={30} />
                                </div>
                                <span>
                                  Connect to Beeppoint solutions
                                </span>
                              </Child>

                            </Steps>

                      </Box>
                </Back>   
              <Back h={"150vh"} u={"./assets/upside.png"}/>
        </OverView>
        
  
    
  )
}




const ScreenShoots = styled.div`
height:auto; 
width:90%;
margin-left:auto;
margin-right:auto;
@media(max-width:768px){
height:120vh;

}
`;



const OverView = styled.div`
width:100%;
`;


const Box = styled.div`
display:flex;
flex-flow:row;
@media(max-width:768px){
flex-flow:column;
}
`;


const Screenshot = styled.div`
height:100vh; 
width:50%;
display:flex;
flex-flow:column;
margin-top:320px;


#m3{
height:600px; 
width:100%;
margin: 0 auto;
object-fit:contain;
}

#m3{
-webkit-animation-name: bounce;
-webkit-animation-duration: 3.5s;
-webkit-animation-direction: alternate;
-webkit-animation-timing-function: cubic-bezier(
.5, 0.05, 1, .5);
-webkit-animation-iteration-count: infinite;
}

@media(max-width:768px){
width:100%;
margin-top:400px;

}
`;



const Steps = styled.div`
height:100vh; 
width:50%;
display:flex;
flex-flow:column;
justify-content:center;
align-items:center;
margin-top:320px;
@media(max-width:768px){
width:90%;
margin-left:auto;
margin-right:auto;
margin-top:50px;
}
`;



const Child = styled.div`
height:25%; 
width:80%;
display:flex;
justify-content:center;
align-items:center;
flex-flow:column;
margin:15px;
text-align:center;
background:#ffffff;
border-radius:15px;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


#icon{
border-radius:50%;
border: solid #fff;
width:60px;
height:60px;
background:#f5f5f5;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
font-weight:900;
color:#302EA7;
}

span{
font-weight:900;
color:#302EA7;
}

@media(max-width:768px){
width:100%;
}
`;


export default Readmore