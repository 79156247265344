import React,{useEffect,useState} from 'react'
import { RiCloseCircleFill, RiDeleteBack2Fill, RiSpeaker2Fill, RiAddCircleLine, RiGift2Line} from 'react-icons/ri';
import {EstateBanner,WriteUps,ContainC, Center, ContainR} from '../Reuse_ui/Css'
import {ButtonIcon} from '../Reuse_ui/Widget';
import {checkService} from '../Reuse_ui/Logic';
import Tmodel from './Tmodel';
import {BusinessGiftCard} from '../actions_api'



const  EditTab = ({data}) => {

  const [showModel, setShowModel] = useState("close");
  const [index, setIndex] = useState(9);

  const Open = (e,i) => {
      setIndex(i);
        e.preventDefault();
            switch(showModel){
              case "open":
                    setShowModel("close");
                    break;
              case "close":
                    setShowModel("open");
                    break;
              default:
                    setShowModel("close");
                    break;
          }
  }


  const AddGift = () => {
     let res =  BusinessGiftCard();
  }


  return (
    <div>
        <EstateBanner mt={"100px"}>
            {<img  src={checkService(data.service,data.bannerUrl)}/>}
            <br/>
         </EstateBanner>
         <div style={{marginLeft:"25px"}}>
            <WriteUps value={"Name: "+data.company_name} readOnly={true} /> 
            <WriteUps value={"Email: "+data.company_email} readOnly={true} /> 
            <WriteUps value={"Phone: "+data.company_phone} readOnly={true} /> 
            <WriteUps value={"About or Price: "+data.write} readOnly={true}/>
            <WriteUps value={"Solgan or Flag: "+data.market} readOnly={true} />
            <WriteUps value={"Date: "+data.signUpDate} readOnly={true} />
            <WriteUps value={"Disable: "+data.disabled} readOnly={true} />
            <WriteUps value={"Package: "+data.package} readOnly={true} />     
           
         </div>                       
         <ContainR>
            <div style={{margin:"0 auto"}}  onClick={(e) => Open(e,1)}> 
                <ButtonIcon font={"9px"} icon={<RiCloseCircleFill/>}  size={"auto"}  text={"Disable"} h={"30px"} />
            </div>
            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(e,3)}>
                <ButtonIcon font={"9px"}  icon={<RiAddCircleLine/>}  size={"auto"}  text={"Add Terminal"} h={"30px"}/>
            </div>
            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(e,2)}>
                <ButtonIcon font={"9px"}  icon={<RiDeleteBack2Fill/>}  size={"auto"}  text={"Delete"} h={"30px"}/>
            </div>
            <div  style={{margin:"0 auto"}}  onClick={(e) => AddGift()}>
                <ButtonIcon font={"9px"}  icon={<RiGift2Line/>}  size={"auto"}  text={"+ Gift card"} h={"30px"}/>
            </div>
         </ContainR>

         {showModel === "open" ? <Tmodel v={data} i={index} fun={Open} /> : null}
    </div>
  )
}

export default EditTab