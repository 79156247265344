import styled from "styled-components";


export const Container01 = styled.div`
width:100%;
height:100%;
img{
width:100%;
height:100%;
object-fit:contain;
}
`;



export const Box = styled.div`
width: ${(props) => props.w}; 
margin:${(props) => props.ui_call === 'U' ? "10px" : "none"};
flex-flow:${(props) => props.t};   
justify-content:${(props) => props.ui_call === 'U' ? "center" : "none"};
align-items:${(props) => props.ui_call === 'U' ? "center" : "none"};
text-align:${(props) => props.ui_call === 'U' ? "center" : "none"};
height:70%;
display:flex;
:hover{
transform: scale(1.05);
transition: transform 500ms;
z-index: 1;
}
img{
width:50%;
height:100px;
object-fit:contain;
}
p{
margin:10px;
font-family: "Poppins", sans-serif;
}
button{
background:#13B6FF;
color:#fff;
border:none;
padding:10px;
cursor:pointer;
border-radius:10px;
font-family: "Poppins", sans-serif;
:hover{
background:#2D3476;
}
}
@media(max-width:900px){
width:${(props) => props.ui_call === 'U' ? "auto" : props.w}; 
img{
width:100%;
height:100px;
}
}
`;


export const Body = styled.div`
background-image:url(${(props => props.bck_img)});
background-color: ${(props) => props.c};
height: ${(props) => props.h}; 
width:${(props) => props.w}; 
background-repeat:no-repeat;
background-size: contain;
@media(max-width:1100px){ 
z-index: ${(props) => props.z ? props.z : ''};
height: ${(props) => props.mh};  
background-color: ${(props) => props.b ? props.b : ''};
}
`;




export const Btn = styled.div`
background:#2D3476;
border-radius:5px;
color:#ffffff;
padding:10px;
display:flex;
max-height: ${(props => props.h ? props.h : "auto")};
min-height: ${(props => props.h ? props.h : "auto")};
min-width:${(props => props.size)};
max-width:${(props) => props.size};
justify-content:center;
align-items:center;
text-align:center;
margin-right:10px;
margin-top:10px;
font-size:${((props) => props.font)};
cursor:pointer;
:hover{
background:#13B6FF;
}
`;





export const Contain = styled.div`
width:${(props) => props.cal_ui === 1 ? "100%" : "80%"};
height:100%;
display:flex;
flex-flow:row;
margin-top:130px;
margin-bottom:40px;
justify-content:center;
h5{
font-weight:900;
font-size:40pt;
text-align:center;
width:70%;
margin-top:50px;
color:#2D3476;
font-family: "Poppins", sans-serif;
}
@media(max-width:768px){
align-items:center;
height:auto;
margin-top:0px;
h5{
font-size:15px;
margin-top:50px;
padding-bottom:30px;
}
flex-wrap: wrap;
flex-flow:${(props) => props.cal_ui === 1 ? "row" : "none"};
flex-wrap:${(props) => props.cal_ui === 1 ? "wrap" : "no-wrap"};
}
`;



export const Card = styled.div`
width:30%;
height:350px;
border-radius:10px;
background:white;
display:flex;
flex-flow:column;
margin:12px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
@media(max-width:900px){
width:100%;
}
`;


export const Pics = styled.div`
width:100%;
height:45%;
display:flex;
z-index:9999;
img{
margin-top:90px;
margin-right:20px;
border:50%;
width:120px;
height:120px;
margin-left:auto;
border-radius:100%;
border: 4px solid #2D3476;
object-fit:contain;
}
`;

export const Write = styled.div`
width:100%;
height:50%;
background:#fc8c03;
clip-path: ellipse(138% 100% at 7.76% 100%);
border-radius:0px 0px 10px 10px;
table{
margin-left:20px;
margin-top:25px;
}
`;

export const Write1 = styled.div`
width:100%;
height:100%;
background:#2D3476;
clip-path: ellipse(138% 100% at 7.76% 100%);
border-radius:0px 0px 10px 10px;
table{
padding-top:45px;
color:#ffffff;
font-size:20px;
}
`;


export const Contains = styled.div`
display:flex;
height:240px;
flex-flow:row;
margin-top:100px;
margin-bottom:100px;
justify-content:space-between;
div{
width:40%;
div{
width:100%;
height:30px;
background:#2D3476;
}
img{
width:100%;
height:215px;
object-fit:cover;
}
box-shadow:rgba(0, 0, 0, 0.1)
0px 20px 25px -5px, 
rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
table{
font-size:12px;
width:55%;
display:flex;
flex-flow:column;
justify-content:center;
align-items:left;
ul li{
margin-left:10px;
font-size:15px;
}
}


@media(max-width:900px){
flex-flow:column;
margin-top:50px;
div{
width:95%;
}
table{
width:auto;
padding:15px;
}
}
`;





export const ContainR = styled.div`
width:90%;
height:100vh;
display:flex;
flex-flow:row;
margin: 0 auto;
justify-content:space-between;
@media(max-width:900px){
text-align:center;
}

`;



export const ContainC = styled.div`
display:flex;
flex-flow:column;
margin: 0 auto;
height:100vh;
width:90%;
@media(max-width:900px){
width:90%; 
}
`;

export const Space = styled.div`
width:100%;
height:30vh;
background:#f5f5f5;
@media(max-width:980px){
height:10vh;
}
`;

export const Acounts = styled.div`
display:flex;
width:100%;
justify-content: center;
align-items: center;
div{
margin-top:30px;
margin-left:10px;
color:#2D3476;
font-size:10pt;
}
`;



export const PackTogether = styled.div`
width:80%;
height:100vh;
display: flex;
margin:0 auto;
justify-content:center;
padding-top:120px;
flex-wrap:wrap;
@media(max-width:768px){
width:100%;
padding-top:130px;
}
`;



export const PanelCards = styled.div`
width:90%;
height:auto;
margin:10px;
padding:10px;
display:flex;
z-Index:98;
font-size:10px;
color:#2D3476;
border-radius:5px;
background-color:#ffffff;
tr td{
display: flex;
text-align:left;
align-items:left;
}
table{
width:100%;
}

box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
@media(max-width:768px){
width:95%;
height:auto;
}
`;



export const LeftTabs = styled.div`
width:60%;
height:90vh;
overflow:scroll;
@media(max-width:768px){
width:100%;
height:400px;
}::-webkit-scrollbar {
display: none;
}
`;

export const RightTab = styled.div`
width:40%;
height:${props => props.h};
display: flex;
align-items:center;
display:flex;
justify-content:center;
flex-flow:column;
padding-top:${props => props.mt};
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
@media(max-width:768px){
width:100%;
height:${props => props.mh};
margin-top:${props => props.mtm};
}
`;




export const EstateBanner = styled.div`
height:40vh;
width:100%;
display:flex;
flex-flow:column;
align-items:center;
justify-content:center;
margin-top:${(props)=> props.mt};
label{
background:#f5f5f5;
padding:10px;
}
img{
width:100%;
height:80%;
object-fit:cover;
}

input{
border:none;
border-radius:15px;
width:90%;
margin:5px;
padding:10px;
}

@media(max-width:800px){
height:30%;
margin-top:0px;
}
`;


export const Texts = styled.div`
padding:5px;
width:auto;
color:#2D3476;
border-radius:5px;
margin:5px;
font-size:12px;
`;

export const WriteUps = styled.input`
padding:10px;
width:70%;
border:1px solid #f5f5f5;
border-radius:5px;
margin:10px;
`;



export const WriteUpsText = styled.textarea`
padding:10px;
width:70%;
height:200px;
border:1px solid #f5f5f5;
border-radius:5px;
margin:10px;
`;



export const EditButton = styled.div`
margin-left:auto;
height:50px;
width:50px;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
`;




export const CenterElement = styled.div`
margin: 0 auto;
display:flex;
flex-flow:column;
align-items:center;
text-align:center;
color: rgba(0,0,0,0.6);
h5{
width:80%;
margin-bottom:30px;
}
@media(max-width:768px){
h5{
width:50%;
font-size:15px;
margin-top:20px;
}
}
`;



//------Center2 is using layout---------------//
export const Center = styled.div`
width:100%;
height:100vh;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
`;


export const Package = styled.div`
width:35%;
height:80%;
margin:20px;
display:flex;
flex-flow:column;
background:white;
align-items:center;
padding-bottom:20px;
border-radius:10px;

img{
width:100%;
object-fit:cover;
border-radius:10px 10px 0px 0px;
}
ul{
margin-top:40px;
font-size:10pt;
}
&:hover{
transform: scale(1.05);
transition: transform 500ms;
z-index: 1;
}
@media(max-width:900px){
width:90%; 
}
`;



export const  BeepAdmminAlignContainer = styled.div`
width:80%;
display:flex;
margin: 0 auto;
padding-top:130px;
@media(max-width:900px){
width:100%;
}
;`



export const ContainList = styled.div`
width:80%;
height:auto;
display:flex;
margin-left:auto;
margin-right:auto;
margin-top:200px;
@media(max-width:768px){
flex-wrap:wrap;
margin-top:70px;
width:100%;
}
`;



export const ScrollTab = styled.div`
width:80%;
height:105%;
margin-top:15px;
@media(max-width:768px){
width:100%;
height:35vh;
}
`;



export const  AlignRight = styled.div`
margin-left:auto;
margin-right:${props => props.al === 1 ? "auto" : "none"};
;`



export const Container = styled.div`
width: 100%;
height:100vh;
display:flex;
background:#f5f5f5;
justify-content:center;
@media(max-width:768px){
height:110vh;
}
`;


export const Boxed = styled.div`
margin:0 auto;
width:80%;
height:91%;
display:flex;
margin-top:20px;
background:#ffffff;
border-radius:10px;
@media(max-width:768px){
height:75%;
margin-top:25%;
padding-bottom:120px;
border-radius:10px;
}
@media(max-width:1000px){
width:90%;
}
`;

export const Input = styled.div`
width:50%;
height:100%;
padding-bottom:50px;
@media(max-width:768px){
width:100%;
}
`;

export const Banner = styled.div`
width:50%;
margin-left:auto;
border-radius:0px 10px 10px 0px;
div{
img{
height:90%;
width:100%;
border-radius:0px 10px 10px 0px;
}
}
@media(max-width:768px){
display:none;
}
`;

