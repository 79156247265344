import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Body} from '../Reuse_ui/Css';
import StaffHeader from './StaffHeader';
import OnbardUsers from './OnbardUsers';
import { Button } from 'react-bootstrap';
import { Get_session, Message } from '../Reuse_ui/Logic';
import {Convert, ListBanks, Payinit, PullData} from '../actions_api';
import { Oval } from 'react-loader-spinner';
import RegisterUsersNode from '../Model/RegisterUsersNode';

const  Convart = () => {
  
 let {e} = useParams();
 const [points, setpoints] = useState("0.0")
 const [amount, setAmount] = useState(null)
 const [account, setAccount] = useState(null)
 const [bankName, setBankName] = useState(null)
 const [options, setOptions] = useState([]);
 const [flag, setFlag] = useState(false);
 




 const MapToList = async () => {

    let ls = await PullData(sessionStorage);
     if(ls){
      setOptions(ls);
     }
    
 }



 const Converter = async () =>{
  CallBanks();
     let page = await Convert(sessionStorage);
      setpoints(page);
 }
 




 const CallBanks = async (e) => {
  let wait = await ListBanks();
  if(wait)
     setOptions(wait);
}




 const handleChange = (e) => {
       setBankName(e.target.value);
 }
 


 const PayInit = async () =>{
  if(bankName && account && amount){
          setFlag(true);
          let page = await Payinit([amount,account,bankName,sessionStorage]);
            if(page){
              setFlag(false);
              setAccount(null);
              setAmount(null);
              setBankName(null);
              Message('Info',page,'info');
            }
          }else
             Message('Info',"Pls fill out fields and select Bank",'warning');
}





 useEffect(() => {
   e === "0" ?  MapToList()  : Converter();
 },[e])





  return (
    <Container>
        <StaffHeader/>
            <Body w={'100%'} h={'auto'}  mh={"auto"} style={{background:"#f5f5f5"}}>
              <OnbardUsers/>
                  {e === "0" 
                     ?
                      <ListUsers>
                          {options.map((v,i) => <RegisterUsersNode v={v} i={i} />)}
                      </ListUsers>
                    :
                     <Card>
                          <span>Total Points: N{new Intl.NumberFormat().format(points)}</span>
                          <br/>
                          <input placeholder='Amount' type='number' value={amount}  onChange={(e) => setAmount(e.target.value)}/>
                          <br/>
                          <input placeholder='Acct no' type='number'  value={account}  onChange={(e) => setAccount(e.target.value)}/>
                          <br/>
                          <select onChange={handleChange}>
                              {options.map((option) => (
                                <option key={option.name} value={option.name}>{option.name}</option>
                              ))}
                          </select>
                          <br/>
                         {flag ? <Oval width={20}  height={30}  color="#000000" /> : <Button onClick={(e) => PayInit()}>Redeem</Button>  }
                     </Card>
                    }
              </Body>        
      </Container>
  )
}



const ListUsers = styled.div`
width:50%; 
height:100vh; 
margin:0 auto;
margin-top:120px;

`;



const Card = styled.div`
width:50%; 
height:auto; 
margin:0 auto;
display:flex;
flex-flow:column;
justify-content:center;
align-items:center;
text-align:center;
background:#ffffff; 
border-radius:10px; 
margin-top:100px;
padding-bottom:50px;
padding-top:20px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

input{
width:50%; 
border: 3px solid #f5f5f5;
border-radius:5px;
padding:10px;
}


span{
font-weight:900;  
}

select{
width:50%;
}
`;








export default Convart