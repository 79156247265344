import React from 'react'
import {PanelCards,EditButton} from '../Reuse_ui/Css'
import {RiAccountCircleFill,RiScan2Line, RiGpsFill,RiListCheck2,RiGroupLine} from 'react-icons/ri'
import { getCookie } from '../Reuse_ui/Logic'

const Marchant = ({v,i,open,icon}) => {
  return (
    
        v === null || v === undefined ? null  :
        <PanelCards key={i}>
                <table>

                    <tr> 
                    <td>
                     <RiGroupLine/>  &nbsp; {v.uid ? v.uid : v.name}
                    </td>
                    </tr>
                 
                    <tr>
                    <td>
                      {<><RiGpsFill/> &nbsp; {v.email} </>}
                    </td>
                    </tr>


                    <tr>
                    <td>
                      {<> +{v.count} </>}
                    </td>
                    </tr>

           </table>
            <EditButton>
  
                <div onClick={(e) => open(v,0)}>
                   <RiAccountCircleFill size={20}/>
                </div>

                &nbsp;
                &nbsp;

                <div onClick={(e) => open(v,1)}>
                   <RiListCheck2 size={20}/>
                </div>
              

            </EditButton>
        </PanelCards>
  )
}

export default Marchant