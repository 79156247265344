import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {Oval} from 'react-loader-spinner'
import { ContainC, ContainR, WriteUps ,CenterElement, Center} from '../Reuse_ui/Css';
import {AddNewMemebr, Format, ImageCall, ListScansByDate, PullScansAndInvite, QueryInvitesScansByDate, Replace, ToCaps, addSingleNode, disabeUser} from '../actions_api'
import Button from 'react-bootstrap/Button';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { RiSortAsc } from 'react-icons/ri';
import { DateHeader, Scroll } from './UserModel';
import InScmodel from './InScmodel';
import { Message } from '../Reuse_ui/Logic';
import ValidateScans from './ValidateScans';


const ScanModel = (props) => {



    const [progress, setprogress] = useState("");
    const [phoneEmail ,setPhoneEmail] = useState("");
    const [singleName,setSingleName] = useState("");
    const [scivList, setScIvList] = useState([]);
    const [screenSize, setScreenSize] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [shwoDate, setShowDate] = useState(false);
    const [shwoDateIcon, setShowDateIcon] = useState(false);
    const [currrentSelectedated, setCurrrentSelectedated] = useState(new Date().toLocaleDateString());





        
  const QueryDate = async (date) => {

    setShowDate(false);
      let dt =  Format(date);
       setCurrrentSelectedated(dt);
        console.log(dt);
         let res = await QueryInvitesScansByDate(props.list,dt,sessionStorage,0);
          if(res){
           setShowDateIcon(true);
            setScIvList(res.data.message);
         }
 } 
  



         
 const QueryLoyalPoint = async (r,i) => {
  setShowDate(false);
   let result = i === 0 ? r : Format(r);
     setCurrrentSelectedated(result);
       let res = await ListScansByDate(props.list,result,sessionStorage,0);
        if(res){
          console.log(res.data.message);
           setShowDateIcon(true);
            setScIvList(res.data.message);
       }
} 





 const ScansAndInvite = async () => {
   let res = await PullScansAndInvite(props.list,0,sessionStorage);
    if(!res.message.includes("Account not found !"))
        setScIvList(res.message);
    else 
        Message("Info",res.message[0],"error")
}





  useEffect(() => {
    props.ui === 0 ? ScansAndInvite() : QueryLoyalPoint(new Date().toISOString().slice(0,10),0)
    console.log(`TYPE  ${props.typ}`);
  },[])
  

    return(<Container>
                 <Content>
                    <Header>
                      <h2>{props.ui === 0 ? "List Scans" : "Validate loyalty Point"}</h2>
                      <Button  onClick={(e) => props.fun(e,1)}>X</Button>
                     </Header>
                      {
                        props.list !== null ?
                         <CenterElement>
                              <DateHeader><div>{currrentSelectedated} {shwoDateIcon ? <RiSortAsc/> : "" }</div>
                                 <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} onClick={(e) => setShowDate(true)} >Select date</Button>
                               </DateHeader>
                              {shwoDate ? <ReactDatePicker  selected={startDate} onChange={(date) => props.ui === 0 ? QueryDate(date) : QueryLoyalPoint(date,1)} /> : ""}
                             <Scroll>
                                {scivList.map((v,i) =>  props.typ !== ToCaps(process.env.REACT_APP_REWARD_NODES) || props.typ !== ToCaps(process.env.REACT_APP_CARPARK_NODES) ? <ValidateScans i={props.list} v={v} k={i} /> :  <InScmodel v={v} i={i} ui={2} key={i}/> )}
                             </Scroll>
                          </CenterElement>
                          :
                        <Center>
                         <Oval color={"#2D3476"}  height={40} width={40}/>
                        </Center> 
                    }
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:1009;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
margin: 0 auto;
padding-bottom:20px;
top:32px;
h2{
font-size:12px;
}


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const SharedContent = styled.div`
display:flex;
flex-direction: column;
flex-grow:1;
overflow-y:auto;
vertical-align: baseline;
background: transparent;
padding: 8px 12px;

::-webkit-scrollbar {
display: none;
}
`;



const UserInfo = styled.div`
display: flex;
align-items: center;
flex-flow:column;
padding: 12px 24px;
svg,img{
width:48px;
height:48px;
background-clip: content-box;
border:2px solid  transparent;
border-radius:50%;
padding:5px;
}
span{
font-weight:600;
font-size:12px;
}
`;



const ShareCreation = styled.div`
display:flex;
justify-content: space-between;
padding: 10px;
`;






const PostButton = styled.button`
min-width:60px;
border-radius: 20px;
height: 35px;
right:2px;
display:flex;
justify-content:center;
align-items:center;
background: ${(props) => (props.disabled ?  "rgba(0,0,0,0.8)" : "#0a66c2")};
color: ${(props) => (props.disabled ? "rgba(1,1,1,0.2)": "white")};
&:hover{
background: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#004162")};
}
`;


const AGORA_SECTION = styled.div`
#me{
position: relative;
width: 50%;
margin: 0 auto;

}
#me video{
position: relative !important;
}
#remote-container{
display: flex;
}
#remote-container video{
height: 200px;
position: relative !important;
}

`;





const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({
    PostData: (payload) => dispatch(AddNewMemebr(payload)),
});

export default  connect(mapStateToProps,mapDistpachToProps)(ScanModel);




    