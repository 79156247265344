import React, { useEffect,useState} from 'react'
import { Ri24HoursFill, RiBankCardFill, RiGpsFill, RiMap2Line, RiPhoneFill, RiRestaurant2Fill } from 'react-icons/ri'
import styled from 'styled-components'
import { Body,Space } from '../Reuse_ui/Css'
import {Container,CardInfo,Meta,TopUps} from '../Hover/Css'
import { Button, ButtonIcon,ButtonWidth} from '../Reuse_ui/Widget'
import Footer from '../Components/Footer'
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';

const BeePoint_card = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleCheck() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
    },[])

  return <>
          <Body w={'100%'} h={'auto'}  mh={"auto"}  style={{background:"#f5f5f5"}}>
            <Space/>
                <Container>
                    <CardInfo>
                        
                        <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/tjbsipxphnqpfbfjajal')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
             
                        <Meta w={'90%'}>
                            <div>
                                <h1>BeePPoint multiverse card</h1>
                                Welcome to the limitless world of BeePPoint! BeePPoint card makes your 
                                payment and all forms of transactions hustle-free and instant. Beeping is fun!  
                            </div>
                            <ButtonIcon size={width <=768 ? '90%' : '50%'}  icon={<RiMap2Line/>}  text={'How to get beeppoint card?'} />
                        </Meta>
                    </CardInfo>
                    <TopUps>
                    {width > 768 ? "" :  <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/cardUse_j6hzun')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>}
                    <Meta w={'40%'}>
                            <div>
                                <h1>Resturants events and movies</h1>
                                As a BeePPoint Card user, you get to enjoy 
                                discounts on purchases from your favourite local restaurants.
                                What is life without a moment of fun? The BeePPoint card will also give you 
                                access to the most exquisite events and movie showcases in town at an unbeatable discount.  
                            </div>
                            <br/>
                            <br/>
                            <ButtonIcon size={width <=768 ? '90%' : '50%'}  icon={<RiGpsFill/>}  text={'View Resturants'} />
                        </Meta>  
                        {width <= 768 ? "" :  <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/cardUse_j6hzun')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>}
                    </TopUps>

                    <TopUps>
                    <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/shoppingPoint_eyppyz')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                    <Meta w={'200px'}>
                            <div>
                                <h1>Shopping and Voucher</h1>
                                    A BeePPoint card at your disposal gives you the power to shop whenever, 
                                    wherever and whatever caught your fancy and helps to save 
                                    some money as you get free discount vouchers and coupons.  
                            </div>
                            <br/><br/>
                            <ButtonIcon size={width <=768 ? '90%' : '50%'}  icon={<RiBankCardFill/>}   text={'Shopping and voches'} />
                        </Meta>  
                    </TopUps>
                </Container>
            </Body>
            <Footer/>
        </>
}



export default BeePoint_card