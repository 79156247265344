import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../Reuse_ui/Widget';
import {connect} from 'react-redux';
import { beepoint_staff_Login } from '../actions_api';
import {Acounts} from '../Reuse_ui/Css'
import { useNavigate } from 'react-router-dom';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'
import {Btn} from '../Reuse_ui/Css';
import {Oval} from 'react-loader-spinner'
import { Get_session } from '../Reuse_ui/Logic';



const StaffLogin = (props) => {

  const [email, setEmail] = useState("");
  const [pass, setpass] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();


  return (
      <Container>
       <Boxed>
          <Input>
            <Type>{Get_session("dm",sessionStorage) !== null || Get_session("dm",sessionStorage) !== undefined ?  "Welcome Back" : "Staff Login"} </Type>
            <USERDATA>
              <input onChange={(e) => setEmail(e.target.value)} value={email} placeholder='Email' type="email"/>
              <input onChange={(e) => setpass(e.target.value)}  value={pass} placeholder='Password' type="password"/>
                 <br/>
               <div onClick={(e) =>{ beepoint_staff_Login(email,pass,navigate); setShow(true);}}>
                 {show ? <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn> : <ButtonIcon   text={'Login'}  icon={null}  size={"100%"}/>}
               </div>
              </USERDATA>
            </Input>
       </Boxed>
     </Container>
  )
}



const Container = styled.div`
width: 100%;
height:100vh;
display:flex;
background:#f5f5f5;
justify-content:center;
@media(max-width:768px){
height:110vh;
}
`;


const Boxed = styled.div`
margin:0 auto;
width:50%;
height:80%;
display:flex;
margin-top:50px;
background:#ffffff;
border-radius:10px;
@media(max-width:1000px){
width:90%;
}
@media(max-width:768px){
height:50%;
margin-top:25%;
padding-bottom:120px;
border-radius:10px;
}
`;

const Input = styled.div`
width:100%;
height:100%;
@media(max-width:768px){
width:100%;
}
`;




const Type = styled.div`
width:100%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:5px;
padding-left:10px;
text-align:center;
border-radius:10px 10px 0px 0px;
@media(max-width:768px){
font-size:12px;
padding-bottom:10px;
padding-left:0px;
border-radius:5px 5px 0px 0px;
display:flex;
justify-content:center;
align-items:center;
}
`;

const TopLayer = styled.div`
width:100%;
height:auto;
display:flex;
background:#4B4AB3;
justify-content:space-between;
`;


const H5 = styled.div`
padding:10px;
color:#ffffff;
background:"#4B4AB3";
border-radius:5px;
margin:10px;
:hover {
background-color: #FF9900;
}
@media(max-width:768px){
margin:5px;
font-size:10px;
padding:5px;
}
`;


const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:20%;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
`;



const MiniLogin = styled.button`
display: flex;
justify-content: center;
background-color: #fff;
align-items: center;
height: 50px;
width: auto
z-index: 0;
margin-top:30px;
margin-left:10px;
color:#2D3476;
cursor:pointer;
font-size: 12px;
padding-left:20px;
padding-right:20px;
border-radius: 28px;
vertical-align:middle;
transition-duration: 167ms;
border: 1px solid #f5f5f5;
&:hover{
background-color: rgba(207,207, 207, 0.25);
color: rgba(0, 0, 0, 0.75);
}
@media(max-width:900px){
font-size:8pt;
}
`;


const mapStateToProps = (state) => {
  return {user:state.userState.user,};
};

const mapDispatchToProps = (dispatch) => ({});



export default connect(mapStateToProps,mapDispatchToProps)(StaffLogin)