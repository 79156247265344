import React, { useEffect, useState } from 'react'
import {Message,Add_session} from '../Reuse_ui/Logic'
import { ButtonIcon } from '../Reuse_ui/Widget';
import styled from 'styled-components';
import {Boxed,Input,Banner} from '../Reuse_ui/Css';
import {RiFacebookCircleFill, RiGoogleFill} from 'react-icons/ri'

const SignUp = (props) => {

const [tabs, setTabs] = useState([process.env.REACT_APP_CARPARK_NODES,process.env.REACT_APP_ESTATE_NODES,process.env.REACT_APP_REWARD_NODES,process.env.REACT_APP_SCHOOL_NODES]);
const [btn, setBtn] = useState(9);
const [serviceChoose, setServiceChoose] = useState(false);
const [companymail, setcompanymail] = useState("");
const [companyphone, setcompanyphone] = useState("");
const [companyname, setcompanyname] = useState("");
const [companypass, setpass] = useState("");
const [fillOut, setFillOut] = useState([]);
const active = {border:"1px solid #f5f5f5",background:"#13B6FF"}
const inactive = {}
const list = [];

const Check = () => {
  list.push(companymail);
  list.push(companyphone);
  list.push(companyname);
  list.push(companypass);
  
  list.push(btn == 0 ? process.env.REACT_APP_CARPARK_NODES :
           btn === 1 ? process.env.REACT_APP_ESTATE_NODES : 
           btn === 2 ? process.env.REACT_APP_REWARD_NODES : 
           btn === 3 ? process.env.REACT_APP_SCHOOL_NODES : 
           process.env.REACT_APP_CARPARK_NODES);

    Add_session(process.env.REACT_APP_SESSION_NODES,sessionStorage,list)

   if(btn == 0 | btn == 2 && serviceChoose === true){
      if(Validate()){
           Add_session(process.env.REACT_APP_SESSION_NODES2,sessionStorage,['open'])
            return window.location.href = "/adminpricing"
       }else  {
         Add_session(process.env.REACT_APP_SESSION_NODES2,sessionStorage,['open'])
           Message("SignUp Error","Pls fill out all fields","warning")
          }
    }else
       if(Validate() && serviceChoose === true)
           return window.location.href = "/csv"
        else
            Message('Form validation', 'Pls fill out all fields and select service','warning');
  }
  

function Validate(){
    return companyname.trim().length > 0 && companymail.trim().length > 0 && companyphone.trim().length > 0 && companypass.trim().length > 0;
  }



  useEffect(() => {
    let res = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_NODES));
    if(res){
      setcompanymail(res[0]);
      setcompanyphone(res[1]);
      setcompanyname(res[2]);
      setServiceChoose(false);
    }
  },[]);

  

  return (
    <Container>
        <Boxed>
            <Input>
              <Type><div>SELECT SERVICE</div></Type>
              <TopLayer>
                {tabs.map((v,i) => <H5  key={i} style={btn == i ? active : inactive}  onClick={(e) =>{setServiceChoose(true); setBtn(i)}}>{v}</H5> )}
              </TopLayer>
              <USERDATA>
                <input value={companyname} onChange={(e) => setcompanyname(e.target.value)} placeholder='Company name'/>
                <input value={companymail} onChange={(e) => setcompanymail(e.target.value)}  placeholder='Company Email'/>
                <input value={companyphone} onChange={(e) => setcompanyphone(e.target.value)}  placeholder='Company Phone no' type="number"/>
                <input value={companypass} onChange={(e) => setpass(e.target.value)}  placeholder='Password' type="password"/>
                   <br/>
                  <div onClick={(e) => Check()}>
                     <ButtonIcon  text={'Send'}  icon={null}  size={"90%"} />
                  </div>
              </USERDATA>
            </Input>
            <Banner>
              <div>
                <img src={
                  btn === 0 ?
                  "./assets/carpark.gif" 
                  :
                  btn === 1 ?
                  "./assets/estate.gif"
                  :
                  btn === 2 ?
                  "./assets/shop.gif"
                  :
                  btn === 3 ?
                  "./assets/Education.gif"
                  :
                  "./assets/estate.gif"
                  }
                />
              </div>
            </Banner>
        </Boxed>
    </Container>
  )
}

const Container = styled.div`
width: 100%;
height:auto;
background:#f5f5f5;
display:flex;
justify-content:center;
margin-bottom:200px;
@media(max-width:768px){
height:100vh;
}
`;




export const Type = styled.div`
width:100%;
height:auto;
color:#ffffff;
background:#4B4AB3;
padding-top:10px;
padding-bottom:10px;
display:flex;
border-radius: 10px 0px 0px 0px;
div{
margin:5px;
}
justify-content:space-between;
@media(max-width:768px){
width:100%;
font-size:12px;
padding-bottom:-2px;
border-radius:5px 5px 0px 0px;
}

`;

export const TopLayer = styled.div`
width:100%;
height:60px;
display:flex;
background:#4B4AB3;
justify-content:space-between;
`;


export const H5 = styled.div`
padding:2px;
color:#ffffff;
border-radius:5px;
height:auto;
margin:10px;
font-size:12px;
display:flex;
align-items:center;
text-align:center;
:hover {
border:1px solid #f5f5f5;
background:#13B6FF;
}
@media(max-width:1200px){
height:25px;
}
@media(max-width:768px){
font-size:10px;
height:30px;
padding:5px;
margin:5px;
}
`;


const USERDATA = styled.div`
width:80%;
display:flex;
flex-flow:column;
margin: 0 auto;
justify-content:center;
align-items:center;
margin-top:50px;
input{
border: 0.5px solid #f5f5f5;
padding:15px;
margin:15px;
border-radius:5px;
width:90%;
}
`;





export default SignUp