import styled from 'styled-components'
export const Container = styled.div`
width:100%;
height:auto;
display:flex;
flex-flow:column;
margin-left:auto;
margin-right:auto;
justify-content:center;
font-family: "Poppins", sans-serif;
@media(max-width:900px){
width:100%;
margin-bottom:100px;
overflow:hidden;
}
`;


export const Meta =styled.div`
width:65%;
height:auto;
display:flex;
flex-flow:column;
margin-left:auto;
div{
height:auto;
width:${(props) => props.w};
margin-left:45px;
h1{
font-size:25pt;
font-weight:900;
padding-top:20px;
padding-bottom:20px;
}
ul{
margin-left:10px;
}
}
@media(max-width:900px){
width:100%;
margin-left:5px;
div{
width:90%;
margin-left:2px;
h1{
font-size:15pt;
}
}
}
`;


export const CardInfo = styled.div`
display:flex;
flex-flow:row;
height:auto;
justify-content:center;
width:70%;
margin:0 auto;
img{
width:30%;
height:100%;
margin-right:10px;
padding-top:20px
}
@media(max-width:900px){
flex-flow:column;
width:90%;
img{
width:100%;
}
}
`;



export const TopUps = styled.div`
width:80%;
height:60vh;
display:flex;
flex-flow:row;
margin-top:150px;
margin-left:auto;
margin-right:auto;
justify-content:space-between;
div{
height:auto;
width:70%;
}
img{
height:90%;
}
@media(max-width:900px){
flex-flow:column;
height:auto;
margin-top:100px;
div{
width:100%;
}
img{
width:100%;
}
}
`;



export const TabsContain = styled.div`
width: 65%;
height: auto;
margin: 0 auto;
padding-top: 40px;
padding-bottom: 10px;

.intro_question_faq{
width: 80%;
height:auto;
color: #f5f5f5;
margin: 5px;
cursor: pointer;
padding: 5px;
display: inline-block;
border: 1px solid #eaeaea;
border-radius: 4px;
text-align:left;
ul li{
margin-left:20px;
text-align:left;
}
li{
margin: 10px 0;
}
}

.view_drop{
color: #2D3476;
margin-right: 12px;
float: right;
margin-top:-30px;
}

.faq_view{
color: #2D3476;
text-align:center;
height:50px;
}

@media(max-width:768px){
width: 100%;

.intro_question_faq{
width: 90%;
height:auto;
padding-top: 5px;
}

.faq_view{
font-size:12px;
}

.view_drop{
margin-top:-40px;
}
}
`;
