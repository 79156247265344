import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Body} from '../Reuse_ui/Css'
import Header from '../Components/Header'
import GoogleMapReact from 'google-map-react'
import { GetLocation, GetUserGeo } from '../actions_api'
import { checkServiceMarker } from '../Reuse_ui/Logic'
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from 'mdb-react-ui-kit'

const BusinesMap = () => {


  const [mark, setMark] = useState([]);
  const [userGeo, setUserGeo] = useState({latitude:null,longitude:null});

  useEffect(() => {
    GetLocal();
  },[]);


  const GetLocal = async ()   => {
     let res = await GetLocation();
       let geo = await GetUserGeo();
       if(geo && res){
        setMark(res.data.message);
        setUserGeo({latitude:geo.coords.latitude,longitude:geo.coords.longitude});
       }
    
  }

    
  const defaultProps = {
    center: {    
      lat: userGeo.latitude,
      lng: userGeo.longitude
    },
    zoom: 12
  };

  return (
    <Body w={'100%'} h={'100vh'}  style={{background:"#f5f5f5"}}>
          <Header/>
            <GoogleMap>
                <Head>Beeppoint merchants on Google Map</Head>
                  <Map>
                    {userGeo.latitude !== null && userGeo.longitude !== null ?
                        <GoogleMapReact
                            bootstrapURLKeys={{key:process.env.REACT_APP_URL}}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}>
                            {mark.map((v,i) => 
                              <Marker 
                                key={v.geoPoint}
                                  lat={v.geoPoint.latitude}
                                  lng={v.geoPoint.longitude}>
                                      <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",width:"100px"}}>
                                        <img alt={v.name} src={checkServiceMarker(v.type)}/>
                                        <MDBPopover color="#00FFFFFF" style={{marginLeft:"-38px"}} placement='top'  dismiss>
                                          <MDBPopoverHeader>{v.name}</MDBPopoverHeader>
                                          <MDBPopoverBody>{v.type}</MDBPopoverBody>
                                        </MDBPopover>
                                      </div>
                              </Marker>
                            )}
                        </GoogleMapReact>
                        :
                        <div id='spin'>Loading Google Map...</div> 
                    }
                  </Map>
            </GoogleMap>
    </Body>
  )
}




const GoogleMap = styled.div`
width:100%;
height: 100vh;
display:flex;
flex-flow:column;
padding-top:50px;
margin-left:auto;
margin-right:auto;
font-family: "Poppins", sans-serif;
@media(max-width:768px){
width:95%;
padding-top:20px;
padding-left:0px;
padding-right:0px;
}
`;


const Head = styled.div`
width:30%;
margin: 0 auto;
height: 12%;
color:#2D3476;
text-align:center;
padding:10px;
font-weight:900;
font-size:1.2em;
border-radius:0px 0px 10px 10px;
@media(max-width:768px){
width:100%;
height: 14%;
padding:21px;
font-size:1.2em;
}
`;


const Map = styled.div`
width:100%;
height:100vh;
margin-top:15px;
background:#f1f1f1;
display:flex;

#spin{
margin-top:25%;
margin-left:auto;
margin-right:auto;
}
@media(max-width:768px){
height:100vh;
}
`;


const Marker = styled.div`
height:120px; 
width:150px; 
h5{
font-size:9px;
border-radius:5px;
white-space: nowrap;
text-align:center;
background:#2D3476;
color:#ffffff;
padding:15px;
}
img{
height:50%; 
width:50%; 
object-fit:contain;
}
`;

export default BusinesMap