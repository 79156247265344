import React from 'react'
import styled from 'styled-components'
import {Contain} from '../Reuse_ui/Css'
import {Button} from '../Reuse_ui/Widget'
import { useNavigate } from 'react-router-dom';
import { List } from '../actions_api';
import {cld} from '../actions_api'
import { remove_white_space } from '../Reuse_ui/Logic';

const HowTo = () => {


  const navigate = useNavigate();

  const push = (d) => {
       window.scrollTo(0,0);
      navigate(`/${remove_white_space(d)}`);
  }




  return (
    <Container>
        <Contain>
            <h5>Experience unlimited access with your every day life using Beeppoint multivase card </h5>
        </Contain>
        <Contain cal_ui={1}>
           <div  onClick={(e)=> push(List()[1])}>
              <Button  img={cld.image('beeppoint/ASSETS/whereTobuy_prev_ui_aksj1j')} write={"How to get card"} direct={"find out more"} w={'300px'}/>
            </div>
            <div onClick={(e) => push(List()[3])}>
               <Button img={cld.image('beeppoint/ASSETS/locationc_prev_ui_wtsea9')} write={"Online marchants"} direct={"find out more"} w={'300px'}/> 
            </div>
            <div onClick={(e) => push(List()[2])}>
               <Button img={cld.image('beeppoint/ASSETS/cardLoad_prev_ui_oesgke')} write={"Cards use case"} direct={"find out more"} w={'300px'}/>
            </div>
            <div onClick={(e) => window.scrollTo(3500,3500)}>
               <Button img={cld.image('beeppoint/ASSETS/download_gnfhqn')} write={"Get BeePPoint app"} direct={"Download app"} w={'300px'}/>
            </div>
        </Contain>
    </Container>
  )
}

const Container = styled.div`
width:100%;
height:150vh;
display:flex;
flex-flow:column;
justify-content:center;
align-items:center;
@media(max-width:768px){
flex-flow:column;
height:auto;
padding-bottom:20px;
}
`;




export default HowTo