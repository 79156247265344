import React from 'react'
import { RiAppStoreFill, RiFacebookLine, RiGooglePlayFill, RiInstagramLine, RiLinkedinFill, RiTwitterLine, RiYoutubeLine } from 'react-icons/ri'
import styled from 'styled-components'
import { Body } from '../Reuse_ui/Css'
import { ButtonIcon } from '../Reuse_ui/Widget'
import { useNavigate } from 'react-router-dom'

const Footer = () =>{

  const history = useNavigate();

  const push = (url) => {
     window.scrollTo(0,0);
     history(url);
  }
  return (
    <Body w={'100%'} h={'80vh'}  style={{background:"#f5f5f5"}}>
      <Box>
        <Contain>
          <Columns w={'20%'}  h={'26vh'}>
            <TopLayer>
              <img src="../assets/translogo.png" />
            </TopLayer>
          </Columns>


          <Columns w={'20%'} h={'auto'}>
           <TopLayer>
               Company
            </TopLayer>
            <table>
              <tr>
                <td onClick={(e) => push("/beeppoint+card")}>BeePPoint Card</td>
              </tr>
              {/* <tr>
               <td>Board of Directors</td>
              </tr> */}
              <tr>
               <td>Careers</td>
              </tr>
              <tr>
               <td>Blogs</td>
              </tr>
             </table>
           
          </Columns>

          <Columns w={'30%'}  h={'26vh'}>
            <TopLayer>
              Pay with beePPoint Card
            </TopLayer>
             <table>
                <tr>
                  <td>
                    Partnered Program
                  </td>
                </tr>
                <tr onClick={(e) => push("/help+support")}>
                  <td>
                    Send Us a message
                   </td>
                </tr>
              </table>
          </Columns>

          <Columns w={'40%'} h={'auto'}>
             <TopLayer>
                Contact Us
              </TopLayer>
              <table>
                <tr>
                  <td>
                    Lagos Nigeria
                  </td>
                </tr>
                <tr>
                  <td>
                  Phone: +234 903 613 8509
                  </td>
                </tr>
                <tr>
                  <td>
                    Beeppointcompany@gmail.com
                  </td>
                </tr>        
              </table>
          </Columns>

        </Contain>

       <Contain>
         <Columns w={'20%'}  h={'10vh'}/>
          <Columns w={'80%'}  h={'10vh'}>
             <h2 onClick={(e) => push("/Frequently+ask+questions")}>Frequently Asked Question</h2>
          </Columns>
       </Contain>
        
        <Contain>
            <Columns w={'25%'}  h={'20vh'}/>
              <Columns w={'70%'}  h={'20vh'}>
                <h2>Download BeePPoint Apps</h2>
                <AppLinks c={'column'}>
                  <ButtonIcon size={'auto'}  icon={<RiAppStoreFill size={45}/>}  text={'Download from apple store'} />
                  <ButtonIcon size={'auto'}  icon={<RiGooglePlayFill size={45}/>}  text={'Get app from playstore'} />
                </AppLinks>
              </Columns>

              <Columns w={'0%'}  h={'20vh'}/>
              <Columns w={'30%'}  h={'20vh'}>
                  <h2>Follow us on social media</h2>
                  <br/>
                <AppLinks c={'row'}>
                    <Circle>
                      <a href="https://instagram.com/beep_point?igshid=ZDdkNTZiNTM=">
                        <RiInstagramLine/>
                      </a>
                    </Circle>
                    <Circle>
                       <a href="https://www.facebook.com/profile.php?id=100091765410263&mibextid=ZbWKwL">
                         <RiFacebookLine/>
                        </a>
                    </Circle>
                    <Circle>
                        <a href="https://youtube.com/@beep_point">
                          <RiYoutubeLine/>
                        </a>
                    </Circle>
                    <Circle>
                       <a href="https://twitter.com/Beep_point?t=G4uplJEe33gpwSIJ4wsiRA&s=09">
                        <RiTwitterLine/>
                        </a>
                    </Circle>
                    <Circle>
                       <a href="https://www.linkedin.com/company/beeppoint/">
                         <RiLinkedinFill/>
                        </a>
                    </Circle>
                </AppLinks>
              </Columns>
        </Contain>

        <Contain>
            <Columns w={'20%'}  h={'10vh'}/>
              <Columns w={'80%'}  h={'10vh'}>
                <RockBottom>
                  <h5>Privacy Policy</h5> 
                  <h4>Terms and conditions</h4>
                </RockBottom>
              </Columns>
        </Contain>        
      </Box>
    </Body>
  )
}

const Box = styled.div`
display:flex;
width:80%;
margin-left:auto; 
margin-right:auto; 
padding-top:200px;
flex-direction:column;
color:#2D3476;
color:#2D3476;
font-family: "Poppins", sans-serif;
@media(max-width:768px){
padding-top:200px;
}
`;





const Contain = styled.div`
width:80%;
height:100%;
display:flex;
margin: 0 auto; 
flex-direction:row;
@media(max-width:768px){
flex-direction:column;
text-align:center;
}
`;


const Columns = styled.div`
width:${props => props.w};
height:${props => props.h};
table{
font-size:12px;
padding:10px;
font-family: "Poppins", sans-serif;
}
h2,h4{
font-size:18px;
}
@media(max-width:768px){
width:100%;
height:auto;
margin-top:20px;
table{
margin: 0 auto;
}
}
`;

const  TopLayer = styled.div`
width:90%;
height:auto;
margin-left:0px;
font-family: "Poppins", sans-serif;
img{
width:100px;
height:100px;
object-fit:contain;
}
@media(max-width:980px){
width:100%;
}
`;



const AppLinks = styled.div`
width:80%;
display:flex;
flex-flow:row;
justify-content:left;
@media(max-width:768px){
width:100%;
flex-flow:${(props) => props.c};
justify-content:center;
align-items:center;
}
`;


const RockBottom = styled.div`
width:50%;
height:100%;
display:flex;
justify-content:space-between;
font-family: "Poppins", sans-serif;
h4,h5{
font-size:15px;
}
@media(max-width:768px){
width:100%;
margin-bottom:50px;
}
`;




const Circle = styled.div`
background:#2D3476;
height:30px;
width:30px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
color:#fff;
margin:2px;
cursor:pointer;
a{
decoration:none;
color:#fff;  
}
`;

export default Footer