import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import { useNavigate } from 'react-router-dom';
import { RiAddFill, RiCamera2Line, RiCloseLine, RiShareCircleLine, RiAddLine, RiUpload2Fill } from 'react-icons/ri';
import { ContainC, ContainR, WriteUps ,CenterElement, Texts} from '../Reuse_ui/Css';
import { ButtonIcon } from '../Reuse_ui/Widget';
import {AddNewMemebr,disableTerminal} from '../actions_api'
import { Message } from '../Reuse_ui/Logic';
import Button from 'react-bootstrap/Button';
const TerminalDisable = (props) => {

    const [terminalId ,setTerminalID] = useState("");

    useEffect(() => {
        setTerminalID(props.id);
    },[])


    return(<Container>
                 <Content>
                    <Header>
                    <h2>Live feed</h2>
                    <Button  onClick={(e) => props.fun(e)}>X</Button>
                    </Header>
                        <Live>

                        </Live>
                 </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:999;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
top:32px;
margin: 0 auto;
padding-bottom:20px;
@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
margin-bottom:10px;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const Live = styled.div`
display:flex;
width:90%;
height:250px;
background:#000;
margin:10px;
margin:auto;
`;




const AGORA_SECTION = styled.div`
#me{
position: relative;
width: 50%;
margin: 0 auto;

}
#me video{
position: relative !important;
}
#remote-container{
display: flex;
}
#remote-container video{
height: 200px;
position: relative !important;
}

`;





const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({
    PostData: (payload) => dispatch(AddNewMemebr(payload)),
});

export default  connect(mapStateToProps,mapDistpachToProps)(TerminalDisable);


