import React,{useState,useEffect} from 'react'
import styled from "styled-components"
import StaffHeader from "./StaffHeader";
import Footer from "../Components/Footer";
import UserTab from './UserTab'
import PointsModel from '../Model/PointsModel'
import UserSelf from './UserSelf'
import { Oval } from 'react-loader-spinner'
import Onbardmechant from './Onbard'
import OnbardUsers from './OnbardUsers';
import SubListModel from '../Model/SubListModel';
import {ListUsers, PullData, Format} from '../actions_api'
import RegisterUsersNode from '../Model/RegisterUsersNode';
import { Add_session, Get_session, Message, getCookie} from '../Reuse_ui/Logic';
import {Body,Center, ContainList, LeftTabs, RightTab} from '../Reuse_ui/Css'
import { RiAccountCircleFill, RiFundsFill ,RiShieldCheckFill,RiSortAsc } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { CenterDiv } from '../Components/BackGroundFlow';







const Staff = ()  => {


 const [list, setList] = useState([]);  
 const [startDate, setStartDate] = useState(new Date());
 const [shwoDate, setShowDate] = useState(false);
 const [shwoDateIcon, setShowDateIcon] = useState(true);
 const [screenSize, setScreenSize] = useState(0);
 const [currrentSelectedated, setCurrrentSelectedated] = useState(null);
 const history = useNavigate()    






async function CallUser (date) {

        if(!Get_session("staff",sessionStorage))
                history('/StaffLogin')
        else{
                setShowDate(false);
                let dt =  Format(date);
                setCurrrentSelectedated(dt);
                Add_session("time_filter",sessionStorage,[dt]);
                 let open = await PullData(sessionStorage,dt);
                 if(open == null){
                         setList([]);
                        setCurrrentSelectedated(null);
                    Message("Info","No users found.","info")
                 }else
                     setList(open)
                   
          }
    
}





useEffect(() => {
  CallUser(); 
},[])



        return ( 
                <>
                        <StaffHeader/>
                                <Body w={'100%'} h={'auto'}  mh={"auto"} style={{background:"#f5f5f5"}}>
                                 <OnbardUsers/>
                                        {list.length > 0 ?
                        
                                                        <ContainList>
                                                                <LeftTabs>
                                                                        <Btn2>
                                                                                <DateHeader>
                                                                                        <div>{currrentSelectedated}</div>
                                                                                        &nbsp;&nbsp; 
                                                                                        <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} onClick={(e) => setShowDate(true)} >Select date</Button>
                                                                                </DateHeader>
                                                                                {shwoDate ? <ReactDatePicker  selected={startDate} onChange={(date) =>  CallUser(date)} /> : ""}
                                                                        </Btn2>

                                                                  {list.map((v,i) => <RegisterUsersNode v={v} i={i} />)}
                                                                 </LeftTabs>
                        
                                                                <RightCard>

                                                                        {Get_session("staff",sessionStorage) ?
                                                                        <div>
                                                                                <span> <RiAccountCircleFill width={10} height={10} color='#ffffff' /> &nbsp; Account Details</span>
                                                                                <span> <RiFundsFill width={10} height={10} color='#ffffff' /> &nbsp; {Get_session("staff",sessionStorage)[1]}</span>
                                                                                <span> <RiShieldCheckFill   width={10} height={10} color='#ffffff' /> &nbsp;  {Get_session("staff",sessionStorage)[0]}</span>
                                                                        </div>
                                                                        :
                                                                         null}
                                                                </RightCard>
                                                        
                                                        </ContainList>
                                                :
                                                <Center>
                                                        {!currrentSelectedated ?
                                                                
                                                          <Btn>
                                                                <DateHeader>
                                                                       <div id='btn'>
                                                                         <Button style={{fontSize: screenSize > 980 ?  "12px" : "9px"}} onClick={(e) => setShowDate(true)} >Select date</Button>
                                                                       </div>
                                                                        <div id='msg'>Select Date to View data</div>
                                                                </DateHeader>
                                                                 {shwoDate ? <ReactDatePicker  selected={startDate} onChange={(date) =>  CallUser(date)} /> : ""}
                                                           </Btn>

                                                                :
                                                          <Center>
                                                            <Oval color={"#2D3476"}  height={40} width={40}/>
                                                          </Center>
                                                        }
                                                </Center>
                        
                                        }
                        </Body>
                  <Footer/>
              </>

        )
}


const RightCard = styled.div`
width:40%;
height:30vh; 
background:#2D3476; 
border-radius:10px;
margin-top:10px;
opacity: 0.5;
display:flex;
flex-flow:column;
align-items:center;
justify-content:center;

div{
align-items:center;
justify-content:center;
}
span{
color:#ffffff;
margin:5px;
display:flex;
text-align:left;
}
`;




const Btn = styled.div`
height:100vh; 
width:100%;
padding-top:80px;
input{
width:80%;
text-align:center;
}
`;







const Btn2 = styled.div`
height:auto; 
width:100%;
margin-left:auto;
text-align:left;
justify-content:left;
align-items:left;
flex-flow:column;
display:flex;
input{
width:15%;
margin-left:10px;
text-align:center;
}
button{
width:100px;
}
`;


export const DateHeader = styled.div`
width:100%;
height:auto; 
margin:10px;
display:flex; 
flex-flow:column;
justify-content:space-between;

#btn{
width:20%; 
height:20px;
}

#msg{
display:flex;
text-align:center;
align-items:center;
justify-content:center;
margin-top:10%;
}

`;

export default Staff;