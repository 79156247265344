import { SET_USER} from './ActionType';
import Swal from 'sweetalert2'
import axios  from 'axios'
import {v4 as uuid4} from 'uuid'
import {auth} from '../firebase'
import AWS from 'aws-sdk';
import {setPersistence,browserSessionPersistence,signInWithEmailAndPassword,signInWithPopup} from 'firebase/auth'
import { FormatNumber, Message, Add_session, setCookie, getCookie, Get_session, remove_white_space, ToUpper} from '../Reuse_ui/Logic';
import { ConsoleView } from 'react-device-detect';
import {Cloudinary} from "@cloudinary/url-gen";




AWS.config.update({
   accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
   secretAccessKey: process.env.REACT_APP_SECRET_KEY
 });
 

 
 const bucket = new AWS.S3({
   apiVersion: process.env.REACT_APP_API_VERSION,
   httpOptions: {timeout: 0},
   params: {Bucket: process.env.REACT_APP_S3_BUCKET},
   region: process.env.REACT_APP_REGION, 
 });




 export const cld = new Cloudinary({
   cloud: {
     cloudName: 'otecdealings'
   }
 }); 

//-------------------------------------------------------Web request -------------------------------------------------------//
export function LoginAuth(e,nav){
     //return  async (dispatch) => {
        setPersistence(auth,browserSessionPersistence)
            .then(async () => {
                  signInWithEmailAndPassword(auth,e[0].toString().trim(),e[1].toString().trim())
                     .then(async (user) => {

                        user.user.getIdToken().then(async(idToken) => {
                           
                            try{

                               let res = await axios.post(process.env.REACT_APP_LOGIN_USER,{idToken:idToken});
                                    setCookie('esession',res.data.message);
                                     Add_session("em",sessionStorage,[user.user.email]);
                                      //dispatch(setUser(user.user));
                                       nav("/");
                               }catch(err){
                                   Message(`Login failed`,err.message,"warning");
                               }
                             })
                           }).then(() => {
                              auth.signOut();
                         })
                          .catch(err => {
                               if(err.toString().includes("auth/wrong-password"))
                                    Message(`Login failed`,"Email or password combination is incorrect","warning");
                                else
                                 if(err.toString().includes("auth/user-not-found"))
                                     Message(`Login failed`,"No account associated with this email.","warning");
                                else
                                  if(err.toString().includes("auth/invalid-email"))  
                                    Message(`Login failed`,"Invalid email.","warning");
                               console.log(err);
                           })
                            .then(() => { })
                              .catch((err) => {
                                console.log(err);
                             })
                         }).catch((err) => {
                            Message(`Login failed`,"Browser error","warning")
                })
        //}
}






export const getData = async () => {
        const response = await fetch('https://api.ipdata.co/?api-key='+process.env.REACT_APP_ACCESS_LOCATION);
          const data = await response.json();
          if(response.status === 200)
              return {Payload:{ip:data.ip,calling_code:data.calling_code,city:data.city,country_name:data.country_name}};  
}






export const Submit = (args,shareImage,points,url,check,model) => {

   if(shareImage.length > 0){
        const   params = {
              ACL: process.env.REACT_APP_READ_RULE,
              Body: shareImage,
              Bucket: process.env.REACT_APP_S3_BUCKET,
              Key: process.env.REACT_APP_S3_PICTURE+args.trim(),
          };
          bucket.putObject(params)
              .on('httpUploadProgress', (e) => {})
               .on('httpDone', (e) => {
                    CLOUD(params,Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User,points,url,check,model);
              })
               .send((err) => {
                  if(err) 
                    alert("Snap error occurred");
              });  
      }
      else
          PostAPI(null,Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User,points,url,check,model);
 } 
 
 
 

 function urls(type,url){
   return process.env.REACT_APP_APP_NAME
            +remove_white_space(type.service)+"/"
              +type.company_email+"/"
                +url.Key.toString().replace("/","")
 }


 
 


 const CLOUD = async (url,type,point,redi,check,model)  => {
   let map = await axios.post(process.env.REACT_APP_CLOUD,{url:process.env.REACT_APP_APP_S3_BASE_URL+url.Key,publicface:urls(type,url)})
      if(map.data.message)
          PostAPI(map.data.message,type,point,redi,check,model);
 }
 




 const PostAPI = async (url,type,points,redi,check,mode) => {

   try{

       axios.defaults.headers.common['Authorization'] = `${process.env.REACT_APP_BEAR}`;
         let e = {  
                     esession:getCookie("esession"),
                     write:points,
                     market:"n/a",
                     bannerUrl:url,
                     service:type.service,
                     email:type.company_email,
                     price: type.service === ToUpper(process.env.REACT_APP_CARPARK_NODES) ? parseInt(points) : 0,
                     type:type.service === ToUpper(process.env.REACT_APP_CARPARK_NODES) ? check.toString() : undefined,
                     model: type.service === ToUpper(process.env.REACT_APP_CARPARK_NODES) ? undefined : {Ky:mode.Ky,Flag:mode.Flag},
                  }

          console.log(e);        

         let res = await axios.post(process.env.REACT_APP_UPDATER_NODE,e);
            if(res.data){
               Message('Info',res.data.message,'info');
               //window.location.href = redi
            }
               

         }catch(err){
            return err.code
         }
 }






 export async function Register(list,packages,code,csv){
  return await axios.post(process.env.REACT_APP_ONBOADING,{User:{company_email:list[0],
      company_phone:FormatNumber(list[1],code),company_name:list[2],company_password:list[3],
            service:list[4],package:packages,csv:csv,GeoPoint:{latitude:0,longitude:0},session:getCookie("asession")}},{withCredentials:true});
}





export async function  request(session){
    axios.defaults.headers.common['Authorization'] = `${process.env.REACT_APP_BEAR}`;
      return await axios.post(process.env.REACT_APP_VERIFY,{esession:getCookie("esession"),email:Get_session("em",session)[0]})

};



export async function  requestTermianl(url,session){
   console.log("Te")
   axios.defaults.headers.common['Authorization'] = `${process.env.REACT_APP_BEAR}`;
     return await axios.post(url,{esession:getCookie("esession"),email:Get_session("em",session)[0],
         serviceType:Get_session(process.env.REACT_APP_USERNODEKEY,session)[0].User.service,
               company_email:Get_session(process.env.REACT_APP_USERNODEKEY,session)[0].User.company_email})

};








export const fireMessage = async (list) => {
   console.log(list);
   try{
      let res = await axios.post(process.env.REACT_APP_MESSAING,{list});
         return res.data;
   }catch(err){
       Message("Error",err.code,"warning");
   }
}



export const CheckNode = async (uid) => {
   try{
       let nodeload = {node:uid};
        let res = await axios.post(process.env.REACT_APP_IMG_BANNER,nodeload);
         return res.data;
      }catch(err){
        console.log(err)
        //Message("Error",err,"warning");
   }
}



export const SendNotice = (payload) => {

}


export function List(){
   return ['beeppoint card','Where to get beeppoint card','Where can beeppoint card be used','businesses on maps']
}


export function List2(){
   return ['beeppoint institution package','beeppoint rewards']
}



export function List3(){
   return ['Frequently ask questions',"Terms and condition"]
}


export const QuestionList = () => {
   return [
            {question:"What is Beeppoint?",answear:"Beeppoint is a payment and access solution system. Beeppoint card makes access to your community, car parks and payment for transport and other transactions seamless and fun. With a Beeppoint card, everything you need is a beep away."},
            {question:"Why should we use Beeppoint? ",answear:"<ul> <li>  Beeppoint is designed to not just make payment and access easier but to give a sense of style and swag to every transaction and access </li> <li>Beeppoint is designed with a state of the art security system which makes it safe.</li>  <li> As a community, Beeppoint gives you control and real-time data on who comes into your community and their period of stay and exit thereby making your community safe.</li> <li> As a merchant, Beeppoint helps to create a dedicated customer base that is easier to reach with just a click away. Remember, loyal customers mean thriving business.</li> <li> As a student, life couldn't be more fun when all you have to do is just beep it and you can get all you need. </li> </ul>"},
            {question:"Where can I use the card? ",answear:"You can use the Beeppoint card at any place you see the Beeppoint logo which could be local or international stores, car parks, community entry points, cinemas, shopping malls, bus stations, train stations and more. "},
            {question:"How do I check my Beeppoint card balance?", answear:"<ul> <li>Open Beeppoint mobile app on your smartphone. Your e-wallet balance is the same as your Beeppoint card balance. </li></ul>"},
            {question:"Can I transfer money from my Beeppoint wallet to another Beeppoint wallet or another account?", answear:"<ul> <li>  Yes, you can! All you need is the receiver Beeppoint e-wallet number or account and follow the process as required. </li></ul>"},
            {question:"How do I recharge my card?",answear:"<ul> <li>  You could recharge your Beeppoint card by transferring money from your bank account to your Beeppoint e-wallet in Beeppoint mobile app. </li> <li> You could load your Beeppoint card at any Beeppoint agent or POS agent. </li></ul>"},
            {question:"LOST, REPLACEMENT, STOLEN.", answear:"Stolen or Lost <br/> <ul><li> You can block your stolen or lost Beeppoint card by logging on to your Beeppoint account via Beeppoint mobile app.</li> <li> Select the Beeppoint card you want to block and select Block.</li> </ul>"},
            {question:"Replacement / Damaged",answear: "<ul><li> You could request a new Beeppoint card from your Beeppoint mobile app conveniently and have it delivered to your doorstep for a token.</li></ul>"},
            {question:"How many Beeppoint cards can I register on the Beeppoint account? ",answear: "<ul><li> You can register up to 10 Beeppoint cards on your Beeppoint account with different email addresses.</li></ul>"},
            {question:"Can somebody else use my Beeppoint card? ", answear:"<ul><li> Somebody else can use your Beeppoint card. In the case of theft or lost card, you can immediately block your card by logging on to your Beeppoint account via your Beeppoint mobile app or request a new card instantly.</li></ul>"},
            {question:"What happens to the balance on an expired, lost or stolen card? ",answear:"<ul><li> If your Beeppoint card expires it does not affect your Beeppoint e-wallet. </li> <li> If your card is stolen or lost, your card balance is safe only if you block the card immediately via your Beeppoint mobile app. </li></ul>"},
         
         ]
}


export function Tr(data){
  return data.trim().length > 0 
}






export function isNumber(evt) {
   evt = (evt) ? evt : window.event;
   var charCode = (evt.which) ? evt.which : evt.keyCode;
   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
   }
   return true;
}






































//-------------------------------------------------------------------Marcheant Ednpoints -------------------------------------------------------------------//




export async function ResetAccount(lat,lng,payload) {
   console.log(lat,lng,payload);
   
   try{
      let res  =  await  axios.post(process.env.REACT_APP_MARCHANT_RESET_PASS,{company_email:payload[0],Otp:payload[1],service:payload[2],goe:{la:lat,lo:lng}})
        return res.data;
     }catch(err){
        Message('Info',err.response,'warning');
   }
}







export function redirect1(c1,index,nav){
   c1[0].User.service === process.env.REACT_APP_REWARD_NODES.toUpperCase() || c1[0].User.service === process.env.REACT_APP_CARPARK_NODES.toUpperCase()  
     ? 
    nav(`/cpanel/${index}`) 
     :
   console.log("No match");
}

export function redirect2(c1,index,nav){
   c1[0].User.service === process.env.REACT_APP_SCHOOL_NODES.toUpperCase() || c1[0].User.service === process.env.REACT_APP_ESTATE_NODES.toUpperCase()  
     ?
    nav(`/cpanel/${index}`) 
     :
   console.log("No match");
}






export const pricing =  async () => {
   try{
        let n = await axios.post(process.env.REACT_APP_PRICING,{data:null});
         return n.data;
   }catch(err){
      Message('Error occured', err.message, 'error');
   }
}



   


export const disableTerminal = async (terid,Session,stats) => {
   try{
      let n = await axios.post(process.env.REACT_APP_DISABLE,{userid:terid,session:getCookie("esession"),email:Get_session("em",Session)[0],
            sT:Get_session(process.env.REACT_APP_USERNODEKEY,Session)[0].User.service,
               company_email:Get_session(process.env.REACT_APP_USERNODEKEY,Session)[0].User.company_email,action: stats.status === true ? false : true});
                  return n.data;
      }catch(err){
         Message('Error occured', err.message, 'error');
      }
}




export const disableCurrentTerminals = async (Session) => {
   try{
      let n = await axios.post(process.env.REACT_APP_DISABLE_ALL_TERMINAL,{session:getCookie("esession"),service:Get_session(process.env.REACT_APP_USERNODEKEY,Session)[0].User.service,
               company_email:Get_session(process.env.REACT_APP_USERNODEKEY,Session)[0].User.company_email});
                  return n.data;
      }catch(err){
         Message('Error occured', err.message, 'error');
      }

}



export const AddNewMemebr = async (surname,father,mother,children,phoneMail,session) => {
   console.log(surname,father,mother,children,phoneMail);

   let K = Get_session(process.env.REACT_APP_USERNODEKEY,session)[0];
   let data = K.User.service === process.env.REACT_APP_ESTATE_NODES.toUpperCase() 
        ?      
        {User:{fN:surname,
            F:father,
            M:mother,
            C:children,
            EP:phoneMail.toLowerCase(),
            company_email:Get_session(process.env.REACT_APP_USERNODEKEY,session)[0].User.company_email, 
            session:getCookie("esession"),
            service:Get_session(process.env.REACT_APP_USERNODEKEY,session)[0].User.service,
            url:process.env.REACT_APP_ADD_FAM}}
         :
         {User:{SN:children,
               EP:phoneMail.toLowerCase(),
               company_email:K.User.company_email,
               session:getCookie("esession"),
               service:K.User.service,
               url:process.env.REACT_APP_JOIN_SCHOOL}}

   
   try{
      let res = await axios.post(data.User.url,data);
                return res.data;
      }catch(err) {
         console.log(err.response);
         Message("Error",err.code,"warning");
      }
}



export const addSingleNode = async (name,mail,famId,session) => {
   console.log(name,mail,famId);
   let K = Get_session(process.env.REACT_APP_USERNODEKEY,session)[0];
   let data = K.User.service === process.env.REACT_APP_ESTATE_NODES.toUpperCase() 
        ?      
         {User:{Name:name,
               email:mail,
               key:famId,
               company_email:K.User.company_email,
               session:getCookie("esession"),
               service:K.User.service,
               url:process.env.REACT_APP_FAMILY_CHILD}}
         :
         {User:{SN:name,
               EP:mail,
               old:famId,
               company_email:K.User.company_email,
               session:getCookie("esession"),
               service:K.User.service,
               url:process.env.REACT_APP_EDIT_STUDENT}}
   
      try{
         console.log(data);
         let res = await axios.post(data.User.url,data);
            return res.data;
         }catch(err) {
            Message("Error",err.code,"warning");
         }
}




export const disabeUser = async (famId,session,ac) => {
   console.log(famId);
   let K = Get_session(process.env.REACT_APP_USERNODEKEY,session)[0];
   let data = K.User.service === process.env.REACT_APP_ESTATE_NODES.toUpperCase() 
        ?      
         {User:{
               editoremail:famId,
               company_email:K.User.company_email,
               session:getCookie("esession"),
               service:K.User.service,
               action:ac.disabled === true ? 1 : 0,
               url:process.env.REACT_APP_EDIT_FAMILY}} //edit
         :
         {User:{
               EP:famId,
               company_email:K.User.company_email,
               session:getCookie("esession"),
               service:K.User.service,
               action:ac.disabled === true ? 1 : 0,
               url:process.env.REACT_APP_DISABLE_STUDENT}}
   
   try{
      console.log(data);
      let res = await axios.post(data.User.url,data);
         return res.data;
      }catch(err) {
         Message("Error",err.code,"warning");
      }
}





export const ScansBeep = async (session_key) => {
   try{
       let K = Get_session(process.env.REACT_APP_USERNODEKEY,session_key)[0];
       let N = {User:{company_email:K.User.company_email,service:K.User.service,session:getCookie("esession"),key:null}};
       let res = await axios.post(process.env.REACT_APP_SCANS,N);
       return res.data;
      }catch(err){
         Message("Error",err.code,"warning");
    }
}






export const PullScansAndInvite = async (data,index,session_key) => {
   try{
      let U = Get_session(process.env.REACT_APP_USERNODEKEY,session_key)[0];
        let P = {User:{company_email:U.User.company_email,service:U.User.service,session:getCookie("esession"),key:data,index:index,isSorted:false}};
        console.log(P);
        let res = await axios.post(process.env.REACT_APP_COMPANY_SCANS_INVITES,P);
        return res.data;
       }catch(err){
          Message("Error",err.code,"warning");
     }
}






export async function QueryInvitesScansByDate(data,date,session_key,index){
   let U = Get_session(process.env.REACT_APP_USERNODEKEY,session_key)[0];
    let P = {User:{company_email:U.User.company_email,service:U.User.service,session:getCookie("esession"),key:data,index:index,isSorted:true,dateSort:date}};
    console.log(P); 
    let res = await axios.post(process.env.REACT_APP_COMPANY_SCANS_INVITES,P);
    return res;
}





































//---------------------------------------------------------Beepadmin-------------------------------------------------------------------------//

export const beepointLogin = async (email,password,nav) => {
         setPersistence(auth,browserSessionPersistence)
              .then(async () => {
                  signInWithEmailAndPassword(auth,email,password)
                     .then(async (user) => {
                           user.user.getIdToken().then(async(idToken) => {
                              try{
                               let res = await axios.post(process.env.REACT_APP_ADMINLOGIN,{idToken:idToken,email:user.user.email});  
                               console.log(res.data.message)
                                setCookie('asession',res.data.message.session);
                                  Add_session("dm",sessionStorage,[user.user.email,user.user.phoneNumber]);
                                      nav("/cp");
                                }catch(err){
                                   Message(`Login failed`,err.message,"warning")
                                }
                             })
                           }).then(() => {
                              auth.signOut();
                         })
                          .catch(err => {
                               if(err.toString().includes("auth/wrong-password"))
                                    Message(`Login failed`,"Email or password combination is incorrect","warning");
                                else
                                 if(err.toString().includes("auth/user-not-found"))
                                     Message(`Login failed`,"No account associated with this email.","warning");
                               console.log(err);
                            })
                            .then(() => {})
                             .catch((err) => {
                                console.log(err);
                             })
                        }).catch((err) => {
                           Message(`Login failed`,"Browser error","warning")
                });
}









export const beepoint_staff_Login = async (email,password,nav) => {
   setPersistence(auth,browserSessionPersistence)
        .then(async () => {
            signInWithEmailAndPassword(auth,email,password)
               .then(async (user) => {
                     user.user.getIdToken().then(async(idToken) => {
                        try{
                         let res = await axios.post(process.env.REACT_APP_LOGIN_STAFF,{idToken:idToken,email:user.user.email});    
                         console.log(res.data);
                          setCookie('ssession',res.data.message.session);
                            Add_session("staff",sessionStorage,[res.data.message.userObj.email,res.data.message.userObj.ref_code]);
                                nav("/staff");
                          }catch(err){
                             Message(`Login failed`,err.message,"warning")
                          }
                       })
                     }).then(() => {
                        auth.signOut();
                   })
                    .catch(err => {
                         if(err.toString().includes("auth/wrong-password"))
                              Message(`Login failed`,"Email or password combination is incorrect","warning");
                          else
                           if(err.toString().includes("auth/user-not-found"))
                               Message(`Login failed`,"No account associated with this email.","warning");
                         console.log(err);
                      })
                      .then(() => {})
                       .catch((err) => {
                          console.log(err);
                       })
                  }).catch((err) => {
                     Message(`Login failed`,"Browser error","warning")
          });
}








export const MarchetList = async (n) => {
   try{
       let K =  {asession:getCookie("asession"),
                  table: 
                    n !== 1 ?
                       TrimUrl() ===  "Beeppoint reward" 
                               ? 
                                 TrimUrl()+"s" 
                                   :
                                 TrimUrl()
                               :
                       "Beeppoint education"
                 }
        console.log(K);
        let list = await axios.post(process.env.REACT_APP_COM_LIST,K);
            return list.data.data;
   }catch(err){
      console.log(err.code)
      Message('Error',err.code.toString().includes("ERR_BAD_RESPONSE") ? "Pls Login" : err.code,'error');
   }
}









export async  function  requestSessionToken(){
   let e = await MarchetList(TrimUrl() == "cp" ? 1 : null)
    return e !== undefined ? e : [];
}










export const SendRequest  =  async (Tc,loc,enN,enE,v) => {
   let data = {
            company_email:v.company_email,
            company_phone:v.company_phone,
            terminal_count:Tc,
            service:v.service,
            workstation:v.company_uid,
            locations:loc,
            enginnerName:enN,
            enginnerEmail:enE,
            workerId: Get_session("dm",sessionStorage)[0],
            workerPhone: Get_session("dm",sessionStorage)[1],
         }
         console.log(data);
    try{
       let res = await axios.post(process.env.REACT_APP_TERMINAL_SETUP,data);
        return res.data;
      }catch(err){
           Message("Error",err.code,"error")
      }
}











export const StaffNewMemberRegistation = async (name,email,phone,password,session) => {
   try{
      const map = await getData();             
          let res  =  await  axios.post(process.env.REACT_APP_STAFF_PORTAL_SIGNUP,{name:name, email:email, phone:"+"+map.Payload.calling_code+phone.substr(1),
                        password:password,referral_code:Get_session("staff",session)[1],ssession:getCookie("ssession")})
            return res.data;
      }catch(err){
          Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
   }
}







export const PullData = async (session) => {

   try{
       let obj = {address:Get_session("time_filter",session)[0],email:Get_session("staff",session)[0],ssession:getCookie("ssession")};
         let res = await axios.post(process.env.REACT_APP_STAFF_LIST_SIGNUP,obj);
         return res.data.message;

      }catch(err){
         Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
    }
}










export const Convert = async (session) => {
   try{
       let obj = {email:Get_session("staff",session)[0],address:Get_session("time_filter",session)[0],ssession:getCookie("ssession")};
         let res = await axios.post(process.env.REACT_APP_STAFF_CONVERT,obj)
               return res.data.message;
      }catch(err){
         if(JSON.stringify(err.message).includes("Request failed with status code 500")){
               Message('Info','Session Expired','waring')
                  window.location.href= "/StaffLogin"
         }else
             Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
    }
}








export const ListBanks = async () => {
   try{
         let res = await axios.get(process.env.REACT_APP_BANK_LIST);
         return res.data;
   }catch(err){
        console.log(err);
   }
}









export const Payinit = async (l) => {
   try{

      if(!l[2].toString().includes("Select Bank")){
            let obj = {
                           uid:l[0],
                           phone:l[1],
                           url:l[2],
                           email:Get_session("staff",l[3])[0],
                           referral_code:Get_session("staff",l[3])[1],
                           address:Get_session("time_filter",l[3])[0],
                           ssession:getCookie("ssession")
                        };
               let res = await axios.post(process.env.REACT_APP_STAFF_INIT_PAY,obj)
                     return res.data.message;
      }else
         Message('Info',"Pls select Bank",'warning');
          
      }catch(err){
         Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
    }
}






















export const SendCommand = async (e,ps) => {

   let hold = {
      adminEmail:Get_session("dm",sessionStorage)[0],
      password:ps,
      businessEmail:e.v.company_email,
      service:e.v.service,
      session:getCookie("asession"),
   };
   
   let res = await axios.post(CheckUi(e.i),hold);
     return res.data;
};










export const ViewPayout = async (l) => {
   try{
      let K = {asession:getCookie("asession")}
       console.log(K);
        let res = await axios.post(process.env.REACT_APP_STAFF_PAYOUT,K);
        console.log(res);
            return res.data.message;

    }catch(err){
       Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
   }
}








export const SendCom = async (l) => {
   try{
      let K = {email:l.id,address:l.body.date,referral_code:l.body.refCode,asession:getCookie("asession")}
        let res = await axios.post(process.env.REACT_APP_PAY_STAFF,K);
        console.log(res)
            return res.data.message;

    }catch(err){
      console.log(err);
       Message('Info', err.response.data.message.message ? err.response.data.message.message : err.response.data.message,'warning');
   }
}








export async function ListScansByDate(U,date,session_key){
   let P = {User:{company_email:U.company_email,service:U.service,session:getCookie("asession"),isSorted:true,dateSort:date}};
   let res = await axios.post(process.env.REACT_APP_LOYALTY_SCANS_INVITES,P);
   return res;
}







export async function SendValidation(ce,pid,docId){
   let P = {asession:getCookie("esession"),company_email:ce,pd:pid,cid:docId};
     let res = await axios.post(process.env.REACT_APP_POINT_VALIDATION,P);
     return res;
}







export async function fireImportRegistration(n,nav){
   try{
      let P = {company_email:n[0],password:n[1],company_name:n[2],asession:getCookie("asession")};
        let res = await axios.post(process.env.REACT_APP_IMPORTER_REGISTER,P);
         Message("Info",res.data.message,"info")
          nav("/cp")
      }catch(err){
          Message("Error",err.request.response,"error")
     }
}





export async function ListImporter(){
   try{
      let P = {asession:getCookie("asession")};
        let res = await axios.post(process.env.REACT_APP_IMPORTER_LIST,P);
         return res.data.message;
     }catch(err){
          Message("Error",err.request.response,"error")
        return [];
     }
}







export async function ListUsers(){
   try{
      let P = {User:{session:getCookie("asession")}};
        let res = await axios.post(process.env.REACT_APP_USER_LIST,P);
         return res.data.message;
     }catch(err){
          Message("Error",err.request.response,"error")
        return [];
     }
}





export async function SubListUsers(uid){
   try{
      let P = {session:getCookie("asession"),uid:uid};
        let res = await axios.post(process.env.REACT_APP_SUB_LIST,P);
         return res.data.message;
     }catch(err){
          Message("Error",err.request.response,"error")
        return [];
     }
}








export async function EditUsers(nd){
   try{
      let P = {uid: nd.uid === undefined ? nd.email:nd.uid, email:nd.email,type:nd.isDisabled};
        let res = await axios.post(process.env.REACT_APP_EDIT_LIST,P);
         return res.data.message;
     }catch(err){
          Message("Error",err.request.response,"error")
        return [];
     }
}






export async function ImporterAddToList(n){
   
   try{

      if(n[5] && n[6] && n[0]){

            const   params = {
                  ACL: process.env.REACT_APP_READ_RULE,
                  Body: n[5],
                  Bucket: process.env.REACT_APP_S3_BUCKET,
                  Key: process.env.REACT_APP_S3_IMPORTER+n[4],
            };

            bucket.putObject(params)
                  .on('httpUploadProgress', (e) => {})
                  .on('httpDone', (e) => {
                        CLOUD2(params,n);
                  })
                  .send((err) => {
                     if(err) 
                        alert("Snap error occurred");
                  });  
         }else
            Message("Info","Pls select an image and fill fields Properly","error")

      }catch(err){
          Message("Error",err.request.response,"error")
        return [];
     }

}





const CLOUD2 = async (url,n)  => {
     let map = await axios
                  .post(process.env.REACT_APP_CLOUD,
                                 {url:process.env.REACT_APP_APP_S3_BASE_URL+url.Key,
                                        publicface:
                                                  urls({
                                                         service:process.env.REACT_APP_IMPORT,
                                                         company_email:n[2]
                                                         },
                                                       url)})
 
      if(map){
               let P = {
                           description:n[0],
                           price:n[6],
                           modelType:n[1],
                           import_uid:n[3],
                           url:map.data.message,
                           asession:getCookie("asession")
                        }
                  
               let res = await axios
                   .post(process.env.REACT_APP_IMPORTER_ADD_ITEMS,P);
               if(res){
                  console.log(res);
                   if(res.status == 200 || res.status == 201)
                        Message("Info",res.data.message,"success")
                    else
                       Message("Info","Error occurred try again later","error")
               }
            }
      
}




function CheckUi(i){
  return i === 1 ? process.env.REACT_APP_DISBABLE_ACCOUNT : process.env.REACT_APP_DELETE_ACCOUNT
}




export const RedirectToLogin =  async (node,navigate) =>{
     console.log(node[0]);
}



export function TrimUrl(){
   return  window.location.href.substring(window.location.href.lastIndexOf('/')+1).replace("+"," ");
}



export function ToCaps(v){
   return  v.toUpperCase();
}
































































//----------------------------------------------------- Reset link ----------------------------------------------------------//

export async function  GetLinkReset(list){
   let e = {password:list[0],payload:list[1]}
   console.log(e);
   return await axios.post(process.env.REACT_APP_AUTH_USER_ACCOUNT,e);
}





export async function  ResetLink(payload){
    let e = {payload:payload}
    console.log(e);
   return await axios.post(process.env.REACT_APP_RESET_LINK,e);
}




export async function FindLocation(payload){
    console.log(payload);
      navigator.geolocation.getCurrentPosition( 
         async  function (position) {
               let res =  await axios.post(process.env.REACT_APP_UPDATE_COMPANY_LOCATION,{esession:getCookie("esession"),company_email:payload.company_email,
                  service:payload.service,goe:{latitude:position.coords.latitude,longitude:position.coords.longitude}});
               if(res)
                  Message("Info",res.data.message,'info')
      });
}





export async function BusinessGiftCard(payload){
       let res = await axios.post(process.env.REACT_APP_ADD_BUSINESS_TO_GIFT_CARD_PROGRAM,{company_email:payload,asession:getCookie("asession")});
         return res.data;

}



export function GetUserGeo (){
   const promise = new Promise((resolve, reject) => {  
      navigator.geolocation.getCurrentPosition( 
         async  function (position,err) {
            if(err)
               reject(false)

               if(position){
                  console.log(position);
                  resolve(position);
                  }
           })
         });
   return promise;
}





export async function GetLocation(){
          var geo = await GetUserGeo();
          if(geo){
               let e = {Geo:{latitude:geo.coords.latitude, longitude: geo.coords.longitude},action:1}
               console.log(e);
                  let res =  await  axios.post(process.env.REACT_APP_LOCATION_FINDER,e);
               return res;
       }
        
}





export function Format(date){
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
}





export function Replace(data:any,oldChar,newChar):string{
   return data.replaceAll(oldChar, newChar.trim())
 }
