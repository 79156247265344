import React from 'react'
import styled from 'styled-components';



const RegisterUsersNode =  ({v})  =>{
  return (
    <Container>
        <table style={{width:"100%",textAlign:"center", display:"flex",justifyContent:"space-between"}} >
            <tr>
                <td>{v.reg_email}</td>
                <td>{v.reg_phone}</td>
            </tr>
            <tr>
                <td>{v.points}</td>
                <td>{v.date}</td>
            </tr>
        </table>
    </Container>
  )
}


const Container = styled.div`
border-radius:5px;
padding:10px;
margin:10px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
tr td{
display:flex;
font-size:12px;
flex-flow:column;
text-align:left;

}
`;

export default RegisterUsersNode