import AOS from 'aos';
import "aos/dist/aos.css";
import {motion as m} from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import  Nav  from "react-bootstrap/Nav";
import  Table  from "react-bootstrap/Table";
import { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { remove_white_space } from '../Reuse_ui/Logic';
import { useNavigate } from 'react-router-dom';
import {RiCloseLine, RiLoginCircleLine, RiMenu2Line} from 'react-icons/ri';
import { request } from '../actions_api';
import { Body } from '../Reuse_ui/Css';

const Header = ({ui}) => {


    const [open, setOpen] = useState(false);
    const [isOPen, setIsOpen] = useState(false);
    const [left, setLeft] = useState("");
    const [hold,setHold] = useState(false);
    const [page, setPage] = useState(null);
    const [dropList, setDropList] = useState(["beeppoint card","Where to get beeppoint card","Where can beeppoint card be used","businesses on maps","Beeppoint institution package","beeppoint rewards","Frequently ask questions","Terms and condition"]);
    const navigate = useNavigate();    
    const [width, setWidth] = useState(window.innerWidth);


    
    const SendPage = (n,i,url,e) => {
        setOpen(n === 0 ? true : false);

        if(i !== null)
           setPage(i);
        setLeft("25%");

        if(e.type === "click"){
          window.scrollTo(0,0);
          navigate((url === 'home') ? '/' : '/'+remove_white_space(url))
        }
    }



    async function Display(){
        if(await request(sessionStorage))
             setHold(true);
  
    }



    useEffect(() => {
            AOS.init();
            AOS.refresh();
            Display();
            window.addEventListener('resize', handleCheck);
        return () => {window.removeEventListener('resize', handleCheck);}
      },[])



    
      function handleCheck() {
          setWidth(window.innerWidth);
      }
   
    



    return (
        <Body w={'100%'} h={'15vh'}  style={{background:"#f5f5f5"}}>
              <Container  height={width < 760 ? "10vh" : "12vh"}>
                        <SectionA onClick={(e) => SendPage(101,null,"home",e)}>
                            <div id='img'>
                                <img src="../assets/trans.png" />
                            </div>
                                 <h3>BeePPoint</h3>
                        </SectionA>

                        <SectionB>
                             <NavBody>
                                  <Nav style={{display:"flex", justifyContent:"space-evenly"}} variant="pills"  defaultActiveKey="/">
                                        <Nav.Item>
                                            <Nav.Link href="/">Home</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onMouseEnter={(e)=> SendPage(0,1,"Beeppoint card",e)}>
                                            <Nav.Link eventKey="link-1">Beeppoint card</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onMouseEnter={(e)=> SendPage(0,2,null,e)}>
                                            <Nav.Link eventKey="link-2">Beeppoint app</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onMouseEnter={(e)=> SendPage(0,3,null,e)}>
                                            <Nav.Link eventKey="link-3">resource</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={(e)=> SendPage(1,null,"business",e)}>
                                            <Nav.Link eventKey="link-4">beeppoint business</Nav.Link>
                                        </Nav.Item>

                                        {hold ?  
                                        <Nav.Item onClick={(e)=> SendPage(1,null,"cpanel/0",e)}>
                                          <Nav.Link eventKey="link-4">admin</Nav.Link>
                                        </Nav.Item>
                                       : null}
                                    </Nav>
                               </NavBody>
                                <LoginSearch>
                                       <div onClick={(e) => SendPage(1,null,"help+support",e)}>Help & Support</div>
                                       <div onClick={(e) => SendPage(1,null,"login",e)}>Login</div>
                                </LoginSearch>
                            </SectionB>  

                            <SectionC>
                                    <RiMenu2Line onClick={(e) => {isOPen ? setIsOpen(false) : setIsOpen(true);} } style={{marginLeft:"auto",marginRight:"10px"}} size={30}/>
                             </SectionC>

                                {open ?
                                <InvisibleCon onMouseLeave={(e) => SendPage(1)}>
                                        <DropDw lf={left}>
                                            <Table data-aos="zoom-out-up">
                                                {page === 1 ? dropList.map((v,i) => <tr style={{lineHeight:2.5}}  onClick={(e) => SendPage(1,null,v,e)}><td>{i <= 3 ?  `${v}` : ""}</td></tr>) : null}
                                                {page === 2 ? dropList.map((v,i) => <tr style={{lineHeight:2.5}}  onClick={(e) => SendPage(1,null,v,e)}><td>{i > 3 && i <= 5 ?  `${v}` : ""}</td></tr>) : null}
                                                {page === 3 ? dropList.map((v,i) => <tr style={{lineHeight:2.5}}  onClick={(e) => SendPage(1,null,v,e)}><td>{i > 5 && i <= 7  ?  `${v}` : ""}</td></tr>) : null}
                                            </Table>
                                        </DropDw>
                                </InvisibleCon>
                                    :
                                    null
                                }

                                {isOPen ?
                                                                
                                        <OpenNav>
                                            <m.div 
                                              initial={{y:"100%"}}
                                              animate={{y:"0%"}}
                                              exit={{opacity:1}}
                                              transition={{duration:0.75, ease:"easeOut"}}> 
                                                        <NavBody>   
                                                            <RiCloseLine style={{color:"#302EA7",margin:"10px"}} onClick={(e) => { setIsOpen(false); SendPage(1)}}  size={30}/>
                                                        </NavBody>
                                                         <Nav  style={{display:"flex",flexFlow:"column",justifyContent:"space-evenly", width:"80%",margin:"0 auto"}} variant="pills" defaultActiveKey="/">
                                                                <Nav.Item style={{marginTop:"100px"}}>
                                                                    <Nav.Link href="/">Home</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item style={{marginTop:"30px"}} onMouseEnter={(e)=> SendPage(0,1,"Beeppoint card",e)}>
                                                                    <Nav.Link eventKey="link-1">Beeppoint card</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item style={{marginTop:"30px"}} onMouseEnter={(e)=> SendPage(0,2,null,e)}>
                                                                    <Nav.Link eventKey="link-2">Beeppoint app</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item style={{marginTop:"30px"}} onMouseEnter={(e)=> SendPage(0,3,null,e)}>
                                                                    <Nav.Link eventKey="link-3">resource</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item style={{marginTop:"30px"}}  onClick={(e)=> SendPage(1,null,"business",e)}>
                                                                    <Nav.Link eventKey="link-4">beeppoint business</Nav.Link>
                                                                </Nav.Item>
                                                                {hold ?  
                                                                    <Nav.Item onClick={(e)=> SendPage(1,null,"cpanel/0",e)}>
                                                                        <br/>
                                                                        <Nav.Link eventKey="link-4">admin</Nav.Link>
                                                                    </Nav.Item>
                                                                : null}
                                                         </Nav>

                                                        <LoginSearch>
                                                           <RiLoginCircleLine/> &nbsp;&nbsp; <div onClick={(e) => SendPage(1,null,"login",e)}>Login</div>
                                                        </LoginSearch>
                                            </m.div>
                                        </OpenNav>
                            :
                        null
                      }
           </Container>
        </Body>
    )
}




const Container = styled.div`
width:100%; 
display:flex;
background:#f5f5f5;
height:${(props) => props.height};
justify-content:space-between;
font-family: "Poppins", sans-serif;
@media(max-width:980px){
box-shadow: 0px 15px 10px -15px #111; 
height:${(props) => props.height};
}
`;


const SectionA = styled.div`
width:30%; 
height:100%;
padding-top:15px;
display:flex;
#img{
width:40px;
height:50px;
margin-left:10px;
img{
width:50px; 
height:30px;
object-fit:contain;
}
}
h3{
padding-top:7px;
font-size:16px;
color:#302EA7;
}
`;


const SectionB = styled(SectionA)`
width:60%; 
height:100%;
display:flex; 
flex-flow:row;
z-index:9999999;
font-size:12px;
@media(max-width:980px){
display:none;
}
`;



const SectionC = styled(SectionB)`
height:100%;
flex-flow:column;
display:none;
@media(max-width:980px){
width:100%;
display:flex; 
}
`;


const InvisibleCon = styled.div`
position:absolute;
right:0;
height:20vh; 
width:50%; 
margin-top:50px;
@media(max-width:980px){
width:100%;
}
`;

const DropDw = styled.div`
z-index:99999999999999;
position:absolute;
height:auto;
width:auto;
display:flex;
margin-left:${(props) => props.lf}
font-size:12px;
padding:25px;
color:#ffffff;
margin-top:50px;
background:#302EA7;
border-radius:15px;
justify-content:center;
font-family: "Poppins", sans-serif;
table{
cursor:pointer;
}
@media(max-width:760px){
margin-left:10%;
font-size:12px;
}
`;




const LoginSearch = styled.div`
width:120px; 
height:100%;
display:flex;
margin-left:auto;
flex-flow:column;
color:#302EA7;
div{
font-size:12px;
margin-left:auto;
padding-right:10px;
}
@media(max-width:768px){
width:80%;
height:40%;
margin:0 auto;
text-align:center;
justify-content:center;
align-items:center;
flex-flow:row;
div{
font-size:15px;
margin-left:0;
}
}
`;


const OpenNav = styled.div`
position:absolute;
background:#fff;
height:100vh; 
width:100%; 
display:flex;
flex-flow:column;
z-index:9999999;
`;


const NavBody = styled.div`
margin-left:auto;
width:100%;
height:30px;
`;

export default Header