import React, { useEffect, useState } from 'react'
import { RiArrowDropUpFill, RiCloseLine, RiLoginCircleLine, RiRemoteControl2Fill } from 'react-icons/ri'
import styled from 'styled-components'
import {TabsContain} from '../Hover/Css'
import {Body, ContainC} from '../Reuse_ui/Css'
import {RiArrowDropDownFill} from 'react-icons/ri'
import { useNavigate } from "react-router-dom";
import { List, List2, List3 } from '../actions_api'
import { remove_white_space } from '../Reuse_ui/Logic'





const Nav = (props) => {

  let history = useNavigate();
  const [open ,setOpen] = useState({enum:false,index:0});


  function IsOpen(v){
    open.enum ? setOpen({enum:false,index:0}) : setOpen({enum:true,index:v})
  }



  const push = (data) => {
    history((data === 'home') ? '/' : '/'+remove_white_space(data))
  }





  return (
    <Body w={"100%"} h={"100vh"} b={'#2D3476'} style={{zIndex:"99999999999999"}}>
        <ContainC>
          <Rows f={'35px'}>
            <RiCloseLine onClick={props.fun}/>
          </Rows> 
      
           <Rows f={'15px'} onClick={(e) => push('Beeppoint+education')}>
            Beep Education
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('Beeppoint+access')}>
              Beep Access
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('Beeppoint+reward')}>
             Beep Rewards
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('Beeppoint+Park')}>
             Beep Park
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('Beeppoint+import')}>
            Beep Import
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('payouts')}>
              Payouts
           </Rows>


           <Rows f={'15px'} onClick={(e) => push('Users')}>
              Users
           </Rows>

           <Rows f={'15px'} onClick={(e) => push('login1')}>
             LOGIN &nbsp;<RiLoginCircleLine/>
           </Rows>

        </ContainC>
    </Body>
  )
}


const Rows = styled.div`
width:auto;
color:#fff;
display:flex;
padding:20px;
margin-left:auto;
justify-content:center;
align-items:center;
background-color:#2D3476;
font-size:${(props) => props.f};
`;

const Contain = styled.div`
width:80%;
right:5px;
color:#fff;
height:auto;
padding:15px;
margin-top:190px;
position:absolute;
align-items:center;
text-align:left;
border: 1px solid #fff;
background:#2D3476;
border-radius:10px;
`;



const Tab = styled.div`
margin-top:10px;
`;


export default Nav