import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {Oval} from 'react-loader-spinner'
import {CenterElement, Center} from '../Reuse_ui/Css';
import Button from 'react-bootstrap/Button';
import { Message} from '../Reuse_ui/Logic';
import SubListMembers from './SubListMembers';
import {Scroll } from './UserModel';
import {SubListUsers} from '../actions_api'


const SubListModel = (props) => {



    const [progress, setprogress] = useState("");
    const [phoneEmail ,setPhoneEmail] = useState("");
    const [singleName,setSingleName] = useState("");
    const [scivList, setScIvList] = useState([]);
    const [screenSize, setScreenSize] = useState(0);
    const [currrentSelectedated, setCurrrentSelectedated] = useState(new Date().toLocaleDateString());






         
 const QuerySubList = async () => {
  
   setScIvList( await SubListUsers(props.ud));

} 





  useEffect(() => {
   if(props.ud !== undefined || props.ud !== null)
         QuerySubList();
  },[])

  


    return(<Container>
                 <Content>
                    <Header>
                      <h2>{"Sub List members"}</h2>
                      <Button  onClick={(e) => props.fun(e,1)}>X</Button>
                     </Header>
                      {
                        props.list !== null ?
                         <CenterElement>
                             <Scroll>
                                {scivList.map((v,i) =>  <SubListMembers i={props.list} v={v} k={i} /> )}
                             </Scroll>
                          </CenterElement>
                          :
                        <Center>
                         <Oval color={"#2D3476"}  height={40} width={40}/>
                        </Center> 
                    }
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:1009;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
margin: 0 auto;
padding-bottom:20px;
top:32px;
h2{
font-size:12px;
}


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;





const  mapStateToProps = (state)  => {
    return {};
};


const mapDistpachToProps = (dispatch) => ({
    
});


export default  connect(mapStateToProps,mapDistpachToProps)(SubListModel);




    