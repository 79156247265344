import React, { useEffect } from 'react';
import Hero_gif from './Hero_gif';
import styled from 'styled-components';
import Scroll from './Scroll';
import HowTo from './HowTo';
import Readmore from './Readmore'
import { Body } from '../Reuse_ui/Css';
import Footer from './Footer'; 
import Question from './Question';
import Header from './Header';


 

const  HomePage = (props) => {


  return (
    <Body  w={"100%"} h={"50vh"}   mh={null} bck_img={null}  c={null}>
      <Header/>
      <Hero_gif/>   
      <Scroll/>   
      <HowTo/>
      <Readmore/>
      <Question/>
      <Footer/>
    </Body>
  )
}





export default HomePage