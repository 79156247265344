import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {RiArrowDropDownFill, RiArrowDropUpFill, RiArrowUpFill, RiMenu2Line, RiSearch2Line} from 'react-icons/ri'
import Navigating from './Nav'
import {Dropmodel} from '../Reuse_ui/Widget'
import AnimateHeight from 'react-animate-height';
import {remove_white_space } from '../Reuse_ui/Logic'
import {useNavigate } from 'react-router-dom';
import {RedirectToLogin} from '../actions_api';


const StaffHeader = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [height, setheight] = useState(0);
  const [hold,setHold] = useState(false);
  const navigate = useNavigate();


  const open = () => {
      window.scrollTo(0,0);
    if(isOpen)
      setIsOpen(false);
    else
       setIsOpen(true);
  }


  const push = (data) => {
    window.scrollTo(0,0);
    navigate(`/${data}`)
  }




  useEffect(() => {
      setheight(120);
      RedirectToLogin(navigate);

  },[])




  return (
    <>
       <Container>
        <AnimateHeight height={height}  duration={500}>
          <Box w={"100%"} h={"100%"}  t={"column"}>
            <Logo onClick={(e) => window.location.href ='/staff'}>
              Staff Portal
            </Logo>
            <Nav>
              <TopNav1>
                   <h5 onClick={(e) => push('stafflogin')}>LOGIN</h5>
              </TopNav1>
              <TopNav2>
                <nav onClick={(e) => push('staff')}>Home</nav>
                <nav onClick={(e) => push('convart/0')}>Users</nav>
                <nav onClick={(e) => push('convart/1')}>Convert</nav>
                <InputIcon>
                  <input/><RiSearch2Line size={25}/>
                </InputIcon>
              </TopNav2>
             </Nav>
          </Box>
      </AnimateHeight> 
    </Container>
    </>
  )
}


const Container = styled.div`
width:100%;
position:fixed;
background:#2D3476;
margin-top:-5px;
`;



const Box = styled.div`
display:flex;
flex-direction:row;
width:100%;
height:100%;
`;   


const Logo = styled.div`
height:100%;
width:20%;
padding-top:10px;
padding-left:30px;
font-size:25px;
font-weight:800;
color:#ffffff;
`;


const Nav = styled.div`
height:100%;
width:80%;
display:flex;
flex-direction:column;
@media(max-width:700px){
display:none;
}
`;



const TopNav1 = styled.div`
height:30%;
width:20%;
display:flex;
margin-left:auto;
margin-top:15px;
flex-direction:row;
margin-right:30px;
justify-content: space-between;
color:#fff;
h5{
margin-left:auto;
margin-right:20px;
font-size:12px;
}
`;


const TopNav2 = styled.div`
height:20px;
width:90%;
display:flex;
color:#ffffff;
margin-top:20px;
margin-left:auto;
margin-right:30px;
flex-direction:row;
justify-content: space-around;
position:relative;
z-index:999;
nav{
font-size:12px;
height:15px;
cursor:pointer;
display:flex;
justify-content: center;
align-items:center;
text-align:center;
}
`;


const Drops = styled.div`
position:absolute;
height:auto;
width:auto;
background:#fff;
margin-top:90px;
display:flex;
font-size:12px;
padding:10px;
justify-content:center;
margin-left:${(props) => props.lf};
table{
cursor:pointer;
}
`;



const InputIcon = styled.div`
display:flex;
justify-content: center;
align-items:center;
text-align:center;
input{
margin:5px;
font-weight:800;
height:20px;

}

`;

export default StaffHeader