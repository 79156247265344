import {react, useState, useEffect} from 'react'
import {Body,ContainList,Center,LeftTabs,RightTab, EstateBanner, ContainR} from "../Reuse_ui/Css";
import OnbardImport from './OnbardImport';
import Header from './Header';
import Footer from '../Components/Footer'
import {ImporterAddToList, ListImporter, requestSessionToken} from '../actions_api'
import styled from 'styled-components';
import ScanModel from '../Model/ScanModel'
import Marchant from './Marchant'
import { Oval } from 'react-loader-spinner'
import { ButtonIcon } from '../Reuse_ui/Widget';
import { RiArrowGoForwardFill, RiBoxingFill, RiCloseCircleFill, RiDeleteBack2Fill } from 'react-icons/ri';



const Importer = () => {

    const [list, setList] = useState([]);
    const [flow, setFlow] = useState(null);
    const [showModel, setShowModel] = useState("close");
    const [nodeList, setNodeList] = useState([]);
    const [shareImage, setShareImage] = useState("");
    const [fileN, setfileN] = useState("");
    const [description, setdescription] = useState("");
    const [price, setPrice] = useState();
    const [node, setNode] = useState({});



    const OpenModel = (v) => {
          switch(showModel){
               case "open":
                     setShowModel("close");
                     break;
               case "close":
                     setShowModel("open");
                     break;
               default:
                    setShowModel("close");
                    break;
          }
        }



    async function Session (){
        setList(await ListImporter());
      }




      const OpenDiv = (v,i) => {
            setNode(v);
            i == 0 ? setFlow(true) : setFlow(false);
       }





      const handle = (e) => {
        const file = e.target.files[0];
        
        if(file === '' || file === undefined){
            alert('The file is  a  ${typeof image}');
            return;
        }
        if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"  || file.type === "image/webp"){
            setShareImage(file);
             setfileN(file.name + Date.now());
         }
    }



    
    const Open = async (i) => {
       
        if(i == 1){

        }else
           if(i == 2){

        }else
           if(i == 3){

        }else
           if(i == 4){
               let wait = await ImporterAddToList([description,null,node.company_email,node.company_uid,fileN,shareImage,price])
           }
     }


      useEffect(() => {
        Session();
      },[])



    return (
        <>
            <Header/>
              <Body w={'100%'} h={"auto"}  mh={"220vh"} style={{background:"#f5f5f5"}}>
            
               <OnbardImport/>

               {list.length > 0 ?

                    <ContainList>

                        <LeftTabs>
                            {list.map((v,i) => <Marchant v={v} i={i} open={OpenDiv} />)}
                        </LeftTabs>

                        <RightTab h={"80vh"}  mh={"100vh"} mt={"0%"} mtm={"0%"}>
                            {flow ? 
                            <EstateBanner>
                                    <input type="file"   name="image" id="file" style={{display: "none"}}  onChange={handle}  accept="image/png, image/gif, image/jpeg, image/jpg"/>
                                        {shareImage && <img style={{marginTop:"0px"}} src={URL.createObjectURL(shareImage) }/>}
                                
                                        <br/>
                                    <p><label  htmlFor="file">{"Choose  banner + "}</label></p>
                                    <br/>
                                    <input  placeholder='Description' value={description} onChange={(e) => setdescription(e.target.value)} type="text" />
                                    <input  placeholder='Price' value={price} onChange={(e) => setPrice(e.target.value)} type="number"/>
                                    <ContainR>
                                            <div style={{margin:"0 auto"}}  onClick={(e) => Open(1)}> 
                                                <ButtonIcon font={"9px"} icon={<RiCloseCircleFill/>}  size={"auto"}  text={"Disable"} h={"30px"} />
                                            </div>
                                            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(2)}>
                                                <ButtonIcon font={"9px"}  icon={<RiDeleteBack2Fill/>}  size={"auto"}  text={"Delete"} h={"30px"}/>
                                            </div>
                                            <div  style={{margin:"0 auto"}}  onClick={(e) => Open(4)}>
                                                <ButtonIcon font={"9px"}  icon={<RiArrowGoForwardFill/>}  size={"auto"}  text={"Send"} h={"30px"}/>
                                            </div>
                                    </ContainR>
                            </EstateBanner>
                                :
                            <Center>
                                <Oval color={"#2D3476"}  height={40} width={40}/>
                            </Center>
                            } 
                        </RightTab>

                        {showModel === "open" ? <ScanModel  list={nodeList} fun={OpenModel} ui={1}/> : null}
                        
                    </ContainList>
                    :
                    <Center>
                      <Oval color={"#2D3476"}  height={40} width={40}/>
                    </Center>
                }
            </Body>
        </>
    )
}




export default  Importer;