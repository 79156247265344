import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Dropmodel} from '../Reuse_ui/Widget'
import AnimateHeight from 'react-animate-height';
import { remove_white_space } from '../Reuse_ui/Logic'
import { useNavigate } from 'react-router-dom';
import {request} from '../actions_api';
import {RiAddBoxLine, RiAddCircleLine, RiArrowDropDownFill, RiArrowDropUpFill, RiArrowUpFill, RiRegisteredFill, RiDeleteBin5Fill, RiEdit2Line, RiMenu2Line, RiNotification2Line, RiScan2Line, RiSearch2Line, RiTerminalFill, RiTerminalWindowFill, RiGpsLine, RiRedPacketFill} from 'react-icons/ri'
import Cdraw from '../Admins/Cdraw';




const Cheader = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [left, setleft] = useState(false);
  const [showModel, setshowModel] = useState(false);
  const [height, setheight] = useState(0);
  const [hold,setHold] = useState(false);
  const navigate = useNavigate();





  const open = () => {
    window.scrollTo(0,0);
    if(isOpen)
      setIsOpen(false);
    else
       setIsOpen(true);
  }


  const push = (data) => {
    navigate((data === 'home') ? '/' : '/'+remove_white_space(data))
  }


  const handleOpen = (show,left) => {
    setshowModel(show);
    setleft(left);
  }


  const LoadUp = async () => {
    if(await  request(0))
       setHold(true)
  }


  useEffect(() => {
      setheight(120);
      LoadUp();
  },[])




  return (
    <>
     {isOpen ? <Cdraw fun={open} hold={hold}/> : ""}
       <Container>
        <AnimateHeight height={height}  duration={500}>
          <Box w={"100%"} h={"100%"}  t={"column"}>
            <Logo onClick={(e) => push('home')}>
               BeePPoint Portal

            </Logo>
            <Nav>
              <TopNav1>
                    <h5>Help & Support</h5> <h5 onClick={(e) => push('login')}>LOGIN</h5>
              </TopNav1>
              <TopNav2>
                  {/* <nav onClick={(e) => push('cpanel/0')}>Users &nbsp; <RiRegisteredFill size={12} /></nav> */}
                  <nav onClick={(e) => push('cpanel/1')}>Scans &nbsp; <RiScan2Line size={12} /></nav>
                  <nav onClick={(e) => push('cpanel/2')}>Notice &nbsp; {<RiNotification2Line size={15}/>}</nav>
                  <nav onClick={(e) => push('cpanel/3')}>Terminals &nbsp; {<RiTerminalWindowFill size={15}/>}</nav>
                  <nav onClick={(e) => push('Update')}>Update &nbsp; {<RiGpsLine size={15}/>}</nav>
                <InputIcon>
                  <input/><RiSearch2Line size={25}/>
                </InputIcon>
              </TopNav2>
            </Nav>

            <DrawerIcon onClick={(e) => open()}>
              <RiMenu2Line  size={25} color={"#ffffff"}/>
            </DrawerIcon>
          </Box>
      </AnimateHeight> 
    </Container>
    </>
  )
}


const Container = styled.div`
width:100%;
position:fixed;
background:#2D3476;
z-index:999;
`;


const  Center = styled.div`
position:fixed;
width:60%;
height:200px;
display:flex;
right:0;
`;


const Box = styled.div`
display:flex;
flex-direction:row;
width:100%;
height:100%;
`;   



const Logo = styled.div`
height:100%;
width:20%;
padding-top:10px;
padding-left:30px;
font-weight:800;
color:#ffffff;
font-size:25px;
@media(max-width:768px){
font-size:20px;
}
`;


const Nav = styled.div`
height:100%;
width:80%;
display:flex;
flex-direction:column;
@media(max-width:1100px){
display:none;
}
`;


const DrawerIcon = styled.div`
margin-left:auto;
display:none;
padding:10px;
@media(max-width:1100px){
display:block;
}
`;


const TopNav1 = styled.div`
height:30%;
width:20%;
display:flex;
margin-left:auto;
margin-top:15px;
flex-direction:row;
margin-right:30px;
justify-content: space-between;
color:#fff;
h5{
margin-right:10px;
font-size:12px;
}
`;


const TopNav2 = styled.div`
height:20px;
width:90%;
display:flex;
color:#ffffff;
margin-top:20px;
margin-left:auto;
margin-right:30px;
flex-direction:row;
justify-content: space-around;
position:relative;
z-index:999;
nav{
font-size:12px;
height:15px;
cursor:pointer;
display:flex;
justify-content: center;
align-items:center;
text-align:center;
}
`;




const InputIcon = styled.div`
display:flex;
justify-content: center;
align-items:center;
text-align:center;
input{
margin:5px;
font-weight:800;
height:20px;

}

`;




export default Cheader