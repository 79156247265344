import React, {useState, useEffect, useCallback, useRef }from 'react'
import {Oval} from 'react-loader-spinner'
import { RiAddLine,RiCamera2Fill,RiStopFill, RiTerminalBoxFill} from 'react-icons/ri';
import {ButtonIcon} from '../Reuse_ui/Widget'
import {Body,PackTogether,LeftTabs,RightTab,EstateBanner,WriteUps,ContainR,Center,Texts} from '../Reuse_ui/Css'
import Terminalmodel from '../Model/Tmodel';
import TerminalDisable from '../Model/Dmodel';
import LiveSteam from '../Model/LiveSteam';
import {disableCurrentTerminals} from '../actions_api/index'
import { Add_session, Get_session, Message } from '../Reuse_ui/Logic';
import Button from 'react-bootstrap/Button';

const Terminaltable = ({list,acs}) => {

  const [showModel, setShowModel] = useState("");
  const [showLive, setShowLive] = useState("");
  const [ui, setUi] = useState("");
  const [id, setid] = useState("");
  const [status, setstatus] = useState("");
  const leftScroll = useRef();

  const OpenModel =  async (e,ui,id,status) => {
    if(ui !== 2){
      setUi(ui);
      setid(id);
      setstatus(status);

      e.preventDefault();
      switch(showModel){
          case "open":
                setShowModel("close");
                break;
          case "close":
                setShowModel("open");
                break;
          default:
               setShowModel("close");
               break;
      }

    }else
       {
        let res = await disableCurrentTerminals(sessionStorage);
            if(res.success)
                Message("Info",res.data,"info");
            else
               Message("Info","Error occurred.","info");

       }  
  }




  const OpenLive = (e,id) => {
    e.preventDefault();
    setid(id);
    switch(showLive){
        case "open":
               setShowLive("close");
              break;
        case "close":
              setShowLive("open");
              break;
        default:
              setShowLive("close");
             break;
    }

  }




  const onScroll = useCallback((event) => {
    Add_session("TS",sessionStorage,event.target.scrollTop);
}, []);


  useEffect(() => {
   let cn = Get_session("TS",sessionStorage);
    if(leftScroll.current !== null){
       leftScroll.current.scrollTo(0,cn);
        leftScroll.current.addEventListener('scroll', onScroll);
    }
  },[]);




  return ( list.length > 0 ? 
          <Body w={'100%'} h={'auto'} mh={"auto"} style={{background:"#f5f5f5"}}>
              <PackTogether>
                  <LeftTabs ref={leftScroll} onScroll={(e) => onScroll(e)}>
                     {list.map((v,i) => <Terminalmodel i={i}  v={v} live={OpenLive}  open={OpenModel} i1={<RiTerminalBoxFill  size={20}  color="#FF9900"/>}  i2={<RiCamera2Fill size={20}  color="#FF9900"/>}  />)}  
                  </LeftTabs>

                  {showModel === "open" ? <TerminalDisable  ui={ui}  id={id}  status={status} fun={OpenModel}  /> : null}
                   {showLive === "open" ? <LiveSteam   id={id}  fun={OpenLive}  /> : null}

                  <RightTab h={"60vh"}  mh={"50vh"}>
                      <Texts>
                       {list[0] !== undefined ? "Depolyed Terminal Count: "+list[0].Assigned.terminal_count : null}
                      </Texts>
                      <Texts>
                        { list[0] !== undefined ? "Terminal registered plan: "+ list[0].Configure.plan : null}
                      </Texts> 
                      <Texts>
                        { list[0] !== undefined ? "Email: "+ list[0].Assigned.company_email : null}
                      </Texts>
                      <Texts>
                        { list[0] !== undefined ? "Phone: "+ list[0].Assigned.company_phone : null}
                      </Texts>
                      <Texts>
                        {acs ? "Registerd account: "+ acs.length : 0}
                      </Texts>
                     <div  onClick={(e) => OpenModel(e,2,null)}>
                         <Button><RiAddLine/> Disable All</Button> 
                     </div>
                 </RightTab>
              </PackTogether>
          </Body>
           : 
         <Center>
            <Oval color={"#2D3476"}  height={40} width={40}/>
         </Center>     
  )
}

export default Terminaltable