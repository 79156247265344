import React, { useEffect,useState} from 'react'
import { Ri24HoursFill, RiGpsFill, RiPhoneFill, RiRestaurant2Fill } from 'react-icons/ri'
import styled from 'styled-components'
import { Body } from '../Reuse_ui/Css'
import {Space} from '../Reuse_ui/Css'
import { Button, ButtonIcon,ButtonWidth} from '../Reuse_ui/Widget'
import Footer from '../Components/Footer'
import Header from '../Components/Header'


const TermsCondition = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleCheck() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
    },[])

  return <>
         <Header/>
          <Body w={'100%'} h={'auto'}  mh={"auto"}  style={{background:"#f5f5f5"}}>
            <Space/>
                <Container>
                    
                </Container>
            </Body>
            <Footer/>
        </>
}


const Container = styled.div`
width:100%;
height:auto;
display:flex;
flex-flow:column;
margin-left:auto;
margin-right:auto;
justify-content:center;
font-family: "Poppins", sans-serif;
@media(max-width:900px){
width:100%;
margin-bottom:100px;
}
`;



export default TermsCondition