import React  from 'react'
import { Body } from '../Reuse_ui/Css'
import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'

function GiftCard() {

  const param = useParams(); 


  return (
    <Body w={"100%"} h={"100vh"} mh={'70vh'}  z={'1.99999'} b={'#f5f5f5'}>
        <Header/>
            <PageView>

            </PageView>
        <Footer/>
    </Body>
  )
}





const PageView = styled.div`
width:60%;
height:400px; 
margin-left:auto;
margin-right:auto;
padding-top:100px;
background:#ffffff; 
border-radius:20px;
display:flex; 
flex-flow:column;
justify-content:center;
align-items:center;
text-align:center;
@media(max-width:980px){
width:90%;
}
`;



export default GiftCard