import React from 'react'
import { Body, Texts } from "../Reuse_ui/Css"
import Cheader from './Cheader'
import styled from 'styled-components'
import Footer from '../Components/Footer'
import { ButtonIcon } from '../Reuse_ui/Widget'
import { RiGpsLine } from 'react-icons/ri'
import { FindLocation } from '../actions_api'
import { Get_session, Message } from '../Reuse_ui/Logic'
import Button from 'react-bootstrap/Button'



const Update = () => {


    const SendOut = async () => {
        await FindLocation(Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage)[0].User);
    }


    return (
        <>
            <Body w={"100%"} h={"100vh"} mh={'100vh'}  z={'1.99999'} b={'#f5f5f5'}>
             <Cheader/>
                <PageView>
                    <Texts>Please always ensure you are at your Business location before button is clicked.</Texts>
                    <div onClick={(e) => SendOut()}>
                      <Button><RiGpsLine /> Update Business Location</Button>
                    </div>
                </PageView>
            </Body>
            <Footer/>
       </>
    )
}



const PageView = styled.div`
width:60%;
height:400px; 
margin-left:auto;
margin-right:auto;
padding-top:100px;
background:#ffffff; 
border-radius:20px;
display:flex; 
flex-flow:column;
justify-content:center;
align-items:center;
text-align:center;
@media(max-width:980px){
width:90%;
}
`;


export default Update;