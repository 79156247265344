import React,{useState,useEffect} from 'react'
import styled from "styled-components"
import Header from "./Header";
import Footer from "../Components/Footer";
import UserTab from './UserTab'
import PointsModel from '../Model/PointsModel'
import UserSelf from './UserSelf'
import { Oval } from 'react-loader-spinner'
import {ListUsers} from '../actions_api'
import Onbardmechant from './Onbard'
import SubListModel from '../Model/SubListModel';
import {Body,Center, ContainList, LeftTabs, RightTab} from '../Reuse_ui/Css'




const Accounts = ()  => {


const [list, setList] = useState([]);  
const [node, setNode] = useState([]);
const [flow, setFlow] = useState(null);
const [showModel, setShowModel] = useState("close");  


const OpenDiv = (v,i) => {
        if(i === 0)
           setFlow(v);
        else
          OpenModel(v);

}
    
    
    
const OpenModel = (v) => {
        setNode(v.uid === undefined ?  v.email : v.uid);
        switch(showModel){
                case "open":
                        setShowModel("close");
                        break;
                case "close":
                        setShowModel("open");
                        break;
                default:
                setShowModel("close");
                break;
        }
}
        
    
    
    
async function CallUser () {
  setList(await ListUsers());
}
    
    
useEffect(() => {
   CallUser(); 
},[])



        return ( 
                <>
                        <Header/>
                                <Body w={'100%'} h={'auto'}  mh={"auto"} style={{background:"#f5f5f5"}}>
                                 <Onbardmechant/>
                                        {list.length > 0 ?
                        
                                                        <ContainList>
                        
                                                                <LeftTabs>
                                                                  {list.map((v,i) => <UserSelf v={v} i={i} open={OpenDiv} />)}
                                                                 </LeftTabs>
                        
                                                                <RightTab h={"120vh"}  mh={"100vh"} mt={"40%"} mtm={"90%"}>
                                                                        {flow
                                                                           ? 
                                                                          <UserTab data={flow} /> 
                                                                            :
                                                                        <Center>
                                                                           <Oval color={"#2D3476"}  height={40} width={40}/>
                                                                        </Center>
                                                                        } 
                                                                </RightTab>
                        
                                                                {showModel === "open" ? <SubListModel  ud={node} fun={OpenModel} ui={1}/> : null}
                                                        
                                                        </ContainList>
                                                :
                        
                                                <Center>
                                                  <Oval color={"#2D3476"}  height={40} width={40}/>
                                                </Center>
                        
                                        }
                        </Body>
                  <Footer/>
              </>

        )
}





export default Accounts;