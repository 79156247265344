import React,{useEffect,useState} from 'react';
import  styled from 'styled-components'
import {Body,ContainR} from '../Reuse_ui/Css'
import {IMAGE,ButtonIcon} from '../Reuse_ui/Widget'
import {useNavigate} from 'react-router-dom';
import { RiArrowRightFill } from 'react-icons/ri';
import  Button from 'react-bootstrap/Button';
import "aos/dist/aos.css";
import AOS from 'aos';
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';



const Hero_gif = () => {

const [width, setWidth] = useState(window.innerWidth);
const navigate = useNavigate();




function handleCheck() {
    setWidth(window.innerWidth);
}
    


useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
}, [])





function navigating(){
    window.scrollTo(0,0);
    navigate('/business');
  }


 

    return (
        <Body w={"100%"} h={"140vh"} mh={"90vh"} bck_img={null}  c={'#f5f5f5'}>
            {width > 768 ?
            <ContainR>
                <LeftContain>
                 <h5>Earn and spend with beeppoint multiverse card. </h5>
                 <br/>
                 <div onClick={(e) => navigating()}>
                    <Button>Learn more</Button>
                 </div>

                 <AppLink>
                    <AdvancedImage  alt="playstore app link" cldImg={cld.image('beeppoint/ASSETS/play_tkvqjr')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                    <AdvancedImage alt='applestore app link' cldImg={cld.image('beeppoint/ASSETS/apple_kvvb9m')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                 </AppLink>

                </LeftContain>
                <Rightdiv>
                    <div data-aos={"fade-down-right"}>
                       <AdvancedImage id="m1"  cldImg={cld.image('beeppoint/ASSETS/newBounce_moqzxj')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                    </div>
                    <div  data-aos={"fade-down-left"}>    
                        <AdvancedImage id="m2"  cldImg={cld.image('beeppoint/ASSETS/service_klglbj')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                    </div> 
                </Rightdiv>
            </ContainR> 
            :
            <Mobile>
                <LeftContain>
                 <h5>Earn and spend  <br/> with Beeppoint  <br/> multiverse card. </h5>
                   <br/><br/> <br/><br/><br/><br/>
                  <div onClick={(e) => navigating()}>
                    <Button>Learn more</Button>
                  </div>
                </LeftContain>

                <Rightdiv>                   
                  <AdvancedImage id="m3"  cldImg={cld.image('beeppoint/ASSETS/Mobile1_uv6fkq')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                </Rightdiv>

            </Mobile>
            }
        </Body>
    )
}




const LeftContain = styled.div`
width:100%;
margin-top:70px;
h5{
font-weight:900;
font-size:30pt;
width:120%;
margin-top:100px;
color:#2D3476;
font-family: "Poppins", sans-serif;
}
@media(max-width:768px){
width:100%;
margin-left:0px;
margin-top:5px;
display:flex; 
flex-flow:column;
justify-content:center;
align-items:center;
text-align:center;
h5{
margin-top:0px;
font-size:20pt;
position:absolute;
margin-bottom:20px;
width:100%;
}
}
`;


const AppLink = styled.div`
display:flex; 
flex-flow:row; 
align-items:center;
text-align:center;

img{
width:150px;
height:150px;
object-fit:contain;
cursor: pointer;
}
`;

const Rightdiv = styled.div`
width:70%;
display:flex;
flex-flow:row;
margin-top:90px;
justify-content: center;

#m1{
height: 700px;
animation: bounce 3.5s;
animation-direction: alternate;
animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
animation-iteration-count: infinite;
}


#m2{
height:600px;
}


@keyframes bounce {
from {
transform: translate3d(0, 0, 0);
}
to {
transform: translate3d(0, 100px, 0);
}
}
#m1{
-webkit-animation-name: bounce;
-webkit-animation-duration: 3.5s;
-webkit-animation-direction: alternate;
-webkit-animation-timing-function: cubic-bezier(
.5, 0.05, 1, .5);
-webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
from {
-webkit-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}
to {
-webkit-transform: translate3d(0, 200px, 0);
transform: translate3d(0, 200px, 0);
}
}
@media(max-width:768px){
width:100%;
margin-top:10px;


#m1{
height:140px;
object-fit:contain;
}

#m2{
height:140px;
object-fit:contain;
}

#m3{
height:400px; 
width:90%;
margin: 0 auto;
object-fit:contain;
}

#m3{
-webkit-animation-name: bounce;
-webkit-animation-duration: 3.5s;
-webkit-animation-direction: alternate;
-webkit-animation-timing-function: cubic-bezier(
.5, 0.05, 1, .5);
-webkit-animation-iteration-count: infinite;
}

}

`;


const Mobile = styled.div`
display:flex;
flex-flow:column;
`;



export  default Hero_gif