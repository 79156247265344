import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import {connect} from "react-redux";
import {v4 as uuid4} from 'uuid'
import { RiAddFill, RiCamera2Line, RiCloseLine, RiShareCircleLine, RiAddLine, RiUpload2Fill, RiGpsLine } from 'react-icons/ri';
import { ContainC, ContainR, WriteUps ,CenterElement, Btn} from '../Reuse_ui/Css';
import { ButtonIcon } from '../Reuse_ui/Widget';
import { Message } from '../Reuse_ui/Logic';
import {Oval} from 'react-loader-spinner'
import {ResetAccount} from '../actions_api'
import { useNavigate } from 'react-router-dom';




const Location = (props) => {

    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
    const [msg, setMsg] = useState("Activate");
    const [showloader, setshowloader] = useState(false);
    const navigate = useNavigate();


    const gprs = async (e) => {
            navigator.geolocation.getCurrentPosition( 
               async  function (position) {
                  let res = await  ResetAccount(position.coords.latitude,position.coords.longitude,props.payload);
                  if(res.message.includes("link")){
                    Message("Info",res.message,"info")
                     setshowloader(true);
                       navigate("/login")
                  }else
                    Message("Info",res.message,"info")
                },
                async function (error){
                if(error.PERMISSION_DENIED){
                     setMsg("Denied and activate account");
                     let res = await   ResetAccount(lat,long,props.payload);
                     if(res.message.includes("link")){
                        Message("Info",res.message,"info")
                        setshowloader(true);
                            navigate("/login")
                     }else
                       Message("Info",res.message,"info")
                   }
                }
            )
        }
      



        useEffect(() => {
            navigator.geolocation.getCurrentPosition( 
                function(position) { },
                function (error){
                if(error.PERMISSION_DENIED){
                     setMsg("Deny and activate account");
                   }
                }
            )
            console.log(props.payload)
        },[])




    return (<Container>
                 <Content>
                    <Header>
                    <h2/>
                    <button  onClick={(e) => props.fun(e)}>X</button>
                    </Header>

                    <CenterElement>
                        <h5>Beeppoint needs to access your location so as to help potential customer locate your business on google map</h5>
                        <div  onClick={(e) => gprs(msg)}>
                            {showloader ?
                             <Btn><Oval color={"#fff"}  height={20} width={20}/></Btn>
                                :
                            <ButtonIcon icon={<RiGpsLine/>}  size={"auto"}  text={msg} h={"30px"}/>
                            }
                        </div>
                     </CenterElement>
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:999;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
top:30%;
margin: 0 auto;
padding-bottom:20px;
@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
@media(max-width:768px){
h2{
width:60%;
font-size:10px;
}
}
`;



const SharedContent = styled.div`
display:flex;
flex-direction: column;
flex-grow:1;
overflow-y:auto;
vertical-align: baseline;
background: transparent;
padding: 8px 12px;
::-webkit-scrollbar {
display: none;
}
`;



const UserInfo = styled.div`
display: flex;
align-items: center;
flex-flow:column;
padding: 12px 24px;
svg,img{
width:48px;
height:48px;
background-clip: content-box;
border:2px solid  transparent;
border-radius:50%;
padding:5px;
}
span{
font-weight:600;
font-size:12px;
}
`;



const ShareCreation = styled.div`
display:flex;
justify-content: space-between;
padding: 10px;
`;




const PostButton = styled.button`
min-width:60px;
border-radius: 20px;
height: 35px;
right:2px;
display:flex;
justify-content:center;
align-items:center;
background: ${(props) => (props.disabled ?  "rgba(0,0,0,0.8)" : "#0a66c2")};
color: ${(props) => (props.disabled ? "rgba(1,1,1,0.2)": "white")};
&:hover{
background: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#004162")};
}
`;




const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({});

export default  connect(mapStateToProps,mapDistpachToProps)(Location);


