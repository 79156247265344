import React, { useEffect, useState } from 'react'
import Header from './Header'
import Nodes from './Nodes'
import Accounts from './Accounts';
import Footer from '../Components/Footer'
import { useParams } from 'react-router-dom'
import Payouts from './Payouts';



const  Index = ({n}) => {

  return (<div>
         {n === 0 ? <Nodes/> : n === 2 ? <Payouts/> : <Accounts/> }
    </div>
  )
}

export default Index