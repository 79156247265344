import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Body, ContainC} from '../Reuse_ui/Css'
import { useNavigate } from "react-router-dom";
import {request} from '../actions_api';
import { RiCloseLine, RiLoginCircleLine,RiGpsLine, RiScan2Line, RiCloseCircleLine,RiArrowDropDownFill,RiAddCircleLine,RiNotification2Line,RiTerminalWindowFill,RiRemoteControl2Fill, RiEdit2Line, RiRegisteredFill} from 'react-icons/ri'




const Cdraw = (props) => {

  let history = useNavigate();

  const push = (data) => {
    history((data === 'home') ? '/' : '/'+data);
  }

  return (
    <Body w={"100%"} h={"100vh"} z={'1.99999'} b={'#2D3476'}>
        <ContainC>
         <Rows f={'35px'}>
            <RiCloseLine onClick={props.fun}/>
          </Rows> 

          {/* <Rows f={'15px'} onClick={(e) => {push('cpanel/0'); props.fun()}}>
              Users &nbsp; <RiRegisteredFill size={12} />
           </Rows> */}

           <Rows f={'15px'} onClick={(e) =>{push('cpanel/1'); props.fun()}}>
              Scans &nbsp; <RiScan2Line size={12} />
           </Rows>

           <Rows f={'15px'} onClick={(e) =>{push('cpanel/2'); props.fun()}}>
             Notice &nbsp; {<RiNotification2Line size={15}/>}
           </Rows>

           <Rows f={'15px'} onClick={(e) =>{push('cpanel/3'); props.fun()}}>
             Terminals &nbsp; {<RiTerminalWindowFill size={15}/>}
           </Rows>


           <Rows f={'15px'} onClick={(e) =>{push('Update'); props.fun()}}>
             Update &nbsp; {<RiGpsLine size={15}/>}
           </Rows>

           <Rows f={'15px'} onClick={(e) =>{push('login'); props.fun()}}>
             LOGIN &nbsp;<RiLoginCircleLine/>
           </Rows>

        </ContainC>
     
    </Body>
  )
}


const Rows = styled.div`
width:auto;
color:#fff;
display:flex;
padding:20px;
margin-left:auto;
justify-content:center;
align-items:center;
font-size:${(props) => props.f};
`;


export default Cdraw;