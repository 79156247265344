import React,{useState} from 'react'
import { Body, WriteUpsText } from '../Reuse_ui/Css'
import { ContainC, ContainR, WriteUps ,CenterElement} from '../Reuse_ui/Css';
import {SendNotice} from '../actions_api'
import { RiAddLine, RiMailSendFill} from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

const NotifyTable = () => {


  const [title, setTitle] =  useState("");
  const [message, setMessage] =  useState("");
  const [userid, setUserId] =  useState("");

  
  return (
    <Body w={'100%'} h={'auto'}  nh={"auto"} style={{background:"#f5f5f5"}}>
      <Contain>
      <Center>
           <CenterElement> 
              <WriteUps placeholder={'Title'} value={title} onChange={(e) => setTitle(e.target.value)}/>
              <WriteUps placeholder={"User Id or family id (Optional) "} value={userid} onChange={(e) => setUserId(e.target.value)}/>
              <WriteUpsText  placeholder={'message'} value={message} onChange={(e) => setMessage(e.target.value)}/>
              <div  onClick={(e) => SendNotice(e)}>
                <Button><RiMailSendFill/> Send notification</Button> 
              </div>
            </CenterElement>
         </Center>
      </Contain>
    </Body>
  )
}


const Contain = styled.div`
width:70%;
height:100vh;
margin:0 auto;
padding-top:10%;
@media(max-width:768px){
width:95%;
padding-top:40%;
}
`;

export const Center = styled.div`
width:50%;
padding:5%;
margin-left:auto;
margin-right:auto;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
@media(max-width:768px){
width:95%;
padding-bottom:40px;
}
`;

export default NotifyTable