import React, {Component,useEffect,useState,useRef}  from 'react';
import styled from "styled-components";
import AWS from 'aws-sdk';
import {connect} from "react-redux";
import axios from 'axios';
import swal from 'sweetalert2'
import {v4 as uuid4} from 'uuid'
import { useNavigate } from 'react-router-dom';
import { RiAddFill, RiCamera2Line, RiCloseLine, RiShareCircleLine, RiAddLine, RiUpload2Fill } from 'react-icons/ri';
import { ContainC, ContainR, WriteUps ,CenterElement, Texts} from '../Reuse_ui/Css';
import { ButtonIcon } from '../Reuse_ui/Widget';
import {AddNewMemebr,disableTerminal} from '../actions_api'
import { Message } from '../Reuse_ui/Logic';
import Button from 'react-bootstrap/Button';

const TerminalDisable = (props) => {

    const [terminalId ,setTerminalID] = useState("");
    const [pass ,setPass] = useState("");
    const history = useNavigate();



    const Invite = (value) => {
        history('/invites/'+value)
    }

    const disable = async (e) => {
     let res = await  disableTerminal(terminalId,sessionStorage,props);
     if(res){
       let msg = props.status !== false ? "Enabled" : "Disabled"; 
         Message("Info",res.data + msg,"info");  
         window.location = "/cpanel/3"
     }
           
    }       


    useEffect(() => {
        setTerminalID(props.id);
        window.scrollTo(0,50); 
    },[])


    return(<Container>
                 <Content>
                    <Header>
                    <h2>Disable Terminal</h2>
                    <Button  onClick={(e) => props.fun(e)}>X</Button>
                    
                    </Header>

                    <CenterElement>
                      <Texts>Disable {props.ui === 2 ? "all" : ""}  terminal are you sure ?</Texts>
                        {props.ui !== 2 ?
                        <Texts>
                            {terminalId}
                        </Texts>    
                        : null }
                        <br/>
                        <div  onClick={(e) => disable(e)}>
                            <ButtonIcon icon={<RiAddLine/>}  size={"50px"}  text={ props.status === true ? "Enable": "Disable"} h={"30px"}/>
                        </div>
                     </CenterElement>
            </Content> 
        </Container>
        )

}






const Container = styled.div `
position:fixed;
top:0;
left:0;
bottom:0;
color:black;
width:100%;
z-index:999;
background-color: rgba(0,0,0,0.8);
`;



const Content  =  styled.div`
max-width:35%;
background-color: white;
max-height:90%;
overflow:initial;
border-radius: 5px;
position: relative;
display: flex;
flex-direction: column;
top:32px;
margin: 0 auto;
padding-bottom:20px;


@media(max-width: 768px){
top:10%;
max-width:100%;
}

`;




const Header  =  styled.div`
display:  block;
padding : 16px 20px;
border-bottom: 1px solid rgba(0,0,0,0.15);
font-size:16px;
line-height: 1.5;
color: rgba(0,0,0,0.6);
font-weight: 400;
display:flex;
justify-content: space-between;
align-items:center;
button{
height:40px;
width:40px;
min-width:auto;
}
`;



const SharedContent = styled.div`
display:flex;
flex-direction: column;
flex-grow:1;
overflow-y:auto;
vertical-align: baseline;
background: transparent;
padding: 8px 12px;

::-webkit-scrollbar {
display: none;
}
`;



const UserInfo = styled.div`
display: flex;
align-items: center;
flex-flow:column;
padding: 12px 24px;
svg,img{
width:48px;
height:48px;
background-clip: content-box;
border:2px solid  transparent;
border-radius:50%;
padding:5px;
}
span{
font-weight:600;
font-size:12px;
}
`;



const ShareCreation = styled.div`
display:flex;
justify-content: space-between;
padding: 10px;
`;






const PostButton = styled.button`
min-width:60px;
border-radius: 20px;
height: 35px;
right:2px;
display:flex;
justify-content:center;
align-items:center;
background: ${(props) => (props.disabled ?  "rgba(0,0,0,0.8)" : "#0a66c2")};
color: ${(props) => (props.disabled ? "rgba(1,1,1,0.2)": "white")};
&:hover{
background: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#004162")};
}
`;


const AGORA_SECTION = styled.div`
#me{
position: relative;
width: 50%;
margin: 0 auto;

}
#me video{
position: relative !important;
}
#remote-container{
display: flex;
}
#remote-container video{
height: 200px;
position: relative !important;
}

`;





const  mapStateToProps = (state)  => {
    return {
        user: state.userState.user,
    };
};

const mapDistpachToProps = (dispatch) => ({
    PostData: (payload) => dispatch(AddNewMemebr(payload)),
});

export default  connect(mapStateToProps,mapDistpachToProps)(TerminalDisable);


