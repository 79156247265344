import React,{ useEffect, useState } from 'react'
import {Oval} from 'react-loader-spinner'
import styled from 'styled-components'
import Model from '../Model/UserModel'
import {Body,PackTogether,LeftTabs,RightTab,EstateBanner,WriteUps,ContainR,Center, Texts} from '../Reuse_ui/Css'
import {Btn, ButtonIcon} from '../Reuse_ui/Widget'
import {RiAddLine,RiEdit2Fill} from 'react-icons/ri'
import { Get_session, Message, checkService } from '../Reuse_ui/Logic'
import {redirect1, Submit, Tr} from '../actions_api'
import Familymodel from '../Model/Familymodel'
import Studentmodel from '../Model/Studentmodel'
import { redirect, useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';




const UserFamTable = ({list,account_type}) => {

  var {c} = useParams();
  const [showModel, setShowModel] = useState("close");
  const [shareImage, setShareImage] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileN, setfileN] = useState("");
  const [companyWrite, setcompanyWrite] = useState("");
  const [marketWrite, setmarketWrite] = useState("");
  const [index, setIndex] = useState(0);
  const [UpdateText, setUpdateText] =  useState("Submit")
  const [url, setUrl] = useState("");
  const [pack, setPack] = useState([]);
  const navigate = useNavigate();
  let hold = Get_session(process.env.REACT_APP_USERNODEKEY,sessionStorage);


  

const OpenModel = (e,user) => {
    setPack(user);
    setIndex(0);
    e.preventDefault();
        switch(showModel){
          case "open":
                setShowModel("close");
                break;
          case "close":
               setShowModel("open");
                break;
          default:
              setShowModel("close");
                break;
        }
  }



  
const ChangeModel = (e,n) => {
  setPack(e);
  setIndex(n);
}



const handle = (e) => {
    const file = e.target.files[0];
    setProgress(0);
    if(file === '' || file === undefined){
        alert(`The file is a ${typeof image}`);
        return;
    }
    if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"  || file.type === "image/webp"){
        setShareImage(file);
        setfileN(file.name);
     }
}




const route = () => {
    setUpdateText('Upload...');
    Submit(fileN+Math.floor(Date.now()/1000)+".png",shareImage,companyWrite,marketWrite,null,window.location.href);
}



useEffect(() => {
     redirect1(hold,1,navigate)  
   setUrl(hold);
},[c])




   return ( list.length > 0  && account_type.trim().length > 0 && account_type !== process.env.REACT_APP_REWARD_NODES.toUpperCase() && account_type !== process.env.REACT_APP_CARPARK_NODES.toUpperCase() ? 
           <Body w={'100%'}  style={{background:"#f5f5f5"}}>
              <PackTogether>
                <LeftTabs>
                    { 
                    hold[0].User.service === process.env.REACT_APP_SCHOOL_NODES.toUpperCase() 
                    ?
                     list.map((v,i) => 
                         <Studentmodel  i={i} v={v}  open={OpenModel} icon={<RiEdit2Fill  size={25}  color="#FF9900"/>}/> 
                       )  
                        :
                        list.map((v,i) => 
                           <Familymodel  i={i} v={v}  open={OpenModel} icon={<RiEdit2Fill  size={25}  color="#FF9900"/>}/>                
                        )  
                      }   
                  </LeftTabs>

                  {showModel === "open" ? <Model  list={[pack]}  index={index} ui={ChangeModel} fun={OpenModel}  userType={url[0].User} /> : null}

                  <RightTab h={"80vh"}  mh={"65vh"} mt={"20px"}> 
                     <EstateBanner mt={"15px"}>
                          <input type="file"   name="image" id="file" style={{display: "none"}}  onChange={handle}  accept="image/png, image/gif, image/jpeg, image/jpg"/>
                           {shareImage && <img src={URL.createObjectURL(shareImage) }/>}
                           {!shareImage && <img src={url ? checkService(hold[0].User.service,url[0].User.bannerUrl) : null}/>}
                            <br/>
                          <p><label  htmlFor="file">{"Choose  banner + "}</label></p>
                          <br/>
                     </EstateBanner>
                     
                    <WriteUps placeholder='Company write up' value={companyWrite}   onChange={(e) => setcompanyWrite(e.target.value)} />
                    <WriteUps placeholder='Marketing write up' value={marketWrite}  onChange={(e) => setmarketWrite(e.target.value)} />
                    <ContainR>
                      <div  onClick={(e) => OpenModel(e,null)}>
                         <Button><RiAddLine/> Add</Button>
                      </div>
                      <div onClick={(e) =>{ Tr(companyWrite) || Tr(marketWrite) || shareImage ? route() : Message("Form validation Error","Pls fill out either field or select an image",'warning')}}>
                        <Button>{UpdateText}</Button>
                      </div>
                    </ContainR>     
                 </RightTab>
              </PackTogether>
          </Body>
           : 
         <Center>
            <Oval color={"#2D3476"}  height={40} width={40}/>
         </Center> 
         
  )
}







export default UserFamTable