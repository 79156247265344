import React, { useEffect,useState} from 'react'
import { Ri24HoursFill, RiRegisteredFill, RiStore3Line } from 'react-icons/ri'
import styled from 'styled-components'
import { Body,Space } from '../Reuse_ui/Css'
import {Container,CardInfo,Meta,TopUps} from '../Hover/Css'
import {  ButtonIcon,ButtonWidth} from '../Reuse_ui/Widget'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import  Button  from 'react-bootstrap/Button'
import {cld} from '../actions_api'
import {AdvancedImage, lazyload} from '@cloudinary/react';



const Wherecard = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleCheck() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', handleCheck);
    return () => {window.removeEventListener('resize', handleCheck);}
    },[])

  return <>
        <Header/> 
          <Body w={'100%'} h={'auto'}  mh={"235vh"}  style={{background:"#f5f5f5"}}>
            <Space/>
                <Container>
                    <CardInfo>
                      
                        <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/tjbsipxphnqpfbfjajal')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
             
                        <Meta w={'90%'}>
                            <div>
                                <h1>BeePPoint multiverse card</h1>
                                Welcome to the limitless world of BeePPoint
                                <br/>
                                 BeePPoint card makes your 
                                payment and all forms of transactions hustle-free and instant.   
                            </div>
        
                          { width > 768 ?  
                             <Button style={{width:"60%",marginTop:"20px",marginLeft:"35px"}}><Ri24HoursFill/>  How to get beeppoint card?</Button>
                             :
                             <Button style={{width:"100%"}}><Ri24HoursFill/>  How to get beeppoint card?</Button>
                          }
                            
                        </Meta>
                    </CardInfo>
                    <TopUps>
                    {width > 768 ? "" :    <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/Table_zmlahm')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>}
                    <Meta w={'40%'}>
                            <div>
                                <h1>All in one card use</h1>
                                  Beeping is fun!
                                  <br/>
                                Enjoy the seamless world of purchasing with style. 
                             </div>
                             <br/>
                            { width > 768 ?  
                             <Button style={{width:"60%",marginTop:"20px",marginLeft:"35px"}}><RiStore3Line/>  View list of stores</Button>
                             :
                             <Button style={{width:"100%"}}><Ri24HoursFill/>  How to get beeppoint card?</Button>
                          }
                        </Meta>  
                        {width <= 768 ? "" :  <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/Table_zmlahm')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>}
                    </TopUps>
                    <TopUps>
                    <AdvancedImage  cldImg={cld.image('beeppoint/ASSETS/social_pk2wxp')} plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}/>
                    <Meta w={'200px'}>
                            <div>
                                <h1>Beppoint customers Program</h1>
                                    Join our ever growing community of beeper today.
                                    <br/> 
                                    Sign up to get a card
                            </div>
                            <br/>
                       

                            { width > 768 ?  
                             <Button style={{width:"60%",marginTop:"20px",marginLeft:"35px"}}><RiRegisteredFill/> Sign up</Button>
                             :
                             <Button style={{width:"100%"}}><RiRegisteredFill/>  Sign up</Button>
                          }
                        </Meta>  
                    </TopUps>
                </Container>
            </Body>
            <Footer/>
        </>
}



export default Wherecard