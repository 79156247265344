import React,{ useEffect,useRef, useState } from 'react';
import  styled from 'styled-components'
import CountUp from 'react-countup';
import {Contain} from '../Reuse_ui/Css'
import useOnScreen from '../Observer/Observe_hook';



const Scroll = () => {

    const scroll = useRef();
    const isVisible = useOnScreen(scroll);
    const [c1, setC1] = useState(0);
    const [c2, setC2] = useState(0);
    const [c3, setC3] = useState(0);
    const [c4, setC4] = useState(0);
    let inter;



    useEffect(() => {
      if(isVisible)
            inter = setInterval(() => {
                setC1(count => count < 1 ? count+1 : 1); 
                setC2(count => count < 2 ? count+1 : 2); 
                setC3(count => count < 12 ? count+1 : 12); 
                setC4(count => count < 5 ? count+1 : 5); 
              },100)
            
      return () => {clearInterval(inter); setC1(0); setC2(0); setC3(0); setC4(0); }
    },[isVisible])

    return (
        <Container ref={scroll}>
            <Contain cal_ui={0}>
              <Boxs style={{fontFamily:'fantasy'}}> 
                <h2>{c1}K</h2> 
                Beeppoint cards 
                   Issued
              </Boxs>

              <Boxs style={{fontFamily:'fantasy'}}> 
                <h2>{c2}K</h2>
                  Total  
                Transactions
              </Boxs>

              <Boxs style={{fontFamily:'fantasy'}}> 
                 <h2>{c3}%</h2>
                Acceptance
                  Points
              </Boxs>

              <Boxs style={{fontFamily:'fantasy'}}> 
                  <h2>{c4}+</h2>
                  Terminal 
                   Mounted
              </Boxs>
            </Contain>
        </Container>
    )
}



const Container = styled.div`
height:30vh;
width:100%;
background:#DFE4FF;
display:flex;
flex-flow:row;
justify-content:center;
align-items:center;
@media(max-width:768px){
height:auto;
}
`;



const Boxs = styled.div`
color:#2D3476;
margin: 0 auto;
width:auto;
height:100px;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
flex-flow:column;

h2{
font-weight:900;
font-size:55px;
white-space:pre-line;
}
font-family: "Poppins";
@media(max-width:768px){
flex-flow:column;
flex: 1 0 21%;
margin: 5px;
padding:20px;
align-items:center;
font-size:18px;
h2{
font-size:25px;
}
}
`;


export  default Scroll