import React,{useState} from 'react'
import {PanelCards,EditButton, Body} from '../Reuse_ui/Css'

const RewardCar = ({v,k,icon,OpenModel}) => {


  function Check(d,t){
    return typeof d === t && !Array.isArray(d) && d !== null && d !== undefined;
  }

  return (   
    !Check(v,'object') ?
    <PanelCards key={k}>
        <table>
            <tr>
                <td>
                  CardID: 
                    <br/>
                     {v}
                </td>
            </tr>
         </table>
     
        <EditButton  onClick={(e) => OpenModel(e,v)}>
            {icon}
        </EditButton>
     </PanelCards>
    :
   null
  )
}

export default RewardCar