import React from 'react'
import styled from 'styled-components'
import { Body } from '../Reuse_ui/Css'
import { UserModel } from '../Reuse_ui/Widget'
import Footer from './Footer'

const AboutUs = () => {
  return (
    <Body w={'100%'} h={'80vh'}  style={{background:"#f5f5f5"}}>
            
            <TopContin>
                    <Center>
                        <h3>Servi Tech inc.</h3>
                        <h5>Write up here</h5>
                    </Center>
            </TopContin>

            <TrackRecord>
            
            </TrackRecord>

            <BoardOfDiretors>
                <h4>Beeppoint solutions business leadership team</h4>
                <Members>
                    <UserModel img={'./assets/profile.png'}  name={'Mrs Kalu'}  rank={'CEO'}/>
                    <UserModel img={'./assets/profile.png'}  name={'Mr Fred'}  rank={'Manager'}/>
                    <UserModel img={'./assets/profile.png'}  name={'Mr Paul'}  rank={'Tecnical manager'}/>
                </Members>
            </BoardOfDiretors>

            {/* <BoardOfDiretors>
                <h4>board of Directors</h4>
                <Members>
                    
                </Members>
            </BoardOfDiretors> */}

            <Footer/>
    </Body>
  )
}



const TopContin = styled.div`
width:100%;
height:70vh;
display:flex;
justify-content:center;
align-items:center;

`;



const Center = styled.div`
display:flex;
justify-content:center;
align-items:center;
flex-flow:column;
color:#fff;
h3{
font-weight:900;
font-size:45px;
}
h5{
width:80%;
text-align:center;
}
`;



const TrackRecord = styled.div`
width:100%;
height:50vh;

`;



const BoardOfDiretors = styled.div`
width:80%;
height:auto;
margin: 0 auto;
padding-bottom:120px;
h4{
text-align:center;
font-weight:900;
font-size:20px;
color:#2D3476;
}
`;


const Members = styled.div`
width:100%;
height:100%;
display:block;
margin-top:100px;
display: flex;
flex-flow:row;
flex-wrap:wrap;
justify-content:center;
align-items:center;
`;

export default AboutUs